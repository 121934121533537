var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c(
        "el-row",
        {
          staticClass: "row-bg",
          staticStyle: { margin: "0", padding: "0 0 0 20px" },
          attrs: { gutter: 24 },
        },
        [
          _c(
            "el-col",
            { attrs: { span: 5 } },
            [
              _c(
                "el-input",
                {
                  staticClass: "input-with-select",
                  attrs: { placeholder: "请输入搜索内容" },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.search.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.inputVal,
                    callback: function ($$v) {
                      _vm.inputVal = $$v
                    },
                    expression: "inputVal",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100px" },
                      attrs: { slot: "prepend", placeholder: "请选择" },
                      slot: "prepend",
                      model: {
                        value: _vm.select,
                        callback: function ($$v) {
                          _vm.select = $$v
                        },
                        expression: "select",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "用户名", value: "1" },
                      }),
                      _c("el-option", { attrs: { label: "姓名", value: "2" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择状态" },
                  model: {
                    value: _vm.status,
                    callback: function ($$v) {
                      _vm.status = $$v
                    },
                    expression: "status",
                  },
                },
                _vm._l(_vm.statusList, function (item) {
                  return _c("el-option", {
                    key: item.name,
                    attrs: { label: item.name, value: item.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 15 } },
            [
              _c(
                "el-button",
                {
                  staticClass: "tb-button",
                  attrs: { size: "small" },
                  on: { click: _vm.search },
                },
                [_vm._v("搜 索 ")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "tb-button",
                  attrs: { size: "small" },
                  on: { click: _vm.reset },
                },
                [_vm._v("重 置 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        {
          staticClass: "row-bg",
          staticStyle: { margin: "0", padding: "20px 0 0 30px" },
        },
        [
          _c(
            "el-col",
            [
              _vm.editData.pattern !== "极速"
                ? _c(
                    "el-button",
                    {
                      staticClass: "tb-button",
                      attrs: {
                        disabled: _vm.editData.role === "查看者",
                        type: "primary",
                        size: "small",
                      },
                      on: { click: _vm.add },
                    },
                    [_vm._v("添加成员")]
                  )
                : _c(
                    "el-button",
                    {
                      staticClass: "tb-button",
                      attrs: {
                        disabled: _vm.editData.role === "查看者",
                        type: "primary",
                        size: "small",
                      },
                      on: { click: _vm.add },
                    },
                    [_vm._v("添加极速成员 ")]
                  ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "studentTable",
          staticClass: "tb-list",
          attrs: {
            data: _vm.dataList,
            border: "",
            fit: "",
            "highlight-current-row": "",
            "row-key": _vm.getRowKeys,
          },
          on: { "selection-change": _vm.handleselectChange },
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "selection",
              width: "70",
              align: "center",
              "reserve-selection": true,
            },
          }),
          _c("el-table-column", {
            attrs: { label: "学号", align: "center", prop: "loginName" },
          }),
          _c("el-table-column", {
            attrs: { label: "姓名", align: "center", prop: "name" },
          }),
          _c("el-table-column", {
            attrs: { label: "所属校区", align: "center", prop: "campusName" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "报名时间",
              align: "center",
              prop: "createTime",
              width: "200",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "状态", align: "center", prop: "status" },
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center", width: "250" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status === "待审核"
                      ? _c(
                          "el-row",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "small",
                                  type: "primary",
                                  plain: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.verifySuccess(scope.row)
                                  },
                                },
                              },
                              [_vm._v("通过 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "small",
                                  type: "primary",
                                  plain: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.verifyFailed(scope.row)
                                  },
                                },
                              },
                              [_vm._v("拒绝 ")]
                            ),
                          ],
                          1
                        )
                      : scope.row.status === "正常" ||
                        scope.row.status === "审核失败"
                      ? _c(
                          "el-row",
                          [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "has",
                                    rawName: "v-has",
                                    value: ["operation:DeleteRecords"],
                                    expression: "['operation:DeleteRecords']",
                                  },
                                ],
                                attrs: {
                                  disabled: _vm.editData.role === "查看者",
                                  size: "small",
                                  type: "text",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.DeleteRecords(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除提交记录 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  disabled: _vm.editData.role === "查看者",
                                  size: "small",
                                  type: "text",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteItem(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除 ")]
                            ),
                            _vm.editData.lotteryId
                              ? _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "right",
                                      width: "300",
                                      trigger: "click",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form",
                                      {
                                        ref: "form",
                                        attrs: { "label-width": "80px" },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "发放次数",
                                              required: "",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              model: {
                                                value: _vm.lotteryNumber,
                                                callback: function ($$v) {
                                                  _vm.lotteryNumber = $$v
                                                },
                                                expression: "lotteryNumber",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  size: "mini",
                                                  type: "primary",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handGrantLottery(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("发放")]
                                            ),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { size: "mini" },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.cancelPopover.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("取消")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          slot: "reference",
                                          disabled:
                                            _vm.editData.role === "查看者",
                                          size: "small",
                                          type: "text",
                                        },
                                        slot: "reference",
                                      },
                                      [_vm._v("手动发放抽奖 ")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("add-contest-student", {
        attrs: {
          show: _vm.addcontestDialog,
          contestId: _vm.editData.contest_id + "",
        },
        on: {
          updateView: _vm.getContestUserList,
          close: function ($event) {
            _vm.addcontestDialog = false
          },
        },
      }),
      _c("openproblemvideo", {
        attrs: {
          studentArray: _vm.multipleSelection,
          show: _vm.videoDialog,
          contestId: _vm.editData.contest_id + "",
        },
        on: {
          updateView: _vm.resetArray,
          close: function ($event) {
            _vm.videoDialog = false
          },
        },
      }),
      _c("el-pagination", {
        staticStyle: { width: "65%" },
        attrs: {
          "current-page": _vm.pagination.currentPage,
          "page-sizes": _vm.pagination.pageSizes,
          "page-size": _vm.pagination.pageSize,
          layout: _vm.pagination.layout,
          total: _vm.pagination.total,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _c("SelectStudentComponents", {
        ref: "student",
        attrs: { multiple: false, selected: _vm.studentList },
        on: { clear: _vm.clear, getSubmit: _vm.studentCallback },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }