<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="70%"
    @open="openDialog"
    top="7vh"
    append-to-body
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>添加竞赛管理员</span>
    </div>
    <el-row :gutter="60">
      <el-col :span="14">
        <el-row style="margin-bottom: 20px">
          <el-row :gutter="20" style="margin-bottom: 10px">
            <el-col :span="12">
              <el-input
                placeholder="请输入内容"
                v-model="inputVal"
                class="input-with-select"
                @keyup.enter.native="search"
              >
                <el-select
                  v-model="select"
                  slot="prepend"
                  placeholder="请选择"
                  style="width: 100px"
                >
                  <el-option label="用户名" value="1"></el-option>
                  <el-option label="姓名" value="2"></el-option>
                </el-select>
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="search"
                ></el-button>
              </el-input>
            </el-col>
          </el-row>
          <el-table
            :data="allList"
            ref="multipleTable"
            border
            fit
            highlight-current-row
            @row-click="handleRowClick"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="selection"
              width="70"
              align="center"
            ></el-table-column>
            <el-table-column
              label="用户名"
              align="center"
              prop="loginName"
            ></el-table-column>
            <el-table-column
              label="姓名"
              align="center"
              prop="name"
            ></el-table-column>
          </el-table>
          <el-pagination
            class="pagination"
            @current-change="allhandleCurrentChange"
            :current-page="allPagination.currentPage"
            :page-sizes="allPagination.pageSizes"
            :page-size="allPagination.pageSize"
            :layout="allPagination.layout"
            :total="allPagination.total"
          ></el-pagination>
          <div style="margin-left: 10px">
            <span>角色：</span>
            <el-select v-model="role" placeholder="请选择">
              <el-option
                v-for="item in roleOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </el-row>
      </el-col>
    </el-row>
    <el-row slot="footer">
      <el-button type="primary" @click="confirm" class="dialog-btn"
        >确 定</el-button
      >
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import { getListEmployee } from "@/api/system/staff";
import { addManager } from "@/api/research/contest";

export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    contestId: {
      default: "",
      type: String,
    },
  },

  data() {
    return {
      role: "管理员",
      roleOptions: [
        {
          value: "创建者",
          label: "创建者",
        },
        {
          value: "管理员",
          label: "管理员",
        },
        {
          value: "运营者",
          label: "运营者",
        },
        {
          value: "查看者",
          label: "查看者",
        },
      ],
      tagSet: [],
      props: {
        multiple: true,
        value: "id",
        label: "title",
      },

      allList: [], // 全部学员

      allPagination: {
        currentPage: 1,
        pageSize: 10,
        layout: "total, prev, pager, next, jumper",
        total: 0,
      },
      inputVal: "",
      select: "",
      multipleSelection: [],
    };
  },
  methods: {
    // 多选框选中数据
    handleSelectionChange(selection) {
      if (selection.length > 1) {
        this.$refs.multipleTable.clearSelection();
        this.$refs.multipleTable.toggleRowSelection(selection.pop());
      } else {
        this.multipleSelection = selection.pop();
      }
      //this.ids = selection.map(item => item.driverId)
      //this.single = selection.length!=1
      //this.multiple = !selection.length
    },
    /**表格单选行资料处理*/
    handleRowClick(row, column, event) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    openDialog() {
      this.inputVal = "";
      this.allPagination.currentPage = 1;
      this.getListEmployee();
    },

    search() {
      this.allPagination.currentPage = 1;
      this.getListEmployee();
    },
    async addManager() {
      const response = await addManager({
        contestId: this.contestId,
        userId: this.multipleSelection.userId,
        role: this.role,
      }).then((res) => {
        if (res.state === "success") {
          window.$msg("新增管理员成功");
          this.close();
          this.$emit("updateView");
        }
      });
    },
    // 全部学生列表
    async getListEmployee() {
      const res = await getListEmployee({
        pageNum: this.allPagination.currentPage,
        pageSize: this.allPagination.pageSize,
        loginName: this.select === "1" ? this.inputVal : "",
        name: this.select === "2" ? this.inputVal : "",
      });
      this.allList = res.body.list;
      this.allPagination.total = res.body.total;
    },

    // 关闭
    close() {
      this.$emit("close");
    },

    allhandleCurrentChange(val) {
      this.allPagination.currentPage = val;
      this.getListEmployee();
    },

    // 保存
    confirm() {
      if (this.multipleSelection.length === 0) {
        window.$msg("请先选择管理员", 2);
        return false;
      }
      this.addManager();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
/deep/ .el-table td {
  padding: 10px 0 !important;
}
/deep/ .el-dialog {
  overflow: auto;
}
.haschoose {
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.15);
  padding-bottom: 30px;
}
.seleRow {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  background: #efefef;
  box-sizing: border-box;
  padding-left: 25px;
}
.seleNum {
  color: #fff;
  border-radius: 50%;
  padding: 1px 8px;
  background: #cccccc;
}
.seleList {
  box-sizing: border-box;
  margin-top: 20px;
  padding-left: 20px;
}
.ico-del {
  display: inline-block;
  margin-right: 50px;
  cursor: pointer;
}
</style>
