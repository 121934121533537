<template>
  <el-row class="table">
    <!-- <selectQuesSet
      ref="selectQuesSet"
      :editData="editData"
      :isEdit="false"
      :showProblemList="true"
    /> -->
    <!-- <div style="width: 100%; display: flex">
      <el-button
        type="primary"
        size="small"
        @click.native="confirm"
        style="margin: 20px 100px"
        >确定</el-button
      >
    </div> -->
    <el-table
      :data="problemList"
      border
      fit
      highlight-current-row
      class="tb-list"
      style="width: 40%"
      border
      fit
      highlight-current-row
      ref="dragTable"
      row-key="problem_id"
    >
      <el-table-column label="题号" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        label="ID"
        align="center"
        prop="problem_id"
      ></el-table-column>
      <el-table-column
        label="标题"
        align="center"
        prop="title"
      ></el-table-column>
    </el-table>
  </el-row>
</template>

<script>
import selectQuesSet from "@/views/operation/components/addContest/selectQuesSet";
import {
  updateQuestionLibrary,
  getQuestionByLibraryId,
} from "@/api/research/contest";

export default {
  components: { selectQuesSet },
  props: {
    editData: {
      default: () => {
        return {};
      },
      type: Object,
    },
  },
  data() {
    return {
      problemList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0,
      },
    };
  },
  created() {
    console.log("editData", this.editData);
    this.getList();
  },
  methods: {
    async getList() {
      let { state, body } = await getQuestionByLibraryId({
        contest_id: this.editData.contest_id,
      });
      this.problemList = body;
    },
    async confirm() {
      await updateQuestionLibrary({
        contest_id: this.editData.contest_id,
        libraryId: this.$refs.selectQuesSet.currentRow.id,
      });
      window.$msg("编辑成功");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../style/table.scss";
.dialog-footer {
  text-align: left;
  margin-left: 50px;
  margin-bottom: 35px;
}
</style>
