<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="70%"
    @open="openDialog"
    top="7vh"
    append-to-body
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>添加竞赛学员</span>
    </div>
    <el-row :gutter="60">
      <el-col :span="14">
        <el-row style="margin-bottom: 20px">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="全部学员" name="allList">
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="12">
                  <el-input
                    placeholder="请输入内容"
                    v-model="inputVal"
                    class="input-with-select"
                    @keyup.enter.native="search"
                  >
                    <el-select
                      v-model="select"
                      slot="prepend"
                      placeholder="请选择"
                      style="width: 100px"
                    >
                      <el-option label="用户名" value="1"></el-option>
                      <el-option label="姓名" value="2"></el-option>
                    </el-select>
                    <el-button
                      slot="append"
                      icon="el-icon-search"
                      @click="search"
                    ></el-button>
                  </el-input>
                </el-col>
                <el-col :span="8">
                  <el-cascader
                    class="td-cascader"
                    v-model="tagSet"
                    :options="tagUserTreeData"
                    :props="props"
                    placeholder="请选择所属标签"
                    clearable
                  ></el-cascader>
                </el-col>
              </el-row>
              <el-table
                :data="allList"
                ref="allList"
                border
                fit
                highlight-current-row
                :row-key="getRowKeys"
                @selection-change="allhandleselectChange"
              >
                <el-table-column
                  type="selection"
                  width="70"
                  align="center"
                  :reserve-selection="true"
                ></el-table-column>
                <el-table-column
                  label="用户名"
                  align="center"
                  prop="loginName"
                ></el-table-column>
                <el-table-column
                  label="姓名"
                  align="center"
                  prop="name"
                ></el-table-column>
              </el-table>
              <el-pagination
                class="pagination"
                @current-change="allhandleCurrentChange"
                :current-page="allPagination.currentPage"
                :page-sizes="allPagination.pageSizes"
                :page-size="allPagination.pageSize"
                :layout="allPagination.layout"
                :total="allPagination.total"
              ></el-pagination>
            </el-tab-pane>
            <el-tab-pane label="批量添加" name="batchAdd">
              <el-input
                type="textarea"
                placeholder="S0001
S0002
S0003
……
……
请按每行一个学号的格式填写要添加的学员，点击导入按钮"
                rows="8"
                v-model="batchStr"
                show-word-limit
              >
              </el-input>
              <el-button
                size="mini"
                type="primary"
                @click="importFun"
                style="margin-top: 10px"
                >导入</el-button
              >
            </el-tab-pane>
          </el-tabs>
        </el-row>
      </el-col>
      <el-col :span="10">
        <el-row class="haschoose">
          <el-row class="seleRow">
            <span>已选择：</span>
            <span class="seleNum" v-text="multipleSelectionTotal.length"></span>
          </el-row>
          <ul style="height: 500px; overflow: auto">
            <li
              v-for="(item, index) of multipleSelectionTotal"
              :key="index"
              class="seleList"
            >
              {{ item.name }} [{{ item.loginName }}]
            </li>
          </ul>
        </el-row>

        <el-row
          style="
            box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.15);
            padding-bottom: 30px;
            margin-top: 15px;
          "
          v-if="activeName === 'batchAdd' && isNonentity"
        >
          <span class="seleRow">不存在的用户名</span>
          <el-row
            v-for="(item, index) of nonexistence"
            :key="index"
            class="seleList"
          >
            <span>{{ item }}</span>
          </el-row>
        </el-row>
      </el-col>
    </el-row>
    <el-row slot="footer">
      <el-button type="primary" @click="confirm" class="dialog-btn"
        >确 定</el-button
      >
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import { getUserList, getUserListStudentt } from "@/api/system/staff";
import { setListByLoginNames } from "@/api/senate/class";
import { batchAddUser } from "@/api/research/contest";

import getTag from "@/mixins/getTag";

export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    contestId: {
      default: "",
      type: String,
    },
  },

  mixins: [getTag],
  data() {
    return {
      tagSet: [],
      props: {
        multiple: true,
        value: "id",
        label: "title",
      },
      activeName: "allList",
      allList: [], // 全部学员

      allPagination: {
        currentPage: 1,
        pageSize: 10,
        layout: "total, prev, pager, next, jumper",
        total: 0,
      },
      multipleSelectionTotal: [], // 已选择学员
      idKey: "userId",
      inputVal: "",
      select: "",
      campusIdSet: false,
      isNonentity: false,
      batchStr: "",
      nonexistence: [],
    };
  },
  methods: {
    importFun() {
      let loninNameSet = this.batchStr.split("\n");
      setListByLoginNames({
        roleCodeSet: [1],
        loninNameSet,
      }).then((res) => {
        if (res.state === "success") {
          this.multipleSelectionTotal = this.getNewList(
            this.multipleSelectionTotal.concat(res.body.existence)
          );
          this.nonexistence = res.body.nonexistence;
          if (res.body.nonexistence.length === 0) {
            this.isNonentity = false;
          } else {
            this.isNonentity = true;
          }
        }
      });
    },

    openDialog() {
      this.batchStr = "";
      this.inputVal = "";
      this.allPagination.currentPage = 1;
      this.activeName = "allList";
      this.isNonentity = false;
      this.nonexistence = [];
      this.getListStudent();
    },

    chooseLoadList() {
      switch (this.activeName) {
        case "allList":
          this.getListStudent();
          break;
      }
    },

    getRowKeys(row) {
      return row.userId;
    },

    search() {
      this.allPagination.currentPage = 1;
      this.chooseLoadList();
    },
    async batchAddUser(userSetsIds) {
      const response = await batchAddUser({
        contestId: this.contestId,
        userSets: userSetsIds,
      }).then((res) => {
        if (res.state === "success") {
          window.$msg("编辑学员成功");
          this.close();
        }
      });
    },
    // 全部学生列表
    async getListStudent() {
      let tagSet = "";
      if (this.tagSet.length !== 0) {
        const tagSetArr = this.tagSet;
        tagSetArr.forEach((ele, idx) => {
          if (tagSet === "") {
            tagSet = ele[ele.length - 1];
          } else {
            tagSet = tagSet + "," + ele[ele.length - 1];
          }
        });
      }
      const res = await getUserListStudentt({
        // pageNum: this.allPagination.currentPage,
        // pageSize: this.allPagination.pageSize,
        // state: 1,
        // roleCodeSet: [1],
        // loginName: this.select === "1" ? this.inputVal : "",
        // name: this.select === "2" ? this.inputVal : "",
        // tagIds: tagSet,
        currentStateSet: ["试听", "在读"],
        loginName: this.select === "1" ? this.inputVal : "",
        name: this.select === "2" ? this.inputVal : "",
        needCount: true,
        pageNum: this.allPagination.currentPage,
        pageSize: this.allPagination.pageSize,
        state: 1,
        studentSets: null,
        tel: null,
      });
      this.allList = res.body.list;
      this.allPagination.total = res.body.total;
    },
    handleClick() {
      if (this.activeName === "allList") {
        this.mergeCheck();
      }
    },
    // 记忆勾选
    mergeCheck() {
      this.allList.forEach((row, index) => {
        this.multipleSelectionTotal.forEach((ele, idx) => {
          if (row.userId === ele.userId) {
            this.$refs.allList.toggleRowSelection(this.allList[index], true);
          }
        });
      });
      this.$forceUpdate();
    },

    clearAllMerge(newVal, oldVal) {
      let ids = [];
      oldVal.forEach((ele) => {
        ids.push(ele.userId);
      });
      let idKey = this.idKey;
      for (let i = 0; i < this.allList.length; i++) {
        for (let j = 0; j < ids.length; j++) {
          if (ids[j].indexOf(this.allList[i][idKey]) >= 0) {
            // 设置选中，记住table组件需要使用ref="table"
            this.$refs.allList.toggleRowSelection(this.allList[i], false);
          }
        }
      }
    },
    allhandleselectChange(val) {
      this.multipleSelectionTotal = this.getNewList(val);
    },

    // 关闭
    close() {
      this.$refs.allList.clearSelection();
      this.$emit("updateView");
      this.$emit("close");
    },

    allhandleCurrentChange(val) {
      this.allPagination.currentPage = val;
      this.getListStudent();
    },

    // 保存
    confirm() {
      let thembArray = [];

      for (let i = 0; i < this.multipleSelectionTotal.length; i++) {
        thembArray.push(this.multipleSelectionTotal[i].userId);
      }

      this.$nextTick(() => {
        this.batchAddUser(thembArray);
      });
    },

    getNewList(arr) {
      let hash = {};
      arr = arr.reduce(function (arr, current) {
        hash[current.userId]
          ? ""
          : (hash[current.userId] = true && arr.push(current));
        return arr;
      }, []);
      return arr;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
/deep/ .el-table td {
  padding: 10px 0 !important;
}
/deep/ .el-dialog {
  overflow: auto;
}
/*/deep/ .el-table__header-wrapper .el-checkbox {*/
/*  display: none;*/
/*}*/
.haschoose {
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.15);
  padding-bottom: 30px;
}
.seleRow {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  background: #efefef;
  box-sizing: border-box;
  padding-left: 25px;
}
.seleNum {
  color: #fff;
  border-radius: 50%;
  padding: 1px 8px;
  background: #cccccc;
}
.seleList {
  box-sizing: border-box;
  margin-top: 20px;
  padding-left: 20px;
}
.ico-del {
  display: inline-block;
  margin-right: 50px;
  cursor: pointer;
}
</style>
