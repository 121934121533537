<template>
  <el-row class="tree-select" :style="{ padding: '16px' }">
    <el-row style="margin-bottom: 16px">
      <el-input
        :size="size"
        v-model="filterText"
        placeholder="输入关键字进行过滤"
        clearable
        v-if="showInput"
      />
    </el-row>
    <el-tree
      ref="tree"
      class="filter-tree"
      :style="{ width: treeWidth }"
      :data="data"
      :empty-text="emptyText"
      :render-after-expand="renderAfterExpand"
      :props="defaultProps"
      :highlight-current="highlightCurrent"
      :default-expand-all="defaultExpandAll"
      :expand-on-click-node="expandOnClickNode"
      :check-on-click-node="checkOnClickNode"
      :auto-expand-parent="autoExpandParent"
      :default-expanded-keys="defaultExpandedKeys"
      :default-checked-keys="defaultCheckedKeys"
      :show-checkbox="showCheckbox"
      :check-strictly="checkStrictly"
      :node-key="nodeKey"
      :current-node-key="currentNodeKey"
      :accordion="accordion"
      :indent="indent"
      :icon-class="iconClass"
      :lazy="lazy"
      :size="size"
      :filter-node-method="filterNode"
      :load="load"
      :allow-drag="allowDrag"
      :allow-drop="allowDrop"
      :render-content="renderContent"
      @node-click="nodeClick"
      @node-contextmenu="
        (event, obj, node, self) =>
          $emit('nodeContextmenu', event, obj, node, self)
      "
      @check-change="(obj, node, flag) => $emit('checkChange', obj, node, flag)"
      @check="checkHandler"
      @current-change="(obj, node) => $emit('currentChange', obj, node)"
      @node-expand="(obj, node, self) => $emit('nodeExpand', obj, node, self)"
      @node-collapse="
        (obj, node, self) => $emit('nodeCollapse', obj, node, self)
      "
      @node-drag-start="(node, event) => $emit('nodeDragStart', node, event)"
      @node-drag-enter="
        (node1, node2, event) => $emit('nodeDragEnter', node1, node2, event)
      "
      @node-drag-leave="
        (node1, node2, event) => $emit('nodeDragLeave', node1, node2, event)
      "
      @node-drag-over="
        (node1, node2, event) => $emit('nodeDragOver', node1, node2, event)
      "
      @node-drag-end="
        (node1, node2, position, event) =>
          $emit('nodeDragEnd', node1, node2, position, event)
      "
      @node-drop="
        (node1, node2, position, event) =>
          $emit('nodeDrop', node1, node2, position, event)
      "
    >
      <slot name="content" />
    </el-tree>
  </el-row>
</template>
<script>
export default {
  name: 'TreeSelectCom',
  props: {
    data: {
      type: [Object, Array],
      default: () => []
    },
    defaultProps: {
      type: [Object, Array],
      default: () => []
    },
    width: {
      type: [Number, String],
      default: 220
    },
    size: {
      type: [Number, String],
      default: 'small'
    },
    // 树节点的内容区的渲染 Function	Function(h, { node, data, store }
    renderContent: {
      type: Function,
      default: (h, { node, data, store }) => {
        return <span>{node.label}</span>
      }
    },
    emptyText: {
      type: String,
      default: ''
    },
    nodeKey: {
      type: String,
      default: 'id'
    },
    renderAfterExpand: {
      type: Boolean,
      default: true
    },
    // 是否高亮当前选中节点，默认值是
    highlightCurrent: {
      type: Boolean,
      default: false
    },
    // 是否默认展开所有节点
    defaultExpandAll: {
      type: Boolean,
      default: false
    },
    // 是否在点击节点的时候展开或者收缩节点， 默认值为 true，如果为 false，则只有点箭头图标的时候才会展开或者收缩节点。
    expandOnClickNode: {
      type: Boolean,
      default: true
    },
    // 是否在点击节点的时候选中节点，默认值为 false，即只有在点击复选框时才会选中节点 默认值为 true，如果为 false，则只有点箭头图标的时候才会展开或者收缩节点。
    checkOnClickNode: {
      type: Boolean,
      default: false
    },
    // 展开子节点的时候是否自动展开父节点
    autoExpandParent: {
      type: Boolean,
      default: true
    },
    // 默认展开的节点的 key 的数组
    defaultExpandedKeys: {
      type: Array,
      default: () => []
    },
    // 节点是否可被选择
    showCheckbox: {
      type: Boolean,
      default: false
    },
    // 在显示复选框的情况下，是否严格的遵循父子不互相关联的做法，默认为 false
    checkStrictly: {
      type: Boolean,
      default: false
    },
    // 默认勾选的节点的 key 的数组
    defaultCheckedKeys: {
      type: Array,
      default: () => []
    },
    // 当前选中的节点
    currentNodeKey: {
      type: [String, Number],
      default: ''
    },
    // 是否每次只打开一个同级树节点展开
    accordion: {
      type: Boolean,
      default: false
    },
    // 相邻级节点间的水平缩进，单位为像素
    indent: {
      type: Number,
      default: 16
    },
    // 自定义树节点的图标
    iconClass: {
      type: String,
      default: ''
    },
    // 是否懒加载子节点，需与 load 方法结合使用
    lazy: {
      type: Boolean,
      default: false
    },
    // 是否开启拖拽节点功能
    draggable: {
      type: Boolean,
      default: false
    },
    // self:内部链接，open:开放链接
    urlType: {
      type: String,
      default: ''
    },
    showInput: {
      type: Boolean,
      default: true
    },
    treeWidth: {
      type: String,
      default: '260px'
    }
  },
  data () {
    return {
      filterText: ''
    }
  },
  watch: {
    filterText (val) {
      this.$refs.tree.filter(val)
    }
  },
  mounted () {
    console.log(this.defaultExpandedKeys)
  },
  methods: {
    setCurrentNodeThemb (id) {
      this.$refs.tree.setCurrentKey(id)
    },
    nodeClick (obj, node, self) {
      this.$emit('nodeClick', obj, node, self)
    },
    checkHandler (obj, select) {
      console.log(obj, select)
      this.$emit('check', obj, select)
    },
    filterNode (value, data) {
      if (!value) return true
      return data[this.defaultProps.label].indexOf(value) !== -1
    },
    load (h, { node, data, store }) {
      console.log(h, { node, data, store })
    },
    // 判断节点能否被拖拽
    allowDrag (node) {
      console.log(node)
    },
    // 拖拽时判定目标节点能否被放置。type 参数有三种情况：'prev'、'inner' 和 'next'，
    // 分别表示放置在目标节点前、插入至目标节点和放置在目标节点后
    allowDrop (draggingNode, dropNode, type) {
      console.log(draggingNode, dropNode, type)
    }
  }
}
</script>
<style lang="scss">
@import "@/style/element-variables.scss";
.tree-select {
  .el-tree--highlight-current
    .el-tree-node.is-current
    > .el-tree-node__content {
    background-color: #edf6ff;
    .el-tree-node__label {
      color: $--color-primary;
    }
    span:nth-child(2) {
      color: $--color-primary;
    }
  }
}
</style>
