import { courseCatalog } from '@/api/learnmanage/categorylearn'

export default {
  data () {
    return {
      tableListLearn: [],
      tableArrLearn: [], // 表格重组数据
      cascaderListLearn: [] // 选择器重组数据
    }
  },
  mounted () {
    this.courseCatalogLearn()
  },
  methods: {
    // 获取课程类别
    async courseCatalogLearn () {
      this.tableArrLearn = []
      this.cascaderListLearn = []
      const res = await courseCatalog({
        pid: '',
        pageNum: 1,
        pageSize: 1000
      })
      this.tableListLearn = res.body
      this.buildTreeLearn(res.body)
      this.cascaderListLearn = [...this.tableArrLearn]
    },

    // 构建 树形数据
    buildTreeLearn (list) {
      let dataList = []
      for (let index in list) {
        dataList[list[index].id] = list[index]
      }
      for (let i in dataList) {
        if (dataList[i].pid) {
          if (!dataList[dataList[i].pid].children) {
            dataList[dataList[i].pid].children = []
          }
          if(dataList[dataList[i].pid].children.length === 0) {
            dataList[i].preitemId = '0000@'
            dataList[i].afteritemId = '0000@'
          }else {
            let thembArray = dataList[dataList[i].pid].children
            let lengthThemb = dataList[dataList[i].pid].children.length - 1
            dataList[i].preitemId = thembArray[lengthThemb].id
            dataList[i].afteritemId = '0000@'
            thembArray[lengthThemb].afteritemId = dataList[i].id
          }
          dataList[dataList[i].pid].children.push(dataList[i])
        } else {
          if(this.tableArrLearn.length === 0) {
            dataList[i].preitemId = '0000@'
            dataList[i].afteritemId = '0000@'
          }else {
            let thembArray = this.tableArrLearn
            let lengthThemb = this.tableArrLearn.length - 1
            dataList[i].preitemId = thembArray[lengthThemb].id
            dataList[i].afteritemId = '0000@'
            thembArray[lengthThemb].afteritemId = dataList[i].id
          }
          this.tableArrLearn.push(dataList[i])
        }
      }
    }
  }
}
