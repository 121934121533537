import $axios from '@/utils/request'

// 获取官网免费课报名列表
export function getRegistrationList (data) {
  const url = '/officialWebsite/registrationList'
  return $axios.fPost(url, data)
}

// 获取官网资讯列表
export function getNews (data) {
  const url = '/news/getNews'
  return $axios.fPost(url, data)
}

// 修改资讯状态
export function changeStatus (data) {
  const url = '/news/changeStatus'
  return $axios.fPost(url, data)
}

// 获取官网资讯列表
export function getNewsList (data) {
  const url = '/news/getNewsList'
  return $axios.fPost(url, data)
}

// 新增资讯
export function addNews (data) {
  const url = '/news/addNews'
  return $axios.fPost(url, data)
}

// 编辑资讯
export function updateNews (data) {
  const url = '/news/updateNews'
  return $axios.fPost(url, data)
}

// 获取官网资讯详情
export function getNewsItem (data) {
  const url = '/news/getNewsItem'
  return $axios.fGet(url, data)
}

// 获取学员案例列表（管理端）
export function getListWebsitePriceUser (data) {
  const url = '/price/user/listWebsitePriceUser'
  return $axios.fPost(url, data)
}

// 新增学员案例（管理端）
export function insertWebsitePriceUser (data) {
  const url = '/price/user/insertWebsitePriceUser'
  return $axios.fPost(url, data)
}

// 编辑学员案例（管理端）
export function updateWebsitePriceUserById (data) {
  const url = '/price/user/updateWebsitePriceUserById'
  return $axios.fPost(url, data)
}

// 排序学员案例（管理端）
export function sortSeq (data) {
  const url = '/price/user/sortSeq'
  return $axios.fPost(url, data)
}

// 删除学员案例(管理端)
export function deleteWebsitePriceUserById (data) {
  const url = '/price/user/delete'
  return $axios.fGet(url, data)
}

// 获取字典
export function getSysDictByType (data) {
  const url = '/dict/getSysDictByType'
  return $axios.fGet(url, data)
}
