import $axios from '@/utils/request'

// 审核列表-全部
export function listLimitScope (data) {
  const url = '/processaudit/listLimitScope'
  return $axios.fPost(url, data)
}
// 审核列表-部分
export function limitScopeForCampus (data) {
  const url = '/processaudit/limitScopeForCampus'
  return $axios.fPost(url, data)
}
// 审核
export function Examineaudit (data) {
  const url = '/processaudit/audit'
  return $axios.fPost(url, data)
}
//查看单个作品
export function getInformation (data) {
  const url = '/creation/getInformation'
  return $axios.fGet(url, data)
}
//保存模板作品
export function addCreationMoudle (data) {
  const url = '/creation/add'
  return $axios.fPost(url, data)
}
//编辑模板作品
export function editCreationMoudle (data) {
  const url = '/creation/edit'
  return $axios.fPost(url, data)
}
// 查看标记的标签列表
export function getTagsByRelationId (data) {
  const url = '/tag/realtion/getTagsByRelationId'
  return $axios.fGet(url, data)
}


// 查看标记的标签列表
export function getTagGroup (data) {
  const url = '/tagGroup/list'
  return $axios.fPost(url, data)
}


