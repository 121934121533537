<template>
  <el-row class="table">
    <el-row class="row-bg" :gutter="24">
      <el-col :span="5">
        <el-input
          placeholder="请输入搜索内容"
          v-model="inputVal"
          class="input-with-select"
          @keyup.enter.native="search"
        >
          <el-select
            v-model="select"
            slot="prepend"
            placeholder="请选择"
            style="width: 100px"
          >
            <el-option label="问题ID" value="1"></el-option>
            <el-option label="问题标题" value="2"></el-option>
          </el-select>
        </el-input>
      </el-col>
      <el-col :span="5">
        <el-input
          placeholder="请输入搜索内容"
          v-model="inputValName"
          class="input-with-select"
          @keyup.enter.native="search"
        >
          <el-select
            v-model="selectName"
            slot="prepend"
            placeholder="请选择"
            style="width: 100px"
          >
            <el-option label="用户名" value="1"></el-option>
            <el-option label="姓名" value="2"></el-option>
          </el-select>
        </el-input>
      </el-col>

      <el-col :span="12">
        <el-button type="primary" size="small" @click="search" class="tb-button"
          >搜 索</el-button
        >
        <el-button type="primary" size="small" @click="reset" class="tb-button"
          >重 置</el-button
        >
      </el-col>
    </el-row>

    <el-table :data="dataList" border fit highlight-current-row class="tb-list">
      <el-table-column
        label="学号"
        align="center"
        prop="loginName"
      ></el-table-column>
      <el-table-column
        label="姓名"
        align="center"
        prop="name"
      ></el-table-column>
      <el-table-column
        label="题号"
        align="center"
        prop="contestProblemNum"
      ></el-table-column>
      <el-table-column label="[id]标题" align="center">
        <template slot-scope="scope">
          <span>[{{ scope.row.problem_id }}]{{ scope.row.problemName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="得分"
        v-if="editData.raceRule !== 'ACM'"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.pass_rate * 100 }}</span>
        </template>
      </el-table-column>
      <el-table-column label="结果" align="center" width="130">
        <template slot-scope="scope">
          <span
            @click="subDetail(scope.row, 'longsince')"
            class="type-span"
            :style="'backgroundColor' + ':' + getStateColor(scope.row.result)"
            >{{ getSubType(scope.row.result) }}
          </span>
        </template>
      </el-table-column>
      <!--      <el-table-column label="得分" align="center" prop="title"></el-table-column>-->
      <el-table-column
        label="运行时间(ms)"
        align="center"
        prop="time"
      ></el-table-column>
      <el-table-column
        label="私用内存(KB)"
        align="center"
        prop="memory"
      ></el-table-column>
      <el-table-column
        label="代码长度(B)"
        align="center"
        prop="code_length"
      ></el-table-column>
      <el-table-column label="编程语言" align="center">
        <template slot-scope="scope">
          <span> {{ getLanText(scope.row.language) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="提交时间"
        align="center"
        prop="in_date"
      ></el-table-column>

      <!--      <el-table-column label="操作" align="center">-->
      <!--        <template slot-scope="scope">-->
      <!--          <el-button size="small" type="primary" plain @click="deleteItem(scope.row)">删除</el-button>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>
    <el-dialog
      title="记录详情"
      width="900px"
      :visible.sync="showDialog"
      append-to-body
      :before-close="() => (showDialog = false)"
    >
      <div slot="title" class="dia-tit">
        <i></i>
        <span>提交详情</span>
      </div>
      <div>
        <detailRecord :item="rowData" v-if="showDialog" />
      </div>
    </el-dialog>
  </el-row>
</template>

<script>
import { getSubmitHistory } from "@/api/research/contest";
import detailRecord from "@/views/record/detail";

export default {
  components: { detailRecord },
  props: {
    editData: {
      default: () => {
        return {};
      },
      type: Object,
    },
  },
  data() {
    return {
      rowData: {},
      showDialog: false,
      inputVal: "",
      inputValName: "",
      select: "1",
      selectName: "1",
      dataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0,
      },
      addMoudleDialog: false,
      moduleTitle: "",
      creatorName: "",
    };
  },
  created() {
    this.getSubmitHistory();
  },
  methods: {
    // 跳转详情页面
    subDetail(row, type) {
      // let routeData = this.$router.resolve({
      //   path: '/research/question-subDetails',
      //   query: {
      //     type: type,
      //     resultData: JSON.stringify(row)
      //   }
      // })
      // window.open(routeData.href, '_blank')
      this.rowData = row;
      this.showDialog = true;
    },
    // 获取模板列表
    async getSubmitHistory() {
      const response = await getSubmitHistory({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        needCount: true,
        contest_id: this.editData.contest_id,
        problem_id: this.select === "1" ? this.inputVal : "",
        title: this.select === "2" ? this.inputVal : "",
        name: this.selectName === "2" ? this.inputValName : "",
        loginName: this.selectName === "1" ? this.inputValName : "",
      });
      this.dataList = response.body.list;
      this.pagination.total = response.body.total;
    },

    // 重置
    reset() {
      this.inputVal = "";
      this.select = "1";
      this.inputValName = "";
      this.selectName = "1";
    },

    // 搜索
    search() {
      this.pagination.currentPage = 1;
      this.getSubmitHistory();
    },

    // 分页
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.getSubmitHistory();
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.getSubmitHistory();
    },
    getSubType(state) {
      switch (state) {
        case 0:
          return "等待";
          break;
        case 1:
          return "等待重判";
          break;
        case 2:
          return "编译中";
          break;
        case 3:
          return "运行并评判";
          break;
        case 4:
          return "正确";
          break;
        case 5:
          return "格式错误";
          break;
        case 6:
          return "答案错误";
          break;
        case 7:
          return "时间超限";
          break;
        case 8:
          return "内存超限";
          break;
        case 9:
          return "输出超限";
          break;
        case 10:
          return "运行错误";
          break;
        case 11:
          return "编译错误";
          break;
        case 12:
          return "编译成功";
          break;
        case 13:
          return "运行完成";
          break;
        default:
          return "未知状态";
      }
    },
    getStateColor(state) {
      switch (state) {
        case 4:
          return "#4DA54D";
          break;
        case 12:
          return "#4DA54D";
          break;
        case 13:
          return "#4DA54D";
          break;
        case 5:
          return "#CE433F";
          break;
        case 6:
          return "#CE433F";
          break;
        case 10:
          return "#CE433F";
          break;
        case 11:
          return "#CE433F";
          break;
        default:
          return "#EB9316";
      }
    },

    // 获取语言
    getLanText(state) {
      switch (state) {
        case 0: {
          return "C";
        }
        case 1: {
          return "C++";
        }
        case 2: {
          return "Pascal";
        }
        case 4: {
          return "Ruby";
        }
        case 5: {
          return "Bash";
        }
        case 6: {
          return "Python";
        }
        case 7: {
          return "php";
        }
        case 8: {
          return "perl";
        }
        case 9: {
          return "c#";
        }
        case 10: {
          return "objectiveC";
        }
        case 11: {
          return "freeBasic";
        }
        case 12: {
          return "scheme";
        }
        case 13: {
          return "Clang";
        }
        case 14: {
          return "Clang++";
        }
        case 15: {
          return "Lua";
        }
        case 16: {
          return "JavaScript";
        }
        case 17: {
          return "Go";
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../style/table.scss";
</style>
