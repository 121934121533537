<template>
  <div class="deparment">
    <!-- tree -->
    <div class="treeDiv">
      <CoduckTreeSelect
        :data="treeData"
        :default-expand-all="true"
        :default-props="defaultProps"
        :highlight-current="true"
        :show-checkbox="false"
        :render-content="renderContent"
        :urlType="urlType"
        @nodeClick="nodeClick"
      />
    </div>
    <!-- table -->
    <div class="tables">
      <div class="handle">
        <el-input
          v-model="queryParam.userSearch"
          placeholder="姓名首字母/姓名/学号"
          clearable
          size="small"
          class="w100"
          @keyup.enter.native="search"
        />
        <el-select
          v-model="queryParam.currentStateSet"
          class="w100"
          style="margin-left: 20px"
          placeholder="学员状态"
          collapse-tags
          multiple
          clearable
          filterable
          @change="search"
          size="small"
        >
          <el-option
            v-for="item in studentOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <el-button
          size="mini"
          style="margin-left: 8px"
          icon="el-icon-search"
          circle
          @click="search"
        ></el-button>
      </div>
      <el-table
        ref="table"
        :data="dataList"
        fit
        v-loading="loading"
        :highlight-current-row="true"
        height="345"
        @select="handleSelectionChange"
        @select-all="selectAll"
        size="small"
        :header-cell-style="{ background: '#F7F8FA' }"
        @row-click="handleClickRow"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          label="工号"
          align="left"
          prop="loginName"
          :show-overflow-tooltip="true"
        />
        <el-table-column label="姓名" align="left" prop="name" />
        <el-table-column label="学员状态" align="left">
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.current_state"
              :type="
                scope.row.current_state === '退学' ||
                scope.row.current_state === '休学' ||
                scope.row.current_state === '欠费'
                  ? 'danger'
                  : scope.row.current_state === '在读'
                  ? 'success'
                  : 'warning'
              "
              size="mini"
              >{{ scope.row.current_state }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="负责人" align="left" prop="eduName">
          <template slot-scope="scope">
            {{ scope.row.eduName || "--" }}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 8px"
        small
        background
        :current-page="pagination.currentPage"
        :page-size="pagination.pageSize"
        :page-sizes="pagination.pageSizes"
        :total="pagination.total"
        :layout="pagination.layout"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>
<script>
import { getListStudentForCampus } from "@/api/user/index";
import CoduckTreeSelect from "@/components/base-com/components/coduck-tree-select.vue";
import { getMyCampus } from "@/api/senate/class";
import {
  dwexamSelfStuList,
  dwexamSelfTreeList,
} from "@/api/questionInvestigate";
export default {
  name: "",
  components: { CoduckTreeSelect },
  props: {
    tableSelection: {
      type: Map,
      default: () => new Map(),
    },
    //self:内部链接，open:开放链接
    urlType: {
      type: String,
      default: "",
    },
    // 查询类型：只查在读的
    searchType: {
      type: String,
      default: "",
    },
    studentOptions: {
      type: Array,
      default: () => [
        {
          value: "临时",
          label: "临时",
        },
        {
          value: "试听",
          label: "试听",
        },
        {
          value: "在读",
          label: "在读",
        },
        {
          value: "退学",
          label: "退学",
        },
        {
          value: "欠费",
          label: "欠费",
        },
        {
          value: "休学",
          label: "休学",
        },
      ],
    },
  },
  data() {
    // const treeList = this.handleTree(campusTree.body, 'id', 'leaderId')
    return {
      loading: false,
      treeData: [],
      onlyDepartment: true,
      pagination: {
        currentPage: 1,
        pageSize: 20,
        pageSizes: [20, 50, 100, 200],
        layout: "total, prev, pager, next",
        total: 0,
      },
      deptId: "",
      campusId: "",
      depName: "",
      queryParam: {
        // 查询参数
        userSearch: "",
        currentStateSet: [],
      },
      // studentOptions: [
      //   {
      //     value: "临时",
      //     label: "临时",
      //   },
      //   {
      //     value: "试听",
      //     label: "试听",
      //   },
      //   {
      //     value: "在读",
      //     label: "在读",
      //   },
      //   {
      //     value: "退学",
      //     label: "退学",
      //   },
      //   {
      //     value: "欠费",
      //     label: "欠费",
      //   },
      //   {
      //     value: "休学",
      //     label: "休学",
      //   },
      // ],
      dataList: [],
      // 树结构
      defaultProps: {
        children: "children",
        label: "name",
      },
      campusIdSet: [],
    };
  },
  mounted() {
    if (this.searchType == "reading") {
      this.queryParam.currentStateSet.push("在读");
    }
    if (this.searchType == "formal") {
      this.queryParam.currentStateSet.push("在读", "退学", "欠费", "休学");
    }
    this.getList();
    this.getMyCampus();
  },
  inject: {
    // 注入父组件传的值，可以写一个默认的值，如果父组件没有返回数据的话
    multiple: {
      default: true,
    },
  },
  methods: {
    handleClickRow(row) {
      const { userId } = row;
      const rows = this.tableSelection.get(userId) ? [] : [row];
      this.handleSelectionChange(rows, row);
    },
    // 获取校区
    getMyCampus() {
      let promise = null;
      if (this.urlType == "self") {
        promise = dwexamSelfTreeList({});
      } else {
        promise = getMyCampus({});
      }
      promise.then((res) => {
        if (res.state == "success") {
          this.treeData = this.handleTree(
            [
              {
                id: "1",
                name: "全部",
                leader: null,
                leaderId: null,
                deptId: null,
                status: 1,
              },
              ...res.body.map((i) => {
                return {
                  ...i,
                  // leaderId: i.leaderId || 1
                  leaderId: 1,
                };
              }),
            ],
            "id",
            "leaderId"
          );
        }
      });
    },
    search() {
      this.pagination.currentPage = 1;
      this.getList();
    },
    // 多选
    handleSelectionChange(rows, row) {
      if (this.multiple) {
        this.tableSelection.delete(row.userId);
        if (rows.length) {
          rows.forEach((i) => {
            this.tableSelection.set(i.userId, i);
          });
        } else {
          rows.forEach((i) => {
            this.tableSelection.delete(i.userId);
          });
        }
      } else {
        [...this.tableSelection].forEach((i) => {
          this.tableSelection.delete(i[1].userId);
        });
        if (rows.length) {
          rows.forEach((i) => {
            if (row.userId === i.userId) {
              this.tableSelection.set(i.userId, i);
            }
          });
        }
      }
      this.$emit("changeSelectionData");
    },
    // 全选
    selectAll(val) {
      if (val.length) {
        this.dataList.forEach((i) => {
          this.tableSelection.set(i.userId, i);
        });
      } else {
        this.dataList.forEach((i) => {
          this.tableSelection.delete(i.userId);
        });
      }
      this.$emit("changeSelectionData");
    },
    nodeClick(obj, node, self) {
      this.handleNodeClick(node, self);
    },
    // tree点击
    handleNodeClick(node, data) {
      this.campusId = node.data.id;
      console.log(node.data);
      if (this.campusId == 1) {
        this.campusId = "";
      }
      this.pagination.currentPage = 1;
      this.getList();
    },
    // 分页
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.getList();
    },
    // 获取数据
    async getList() {
      this.loading = true;
      const params = {
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        userSearch: this.queryParam.userSearch,
        currentStateSet: this.queryParam.currentStateSet,
        campusIdSet: this.campusId ? [this.campusId] : [],
        roleCodeSet: [1],
      };
      let res = null;
      // const res = await getListStudentForCampus(params);
      if (this.urlType == "self") {
        res = await dwexamSelfStuList(params);
      } else {
        res = await getListStudentForCampus(params);
      }

      // const res = await getListStudentForCampus({
      //   pageNum: this.pagination.currentPage,
      //   pageSize: this.pagination.pageSize,
      //   userSearch: this.queryParam.loginName ? this.queryParam.loginName : '',
      //   campusIdSet: this.campusId ? [this.campusId] : [],
      //   state: '',
      //   currentStateSet: this.queryParam.currentState
      //     ? this.queryParam.currentState
      //     : [],
      //   followName: '',
      //   noexitFollow: '',
      //   orderBy: 'loginName',
      //   isAsc: false,
      //   tagIds: '',
      //   minMsgCreateTime: null,
      //   maxMsgCreateTime: null
      // })
      this.loading = false;
      if (res.state == "success") {
        this.dataList = res.body.list;
        this.pagination.total = res.body.total;
      }
      // 反选
      this.$nextTick(() => {
        this.reverseElectionClick();
      });
    },
    // 反选
    reverseElectionClick() {
      this.dataList.forEach((item, index) => {
        this.$refs.table.toggleRowSelection(
          this.dataList[index],
          this.tableSelection.has(item.userId)
        );
      });
    },
    // 树自定义
    renderContent(h, { node, data, store }) {
      return (
        <span class="custom-tree-node">
          <i style="margin-right:8px" class="cdesign cdesign-xuexiao" />
          <span>{node.label}</span>
        </span>
      );
    },
  },
};
</script>
<style scoped lang="scss">
.deparment {
  height: 100%;
  display: flex;
  .treeDiv {
    width: 240px;
    border-right: 1px solid #eee;
    height: 449px;
    // padding: 15px;
    overflow-y: auto;
  }
  .treeDiv::-webkit-scrollbar {
    width: 3px;
    height: 5px;
  }
  .tables {
    padding: 16px;
    width: 439px;
    .handle {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .w100 {
        width: 180px !important;
      }
    }
    ::v-deep .el-table__body-wrapper::-webkit-scrollbar {
      width: 3px;
      height: 5px;
    }
    ::v-deep .pagination {
      margin: 0 0 0 -36px;
      padding-top: 8px;
    }
  }
}
</style>
