<template>
  <el-row class="table">
    <el-row class="row-bg" :gutter="24">
      <el-col :span="18">
        <el-button type="primary" size="small" @click="add" class="tb-button"
          >添加成员</el-button
        >
      </el-col>
    </el-row>

    <el-table
      :data="dataList"
      border
      fit
      highlight-current-row
      class="tb-list"
      style="width: 40%"
    >
      <el-table-column
        label="账号"
        align="center"
        prop="loginName"
      ></el-table-column>
      <el-table-column
        label="姓名"
        align="center"
        prop="name"
      ></el-table-column>
      <el-table-column
        label="角色"
        align="center"
        prop="role"
      ></el-table-column>

      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.role !== '创建者'"
            size="small"
            type="primary"
            plain
            @click="deleteItem(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <add-contest-admin
      :show="addMoudleDialog"
      @updateView="getContestManagerList"
      :contestId="editData.contest_id + ''"
      @close="addMoudleDialog = false"
    ></add-contest-admin>
  </el-row>
</template>

<script>
import { getContestManagerList, deleteManager } from "@/api/research/contest";
import addContestAdmin from "@/components/research/detail-pane/addContestAdmin";

export default {
  components: {
    addContestAdmin,
  },
  props: {
    editData: {
      default: () => {
        return {};
      },
      type: Object,
    },
  },
  data() {
    return {
      dataList: [],
      addMoudleDialog: false,
      moduleTitle: "",
      creatorName: "",
    };
  },
  created() {
    this.getContestManagerList();
  },
  methods: {
    // 获取模板列表
    async getContestManagerList() {
      const response = await getContestManagerList({
        contestId: this.editData.contest_id,
      });
      this.dataList = response.body;
    },

    add() {
      this.addMoudleDialog = true;
    },

    async deleteManager(row) {
      const response = await deleteManager({
        contestManagerId: row.managerId,
      });
      window.$msg("删除管理员成功");
      this.getContestManagerList();
    },
    // 删除
    deleteItem(row) {
      this.$confirm("是否删除当前管理员", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.deleteManager(row);
        })
        .catch(() => {
          return false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../style/table.scss";
</style>
