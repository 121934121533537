<template>
  <el-row class="table">
    <el-row class="row-bg" :gutter="24" style="margin: 0; padding: 0 0 0 20px">
      <el-col :span="5">
        <el-input
          placeholder="请输入搜索内容"
          v-model="inputVal"
          class="input-with-select"
          @keyup.enter.native="search"
        >
          <el-select
            v-model="select"
            slot="prepend"
            placeholder="请选择"
            style="width: 100px"
          >
            <el-option label="用户名" value="1"></el-option>
            <el-option label="姓名" value="2"></el-option>
          </el-select>
        </el-input>
      </el-col>
      <el-col :span="3">
        <el-select v-model="status" placeholder="请选择状态">
          <el-option
            v-for="item in statusList"
            :key="item.name"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
      </el-col>

      <el-col :span="15">
        <el-button size="small" @click="search" class="tb-button"
          >搜 索
        </el-button>
        <el-button size="small" @click="reset" class="tb-button"
          >重 置
        </el-button>
        <!-- <el-button
          type="primary"
          size="small"
          @click="exportSearchData"
          class="tb-button"
          >导出
        </el-button> -->
        <!-- <el-button :disabled="editData.role === '查看者'" type="primary" size="small" @click="add" class="tb-button">添加成员</el-button> -->
      </el-col>
    </el-row>

    <el-row class="row-bg" style="margin: 0; padding: 20px 0 0 30px">
      <el-col>
        <!-- <el-button
          v-if="editData.pattern !== '极速'"
          :disabled="editData.role === '查看者'"
          type="primary"
          size="small"
          @click="openModal"
          class="tb-button"
          >添加成员
        </el-button> -->
        <el-button
          v-if="editData.pattern !== '极速'"
          :disabled="editData.role === '查看者'"
          type="primary"
          size="small"
          @click="add"
          class="tb-button"
          >添加成员</el-button
        >
        <el-button
          v-else
          :disabled="editData.role === '查看者'"
          type="primary"
          size="small"
          @click="add"
          class="tb-button"
          >添加极速成员
        </el-button>
        <!-- <template v-if="editData.raceRule != 'EPC'">
          <el-button
            :disabled="editData.role === '查看者'"
            size="small"
            @click="batchPass"
            class="tb-button"
            >批量通过
          </el-button>
          <el-button
            :disabled="editData.role === '查看者'"
            size="small"
            @click="batchRefund"
            class="tb-button"
            >批量拒绝
          </el-button>
          <el-button
            :disabled="editData.role === '查看者'"
            size="small"
            @click="openProblemVideo('currChoose')"
            class="tb-button"
            >开放题解
          </el-button>
        </template> -->
      </el-col>
    </el-row>

    <el-table
      :data="dataList"
      border
      fit
      ref="studentTable"
      highlight-current-row
      class="tb-list"
      :row-key="getRowKeys"
      @selection-change="handleselectChange"
    >
      <el-table-column
        type="selection"
        width="70"
        align="center"
        :reserve-selection="true"
      ></el-table-column>
      <el-table-column
        label="学号"
        align="center"
        prop="loginName"
      ></el-table-column>
      <el-table-column
        label="姓名"
        align="center"
        prop="name"
      ></el-table-column>
      <!-- <el-table-column
        label="负责人"
        align="center"
        prop="eduName"
      ></el-table-column> -->
      <el-table-column
        label="所属校区"
        align="center"
        prop="campusName"
      ></el-table-column>
      <!-- <el-table-column
        label="当前学习阶段"
        align="center"
        prop="catalogLearnTitle"
      ></el-table-column> -->
      <el-table-column
        label="报名时间"
        align="center"
        prop="createTime"
        width="200"
      ></el-table-column>
      <el-table-column
        label="状态"
        align="center"
        prop="status"
      ></el-table-column>

      <el-table-column label="操作" align="center" width="250">
        <template slot-scope="scope">
          <el-row v-if="scope.row.status === '待审核'">
            <el-button
              size="small"
              type="primary"
              plain
              @click="verifySuccess(scope.row)"
              >通过
            </el-button>
            <el-button
              size="small"
              type="primary"
              plain
              @click="verifyFailed(scope.row)"
              >拒绝
            </el-button>
          </el-row>
          <el-row
            v-else-if="
              scope.row.status === '正常' || scope.row.status === '审核失败'
            "
          >
            <el-button
              :disabled="editData.role === '查看者'"
              size="small"
              type="text"
              @click="DeleteRecords(scope.row)"
              v-has="['operation:DeleteRecords']"
              >删除提交记录
            </el-button>
            <el-button
              :disabled="editData.role === '查看者'"
              size="small"
              type="text"
              @click="deleteItem(scope.row)"
              >删除
            </el-button>
            <el-popover
              placement="right"
              width="300"
              v-if="editData.lotteryId"
              trigger="click"
            >
              <el-form ref="form" label-width="80px">
                <el-form-item label="发放次数" required>
                  <el-input v-model="lotteryNumber"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button
                    size="mini"
                    type="primary"
                    @click="handGrantLottery(scope.row)"
                    >发放</el-button
                  >
                  <el-button size="mini" @click.native="cancelPopover"
                    >取消</el-button
                  >
                </el-form-item>
              </el-form>
              <el-button
                slot="reference"
                style="margin-left: 10px"
                :disabled="editData.role === '查看者'"
                size="small"
                type="text"
                >手动发放抽奖
              </el-button>
            </el-popover>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <add-contest-student
      :show="addcontestDialog"
      @updateView="getContestUserList"
      :contestId="editData.contest_id + ''"
      @close="addcontestDialog = false"
    ></add-contest-student>
    <openproblemvideo
      :studentArray="multipleSelection"
      @updateView="resetArray"
      :show="videoDialog"
      :contestId="editData.contest_id + ''"
      @close="videoDialog = false"
    ></openproblemvideo>
    <el-pagination
      style="width: 65%"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>
    <SelectStudentComponents
      ref="student"
      @clear="clear"
      :multiple="false"
      :selected="studentList"
      @getSubmit="studentCallback"
    />
  </el-row>
</template>

<script>
import {
  getContestUserList,
  deleteUser,
  verifySuccess,
  verifyFailed,
  batchAddUser,
  deleteRecord,
} from "@/api/research/contest";
import { enableQues } from "@/api/research/quesBank";
import addContestStudent from "@/components/research/detail-pane/addContestStudent";
import openproblemvideo from "./openproblemvideo";
import SelectStudentComponents from "@/components/base-com/select/SelectStudents";
import dayjs from "dayjs";
import axios from "axios";
import { getToken } from "@/utils/auth";
import { editLotteryUser } from "../../../api/three-planning";

export default {
  components: {
    addContestStudent,
    openproblemvideo,
    SelectStudentComponents,
  },
  props: {
    editData: {
      default: () => {
        return {};
      },
      type: Object,
    },
  },
  data() {
    return {
      studentList: [],
      videoDialog: false,
      addcontestDialog: false,
      status: "",
      statusList: [
        {
          name: "待审核",
        },
        {
          name: "正常",
        },
        {
          name: "审核失败",
        },
      ],
      inputVal: "",
      select: "1",
      dataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0,
      },
      moduleTitle: "",
      creatorName: "",
      currentObject: null,
      lotteryNumber: 0,
      multipleSelection: [],
    };
  },
  created() {
    this.getContestUserList();
  },
  provide() {
    return {
      showRole: false,
      modal: true,
    };
  },
  methods: {
    //删除提交记录
    async DeleteRecords(row) {
      this.$confirm("确定删除当前学员在本题库的刷题记录吗？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async (res) => {
          let { contestUserId } = row;
          let { state } = await deleteRecord({
            contestUserId,
          });
          if (state === "success") {
            this.getContestUserList();
            window.$msg("删除成功");
          }
        })
        .catch(() => {
          return false;
        });
    },
    handGrantLottery(row) {
      if (this.lotteryNumber === 0) {
        return window.$msg("请先输入正确的发放次数", 2);
      }
      this.$confirm(
        "确定给 " +
          row.name +
          " 增加 " +
          this.lotteryNumber +
          " 次抽奖机会吗？",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(async () => {
          this.editLotteryUser(row);
        })
        .catch(() => {
          return false;
        });
    },
    async editLotteryUser(row) {
      const res = await editLotteryUser({
        lotteryId: this.editData.lotteryId,
        userId: row.userId,
        amount: this.lotteryNumber,
      });
      if (res && res.state === "success") {
        window.$msg("发放成功");
        this.lotteryNumber = 0;
        document.body.click();
      }
    },
    cancelPopover() {
      document.body.click();
    },
    exportSearchData() {
      this.$message.success("学员信息导出中...");
      const url =
        process.env.VUE_APP_API_ADDRESS + "contest/user/exportContestUser";
      axios({
        method: "post",
        url: url,
        headers: {
          Authorization: getToken(),
        },
        responseType: "blob",
        data: {
          contestId: this.editData.contest_id,
          loginName: this.select === "1" ? this.inputVal : "",
          name: this.select === "2" ? this.inputVal : "",
          status: this.status,
        },
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "学员信息.xlsx");
        document.body.appendChild(link);
        link.click();
      });
    },

    clear() {
      this.studentList = [];
    },
    openJsModal() {},
    openModal() {
      this.$refs.student.dialogVisible = true;
      this.studentList = [];
    },
    // 学生回调
    studentCallback(data) {
      if (data && data.length) {
        batchAddUser({
          contestId: this.editData.contest_id,
          userSets: data.map((i) => i.userId),
        }).then((res) => {
          this.getContestUserList();
        });
      }
    },
    resetArray() {
      this.$refs.studentTable.clearSelection();
    },
    getRowKeys(row) {
      return row.contestUserId;
    },

    handleselectChange(val) {
      this.multipleSelection = val;
    },
    batchPass() {
      if (this.multipleSelection.length === 0) {
        window.$msg("请先选择要审批的用户", 2);
        return false;
      } else {
        let flag = false;
        for (let i = 0; i < this.multipleSelection.length; i++) {
          if (this.multipleSelection[i].status === "审核失败") {
            flag = true;
            break;
          }
        }
        if (flag) {
          this.$confirm(
            "当前审批学员中有审核失败的状态学员，是否继续通过审核？",
            {
              confirmButtonText: "继续通过",
              cancelButtonText: "返回修改",
              type: "warning",
            }
          )
            .then(async () => {
              this.batchVerifySuccess();
            })
            .catch(() => {
              return false;
            });
        } else {
          this.batchVerifySuccess();
        }
      }
    },
    async openProblemVideo(rangeType) {
      if (rangeType == "currChoose") {
        if (this.multipleSelection.length === 0) {
          window.$msg("请先选择要开放题解视频的学员", 2);
          return false;
        }
        this.videoDialog = true;
      } else {
        let { state, body } = await enableQues([
          {
            contest_id: this.editData.contest_id,
            solutionVideoOpen: "Y",
          },
        ]);
        if (state == "success") {
          window.$msg("操作成功");
        }
      }
    },
    batchRefund() {
      if (this.multipleSelection.length === 0) {
        window.$msg("请先选择要审批的用户", 2);
        return false;
      } else {
        let flag = false;
        for (let i = 0; i < this.multipleSelection.length; i++) {
          if (this.multipleSelection[i].status === "正常") {
            flag = true;
            break;
          }
        }
        if (flag) {
          this.$confirm("当前审批学员中有审核通过的学员，是否继续拒绝？", {
            confirmButtonText: "继续通过",
            cancelButtonText: "返回修改",
            type: "warning",
          })
            .then(async () => {
              this.batchVerifyFailed();
            })
            .catch(() => {
              return false;
            });
        } else {
          this.batchVerifyFailed();
        }
      }
    },
    async batchVerifySuccess() {
      let thembArray = [];
      this.multipleSelection.forEach((item) => {
        thembArray.push(item.contestUserId);
      });
      const response = await verifySuccess(JSON.stringify(thembArray));
      if (response && response.state === "success") {
        window.$msg("批量审核完成");
        this.$refs.studentTable.clearSelection();
        this.getContestUserList();
      }
    },
    async batchVerifyFailed() {
      let thembArray = [];
      this.multipleSelection.forEach((item) => {
        thembArray.push(item.contestUserId);
      });
      const response = await verifyFailed(JSON.stringify(thembArray));
      if (response && response.state === "success") {
        window.$msg("批量审核完成");
        this.$refs.studentTable.clearSelection();
        this.getContestUserList();
      }
    },
    // 审核通过竞赛学员
    async verifySuccess(row) {
      let thembArray = [];
      thembArray.push(row.contestUserId);
      const response = await verifySuccess(JSON.stringify(thembArray));
      if (response && response.state === "success") {
        window.$msg("审核通过");
        this.getContestUserList();
      }
    },
    // 审核失败竞赛学员
    async verifyFailed(row) {
      let thembArray = [];
      thembArray.push(row.contestUserId);
      const response = await verifyFailed(JSON.stringify(thembArray));
      if (response && response.state === "success") {
        window.$msg("拒绝成功");
        this.getContestUserList();
      }
    },
    // 删除竞赛学员
    async deleteUser(row) {
      const response = await deleteUser({
        contestUserId: row.contestUserId,
      });
      if (response && response.state === "success") {
        window.$msg("删除成功");
        this.getContestUserList();
      }
    },
    // 查询竞赛学员
    async getContestUserList() {
      const response = await getContestUserList({
        needCount: true,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        contestId: this.editData.contest_id,
        loginName: this.select === "1" ? this.inputVal : "",
        name: this.select === "2" ? this.inputVal : "",
        status: this.status,
      });
      if (response && response.state === "success") {
        this.dataList = response.body.list.map((item) => {
          return {
            ...item,
            createTime: dayjs(item.createTime).format("YYYY-MM-DD HH:mm:ss"),
          };
        });
        this.pagination.total = response.body.total;
        this.$refs.studentTable.clearSelection();
      }
    },

    // 重置
    reset() {
      this.inputVal = "";
      this.select = "1";
      this.status = "";
    },

    // 搜索
    search() {
      this.pagination.currentPage = 1;
      this.getContestUserList();
    },

    add() {
      this.addcontestDialog = true;
    },

    // 分页
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.getContestUserList();
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.getContestUserList();
    },

    // 删除
    deleteItem(row) {
      this.$confirm("是否删除当前学员", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.deleteUser(row);
        })
        .catch(() => {
          return false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../style/table.scss";
</style>
