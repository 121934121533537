var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "batchImport" },
    [
      _c("el-input", {
        attrs: {
          type: "textarea",
          placeholder:
            "S0001\nS0002\nS0003\n……\n……\n请按每行一个学号的格式填写要添加的学员，点击导入按钮",
          rows: "8",
          "show-word-limit": "",
        },
        model: {
          value: _vm.batchStr,
          callback: function ($$v) {
            _vm.batchStr = $$v
          },
          expression: "batchStr",
        },
      }),
      _c(
        "el-button",
        {
          staticStyle: { "margin-top": "10px" },
          attrs: { size: "mini", type: "primary" },
          on: { click: _vm.importFun },
        },
        [_vm._v(" 导入 ")]
      ),
      _vm.isNonentity
        ? _c(
            "el-row",
            { staticClass: "result-box" },
            [
              _c("span", { staticClass: "seleRow" }, [
                _vm._v("不存在的用户名"),
              ]),
              _vm._l(_vm.nonexistence, function (item, index) {
                return _c("el-row", { key: index, staticClass: "seleList" }, [
                  _c("span", [_vm._v(_vm._s(item))]),
                ])
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }