<template>
  <!-- overflow-y: auto -->
  <!-- style="min-height: calc(100vh - 240px)" -->
  <div class="c-padding-left-16 c-padding-right-16">
    <div class="c-padding-8">
      <!-- <h3>基本信息：</h3> -->
      <div>
        <div>
          <span class="info-item"
            >结果：
            <el-tag
              type="success"
              v-if="
                solutionInfo.result <= 4 ||
                solutionInfo.result == 12 ||
                solutionInfo.result == 13
              "
            >
              {{ getType(solutionInfo.result) }}
            </el-tag>
            <el-tag
              type="danger"
              v-else-if="solutionInfo.result >= 5 && solutionInfo.result <= 11"
            >
              {{ getType(solutionInfo.result) }}
            </el-tag>
            <el-tag v-else type="warning">
              {{ getType(solutionInfo.result) }}
            </el-tag>
          </span>
          <span class="info-item" v-if="solutionList.length">
            测试用例通过率：{{ solutionInfo.pass_rate * 100 }}%
          </span>
        </div>
        <div>
          <span class="info-item"
            >语言：<span class="item-green">{{
              getLanText(item.language)
            }}</span></span
          >
          <span class="info-item"
            ><span class="item-green"
              >代码长度：{{ solutionInfo.code_length }}</span
            ></span
          >
          <span class="info-item"
            >运行时间：<span class="item-green"
              >{{ solutionInfo.time }}ms</span
            ></span
          >
          <span class="info-item"
            >占用内存：<span class="item-green"
              >{{ solutionInfo.memory }}KB</span
            ></span
          >

          <span class="info-item"
            >提交时间：
            {{
              solutionInfo.in_date
                ? solutionInfo.in_date.substring(
                    0,
                    solutionInfo.in_date.length - 2
                  )
                : "暂无"
            }}</span
          >
        </div>
        <div class="c-margin-top-8">
          <h3>代码：</h3>
          <!-- <textarea
            id="textarea"
            readonly
            v-model="solutionInfo.source"
          ></textarea> -->
          <div class="ace-editor" id="toptwo" ref="ace"></div>
          <!-- <div class="noCopy" v-if="codeOpen == 'n'"></div> -->
        </div>
      </div>
    </div>
    <div class="c-padding-8" v-if="solutionInfo.compileInfo">
      <h3>编译信息：</h3>
      <div
        class="c-margin-top-8 c-padding-16 info-item-box"
        style="padding: 20px"
      >
        <p
          v-if="solutionInfo.compileInfo"
          style="
            padding: 20px;
            white-space: pre-wrap;
            max-height: 500px;
            overflow-y: auto;
          "
        >
          {{ solutionInfo.compileInfo }}
        </p>
        <p v-else>暂无</p>
      </div>
    </div>
    <!-- <div class="c-padding-8" v-if="solutionInfo.runtimeInfo">
      <h3>运行错误信息：</h3>
      <div
        class="c-margin-top-8 c-padding-16 info-item-box"
        style="padding: 20px"
      >
        <p v-if="solutionInfo.runtimeInfo">
          {{ solutionInfo.runtimeInfo }}
        </p>
        <p v-else>暂无</p>
      </div>
    </div> -->
    <div
      class="c-padding-8"
      v-if="
        5 <= Number(solutionInfo.result) && Number(solutionInfo.result) < 11
      "
    >
      <h3>运行信息：</h3>
      <div
        class="c-margin-top-8 c-padding-16 info-item-box"
        style="
          padding: 20px;
          white-space: pre-wrap;
          max-height: 500px;
          overflow-y: auto;
        "
      >
        {{ solutionInfo.runtimeInfo }}
      </div>
    </div>
    <div class="c-padding-8" v-if="solutionList.length">
      <h3>用例信息：</h3>
      <div
        class="c-margin-top-8 c-padding-16 info-item-box"
        style="padding: 20px"
      >
        <p v-if="solutionList.length == 0" class="compileInfoText colorText">
          暂无
        </p>
        <el-row v-else>
          <!-- <el-col
            :span="12"
            v-for="(v, i) in solutionList"
            :key="i"
            :style="{
              'border-color': getResult(v.runState),
              color: getResult(v.runState),
            }"
          >
            <div class="borderDiv">
              <span
                >#{{ i + 1 }}
                <span style="margin-left: 5px"
                  >{{ v.runTime }}/{{ v.memory }}</span
                ></span
              >
              <span
                >{{ getrunState(v.runState)
                }}<i :class="getIcon(v.runState)"></i
              ></span>
            </div>
          </el-col> -->
          <!-- <div class="useCases">
            <div
              class="useCasesItem"
              v-for="(v, i) in solutionList"
              :key="i"
              :style="{
                background: getuseCases(v.runState),
                color: '#fff',
              }"
            >
              <span style="font-size: 12px">#{{ i + 1 }}</span>
              <div class="useCasesDiv">
                <span style="font-size: 16px">{{ v.runState }}</span>
                <span style="margin-top: 5px; font-size: 12px"
                  >{{ v.runTime }}/{{ v.memory }}</span
                >
              </div>
            </div>
          </div> -->
          <div style="display: flex; flex-wrap: wrap">
            <div v-for="(item, index) in solutionList">
              <div
                class="baseClass"
                :class="item.runState === 'AC' ? 'ACclass' : 'WAclass'"
              >
                <span class="yongliIndex">#{{ item.fileName }}</span>
                <span class="yongliStatus">{{ item.runState }}</span>
                <span class="yongliData"
                  >{{ item.runTime }}/{{ item.memory }}</span
                >
              </div>
            </div>
          </div>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import getLanText from "@/mixins/status";
import ace from "ace-builds";
import "ace-builds/src-noconflict/snippets/javascript";
import "ace-builds/src-noconflict/snippets/html";
import "ace-builds/src-noconflict/snippets/java";
import "ace-builds/src-noconflict/snippets/c_cpp";
import "ace-builds/src-noconflict/snippets/pascal";
import "ace-builds/src-noconflict/snippets/ruby";
import "ace-builds/src-noconflict/snippets/batchfile";
import "ace-builds/src-noconflict/snippets/python";
import "ace-builds/src-noconflict/snippets/php";
import "ace-builds/src-noconflict/snippets/perl";
import "ace-builds/src-noconflict/snippets/objectivec";
import "ace-builds/src-noconflict/snippets/scheme";
import "ace-builds/src-noconflict/snippets/clojure";
import "ace-builds/src-noconflict/snippets/lua";
import "ace-builds/src-noconflict/snippets/javascript";
import "ace-builds/src-noconflict/snippets/golang";
import "ace-builds/src-noconflict/snippets/gobstones";
import "ace-builds/src-noconflict/snippets/django";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/theme-clouds_midnight";
import "ace-builds/src-noconflict/mode-javascript";
import {
  getSubmitHistoryItem,
  getSubmitHistoryItemMore,
  getSubmitHistoryItemQues,
  getSolutionDetailMore,
} from "@/api/research/question";
import { getSolutionDetail } from "@/api/research/quesBank";
export default {
  name: "RecordDetail",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    codeOpen: {
      default: "y",
      type: String,
    },
    // 查看更多记录需要另外调取接口
    ojSumbitType: {
      type: String,
      default: "",
    },
  },
  mixins: [getLanText],

  data() {
    return {
      solutionId: "",
      solutionInfo: {},
      solutionList: [],
      editor: null,
      aceEditor: null,
      themePath: "ace/theme/chrome",
      modePath: "ace/mode/c_cpp",
    };
  },
  computed: {
    getPass: function () {
      if (this.solutionList.length) {
        let acNum = this.solutionList.filter((item) => {
          return item.runState == "AC";
        }).length;
        return (acNum / this.solutionList.length) * 100 + "%";
      } else {
        return 0;
      }
    },
  },
  created() {
    console.log("item", this.item);
    this.solutionId = this.item.solution_id;
  },
  mounted() {
    this.solutionId = this.item.solution_id;
    if (this.solutionId) {
      setTimeout(() => {
        this.initData();
      }, 200);
    }
  },
  methods: {
    // 获取数据
    initData() {
      this.getSubmitHistoryItem();
      // this.getSolutionDetail();
    },
    // 记录详情
    getSubmitHistoryItem() {
      let apiAxios = null;
      if (this.ojSumbitType == "longsince") {
        apiAxios = getSubmitHistoryItemMore({ solution_id: this.solutionId });
      } else {
        apiAxios = getSubmitHistoryItem({ solution_id: this.solutionId });
      }
      apiAxios.then((res) => {
        if (res.body) {
          this.solutionInfo = res.body;
          this.aceEditor = ace.edit(this.$refs.ace, {
            fontSize: 16,
            value: this.solutionInfo.source,
            theme: this.themePath,
            mode: this.modePath,
            wrap: true,
            tabSize: 4,
          });
          ace.require("ace/ext/language_tools");
          // 激活自动提示
          this.aceEditor.setOptions({
            enableSnippets: true,
            enableLiveAutocompletion: true,
            enableBasicAutocompletion: true,
          });
        }
      });
    },
    // 用例详情
    getSolutionDetail() {
      let apiAxios = null;
      if (this.ojSumbitType == "longsince") {
        apiAxios = getSolutionDetailMore({ solutionId: this.solutionId });
      } else {
        apiAxios = getSolutionDetail({ solutionId: this.solutionId });
      }
      apiAxios.then((res) => {
        if (res.state == "success" && res.body) {
          // let test = [
          //   {
          //     solutionId: 39557,
          //     problemId: "1074",
          //     runTime: "6ms",
          //     memory: "5264k",
          //     fileName: "sample.in",
          //     runState: "AC",
          //   },
          // ]
          this.solutionList = res.body;
        }
      });
    },
    getResult(state) {
      switch (state) {
        case "AC":
          return "#11f502";
          break;
        default:
          return "#f70606";
      }
    },
    getuseCases(state) {
      switch (state) {
        case "AC":
          return "rgb(82,196,26)";
          break;
        default:
          return "rgb(231,76,60)";
      }
    },
    getrunState(state) {
      switch (state) {
        case "AC":
          return "正确";
          break;
        default:
          return "答案错误";
      }
    },
    getIcon(state) {
      switch (state) {
        case "AC":
          return "el-icon-success";
          break;
        default:
          return "el-icon-error";
      }
    },
    html2Escape(sHtml) {
      return sHtml.replace(/[<>&"]/g, function (c) {
        return { "<": "&lt;", ">": "&gt;", "&": "&amp;", '"': "&quot;" }[c];
      });
    },
    getResult1(state) {
      switch (state) {
        case 4:
          return "#f70606";
          break;
        case 6:
          return "#11f502";
          break;
        default:
          return "#F5C319";
      }
    },
    getSubType(state) {
      switch (state) {
        case "OJ":
          return "在线判题系统";
          break;
        case "AC":
          return "通过";
          break;
        case "WA":
          return "答案错误";
          break;
        case "TLE":
          return "超时";
          break;
        case "LOE":
          return "超出输出限制";
          break;
        case "MLE":
          return "超内存";
          break;
        case "RE":
          return "运行时错误";
          break;
        case "PE":
          return "格式错误";
          break;
        case "CE":
          return "无法编译";
          break;
        default:
          return "未知状态";
      }
    },
    getType(state) {
      switch (state) {
        case 0:
          return "等待";
          break;
        case 1:
          return "等待重判";
          break;
        case 2:
          return "编译中";
          break;
        case 3:
          return "运行并评判";
          break;
        case 4:
          return "答案正确";
          break;
        case 5:
          return "格式错误";
          break;
        case 6:
          return "答案错误";
          break;
        case 7:
          return "时间超限";
          break;
        case 8:
          return "内存超限";
          break;
        case 9:
          return "输出超限";
          break;
        case 10:
          return "运行错误";
          break;
        case 11:
          return "编译错误";
          break;
        case 12:
          return "编译成功";
          break;
        case 13:
          return "运行完成";
          break;
        default:
          return "未知状态";
      }
    },
  },
};
</script>
<style scoped>
.c-padding-8 {
  padding: 8px;
}
.c-margin-top-8 {
  margin-top: 8px;
}
.info-item {
  padding: 8px;
  display: inline-block;
  margin-right: 16px;
}
.info-item-box {
  border: 1px solid #eee;
  /* max-height: 200px; */
  overflow-y: auto;
}
.item-green {
  color: green;
}
.borderDiv {
  height: 50px;
  border-style: solid;
  border-width: thin thin thin medium;
  justify-content: space-around;
  display: flex;
  align-items: center;
  margin: 10px;
}
.el-dialog__body {
  padding: 10px 20px;
}
.useCases {
  display: flex;
  flex-wrap: wrap;
}
.useCasesItem {
  width: 100px;
  height: 90px;
  margin: 20px;
  padding: 10px;
  border-radius: 8px;
}
.useCasesDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
<style>
.w-e-text-container {
  padding: 16px 0 16px 16px;
}
#textarea {
  border: 1px solid #eee;
  outline: none;
  display: block;
  padding: 16px;
  width: 100%;
  min-height: 160px;
  color: #606266;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 15px;
}
.baseClass {
  width: 100px;
  height: 100px;
  margin: 5px;
  color: white;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}
.ACclass {
  background: #52c41a;
}
.WAclass {
  background: #e74c3c;
}
.yongliIndex {
  margin-top: 10px;
  margin-left: 10px;
}

.yongliParent {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow-y: auto;
  max-height: 300px;
  font-size: 14px;
}
.yongliStatus {
  width: 100%;
  text-align: center;
  font-size: 20px;
}
.yongliData {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}
.ace-editor {
  width: 100%;
  height: 500px;
  overflow-y: auto;
}
.noCopy {
  width: 100%;
  height: 500px;
  overflow-y: auto;
  position: absolute;
  top: 197px;
  left: 0;
}
</style>
