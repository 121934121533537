var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "deparment" },
    [
      _c(
        "div",
        { staticClass: "treeDiv" },
        [
          _c("CoduckTreeSelect", {
            ref: "coduckTree",
            attrs: {
              data: _vm.treeData,
              "default-props": _vm.defaultProps,
              "default-expand-all": true,
              "highlight-current": true,
              "show-checkbox": false,
              "render-content": _vm.renderContent,
              "expand-on-click-node": false,
            },
            on: { nodeClick: _vm.nodeClick },
          }),
        ],
        1
      ),
      _c("EmployeeList", {
        ref: "employeeList",
        attrs: { size: _vm.size, "table-selection": _vm.tableSelection },
        on: { changeSelectionData: _vm.changeSelectionData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }