import $axios from '@/utils/request'

// 获取部门列表
export function getDepartmentList (data) {
  const url = '/department/list'
  return $axios.fPost(url, data)
}
// 获取部门树
export function getDepartmentTreeData (data) {
  const url = '/department/treeData'
  return $axios.fPost(url, data)
}
//新增部门
export function addDepartmentMethod (data) {
  const url = '/department/add'
  return $axios.fPost(url, data)
}
//编辑部门
export function editDepartment (data) {
  const url = '/department/edit'
  return $axios.fPost(url, data)
}
//删除部门
export function removeDepartment (data) {
  const url = '/department/remove'
  return $axios.fGet(url, data)
}
//查询员工所属部门
export function getUserDepartmentByUser (data) {
  const url = '/department/getUserDepartmentByUser'
  return $axios.fGet(url, data)
}
//更新员工所属部门
export function updateUserDepartment (data) {
  const url = '/department/updateUserDepartment'
  return $axios.fPost(url, data)
}
