import $axios from '@/utils/request'

// 获取活动评价维度
export function getJudgedDemensionListByActivity (data) {
  const url = '/activity/judgedimension/getJudgedDemensionListByActivity'
  return $axios.fGet(url, data)
}
// 根据活动记录id获取作品信息
export function getCreationByReord (data) {
  const url = '/activity/recordcreation/getCreationByReord'
  return $axios.fGet(url, data)
}

// 获取活动管理员列表
export function listPermDataByBusiness (data) {
  const url = '/data/perm/listPermDataByBusiness'
  return $axios.fGet(url, data)
}

// 删除管理员
export function deleteMember (data) {
  const url = '/data/perm/delete'
  return $axios.fGet(url, data)
}

// 添加管理员
export function addManager (data) {
  const url = '/data/perm/addManager'
  return $axios.fPost(url, data)
}

// 评审作品得分
export function judgeScore (data) {
  const url = '/activity/recordcreation/judgeScore'
  return $axios.fPost(url, data)
}

// 新增活动评价维度
export function insertJudgedDemension (data) {
  const url = '/activity/judgedimension/insertJudgedDemension'
  return $axios.fPost(url, data)
}

// 编辑活动分类列表
export function editJudgedDemension (data) {
  const url = '/activity/judgedimension/editJudgedDemension'
  return $axios.fPost(url, data)
}

// 活动记录的列表
export function listActivityRecord (data) {
  const url = '/activity/listActivityRecord'
  return $axios.fPost(url, data)
}

// 获取活动分类列表
export function getTreeCategorys (data) {
  const url = '/activity/catalog/getTreeCategorys'
  return $axios.fGet(url, data)
}

// 新增活动分类列表
export function insertCatalog (data) {
  const url = '/activity/catalog/insertCatalog'
  return $axios.fPost(url, data)
}

// 编辑活动分类列表
export function editCaltalog (data) {
  const url = '/activity/catalog/editCaltalog'
  return $axios.fPost(url, data)
}

// 批量审核成功
export function verifySuccess (data) {
  const url = '/activity/activitySign/verifySuccess'
  return $axios.fPost(url, data)
}

// 批量审核失败
export function verifyFailed (data) {
  const url = '/activity/activitySign/verifyFailed'
  return $axios.fPost(url, data)
}

// 设置优秀作品（包含取消）
export function bestWorks (data) {
  const url = '/creation/editBatch'
  return $axios.fPost(url, data)
}

// 设置奖项
export function setPriceBatch (data) {
  const url = '/activity/recordcreation/setPriceBatch'
  return $axios.fPost(url, data)
}


