var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.tagsDialog
    ? _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.tagsDialog,
            "append-to-body": "",
            width: "800px",
            top: "7vh",
            "before-close": () => {
              _vm.$emit("update:tagsDialog", false)
            },
          },
          on: {
            "update:visible": function ($event) {
              _vm.tagsDialog = $event
            },
          },
        },
        [
          _c("v-classify", {
            ref: "vClassify",
            staticStyle: { height: "500px" },
            attrs: {
              currentTag: _vm.currentTag,
              pageType: _vm.handleType,
              classifyList: _vm.tagsTree,
              getAllChoose: true,
            },
            on: {
              getTagValue: _vm.getTagValue,
              markTagForCustomer: _vm.markTagForCustomer,
              getAllChooseTags: _vm.getAllChooseTags,
              close: _vm.close,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }