var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "row-bg", attrs: { gutter: 24 } },
    [
      _vm.editData.lotteryOpen === "Y"
        ? _c(
            "el-button",
            {
              staticStyle: { "margin-left": "60px", "margin-bottom": "20px" },
              attrs: {
                type: "primary",
                size: "mini",
                disabled:
                  _vm.editData.role === "查看者" || _vm.editData.isGrant,
              },
              on: { click: _vm.grantLottery },
            },
            [
              _vm._v(
                _vm._s(
                  _vm.editData.isGrant ? "已发放抽奖次数" : "发放抽奖次数"
                ) + " "
              ),
            ]
          )
        : _vm._e(),
      _c(
        "el-form",
        {
          ref: "formData",
          staticClass: "formParent",
          attrs: {
            model: _vm.formData,
            "label-width": "140px",
            "label-position": "right",
          },
        },
        [
          _vm.isNormal && _vm.formData.lotteryOpen === "Y"
            ? _c(
                "el-form-item",
                { attrs: { label: "绑定抽奖：" } },
                [
                  _vm.formData.lotteryObject && _vm.formData.lotteryObject.id
                    ? _c(
                        "div",
                        [
                          _vm.formData.lotteryObject
                            ? _c(
                                "el-tag",
                                {
                                  attrs: { closable: "" },
                                  on: { close: _vm.closeTag },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.formData.lotteryObject.title)
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-left": "15px" },
                              attrs: { size: "mini" },
                              on: { click: _vm.gotoLotterySetting },
                            },
                            [_vm._v("配置抽奖奖项")]
                          ),
                        ],
                        1
                      )
                    : _c(
                        "el-button",
                        {
                          attrs: {
                            disabled:
                              _vm.editData.role !== "管理者" &&
                              _vm.editData.role !== "创建者",
                            size: "mini",
                          },
                          on: {
                            click: function ($event) {
                              _vm.bindFlag = true
                            },
                          },
                        },
                        [_vm._v("绑定抽奖 ")]
                      ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("bind-lottery-activity", {
        attrs: { show: _vm.bindFlag },
        on: {
          close: function ($event) {
            _vm.bindFlag = false
          },
          chooseCurrent: _vm.chooseCurrent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }