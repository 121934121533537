<template>
  <div class="deparment">
    <!-- tree -->
    <div class="treeDiv">
      <CoduckTreeSelect
        ref="coduckTree"
        :data="treeData"
        :default-props="defaultProps"
        :default-expand-all="true"
        :highlight-current="true"
        :show-checkbox="false"
        :render-content="renderContent"
        :expand-on-click-node="false"
        @nodeClick="nodeClick"
      />
    </div>
    <!-- table -->
    <EmployeeList
      :size="size"
      ref="employeeList"
      :table-selection="tableSelection"
      @changeSelectionData="changeSelectionData"
    >
    </EmployeeList>
  </div>
</template>
<script>
import CoduckTreeSelect from "@/components/base-com/components/coduck-tree-select.vue";
import EmployeeList from "./EmployeeList.vue";
import { getDepartmentList } from "@/api/system/department";

export default {
  name: "EmployeeCom",
  components: { CoduckTreeSelect, EmployeeList },
  props: {
    tableSelection: {
      type: Map,
      default: () => new Map(),
    },
    size: {
      type: String,
      default: "small",
    },
  },
  data() {
    return {
      currentDpid:'',
      treeData: [],
      // 树结构
      defaultProps: {
        children: "children",
        label: "name",
      },
    };
  },
  inject: {
    // 注入父组件传的值，可以写一个默认的值，如果父组件没有返回数据的话
    multiple: {
      default: true,
    },
  },
  mounted() {
    this.getDepartmentList();
  },
  methods: {
    changeSelectionData() {
      this.$emit("changeSelectionData");

    },
    // 反选
    reverseElectionClick() {
      this.$refs.employeeList.reverseElectionClick();
    },
    // 获取部门
    getDepartmentList() {
      getDepartmentList({
        pageNum: 1,
        pageSize: 1000000,
      }).then((res) => {
        if (res.state === "success") {
          this.treeData = this.handleTree(res.body, "id");
        } else {
          this.treeData = [];
          this.$message.warning(res.errMsg);
        }
        this.currentDpid = localStorage.getItem('dpid') ? localStorage.getItem('dpid') :''
        this.$nextTick(()=>{
          this.$refs.coduckTree.setCurrentNodeThemb(this.currentDpid)
          this.$refs.employeeList.deptId = this.currentDpid
          this.$refs.employeeList.getList()
        })

        setTimeout(()=> {
          document.getElementsByClassName('is-current')[0].scrollIntoView({behavior: "smooth",block:"center"})
        },500)
      });
    },
    nodeClick(obj, node, self) {
      this.$refs.employeeList.handleDeptNodeClick(node.data, self);
    },
    // 树自定义
    renderContent(h, { node, data, store }) {
      return (
        <span class="custom-tree-node">
          <i style="margin-right:8px" class="cdesign cdesign-bumen" />
          <span class="oneline-style" title={node.label}>
            {node.label}
          </span>
        </span>
      );
    },
  },
};
</script>
<style scoped lang="scss">
.toolbar-style {
  display: inline-block;
  text-align: right;
  position: absolute;
  right: 0;
}
.oneline-style {
  display: inline-block;
  width: 100%;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.deparment {
  height: 450px;
  display: flex;
  .treeDiv {
    width: 240px;
    border-right: 1px solid #eee;
    height: 449px;
    overflow-y: auto;
    margin-left: -16px;
  }
  .treeDiv::-webkit-scrollbar {
    width: 3px;
    height: 5px;
  }
  .tables {
    padding: 16px 0 0 16px;
    width: 459px;
    .handle {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      height: 32px;
      .w100 {
        width: 180px !important;
      }
    }
    ::v-deep .el-table__body-wrapper::-webkit-scrollbar {
      width: 3px;
      height: 5px;
    }
    ::v-deep .pagination {
      margin: 0 0 0 -36px;
      padding-top: 8px;
    }
  }
}
</style>
