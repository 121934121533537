<template>
  <div class="selected-box">
    <!-- 已选择 -->
    <div style="border: 1px solid #eee">
      <el-row style="padding: 16px">
        <el-col :span="18">
          <span>已选:（{{ tableSelected.length }}）</span>
        </el-col>
        <el-col :span="2">
          <i
            class="el-icon-sort-down pointer"
            title="降序"
            @click="selectDesc"
          />
        </el-col>
        <el-col :span="2">
          <i class="el-icon-sort-up pointer" title="升序" @click="selectAsce" />
        </el-col>
        <el-col :span="2">
          <i class="el-icon-delete-solid pointer" @click="deleteAll" />
        </el-col>
      </el-row>
      <div class="sel-list" :style="{ height: flagHeight + 'px' }">
        <div
          v-for="(item, index) in tableSelected"
          :key="index"
          class="hover-style oneline-style"
        >
          <span v-if="item.loginName">
            [{{ item.loginName }}]{{ item.name }}
          </span>
          <span v-else>
            {{ item.name }}
          </span>
          <i class="el-icon-delete-solid pointer" @click="deleteSelect(item)" />
        </div>
      </div>
    </div>
    <!-- 角色 -->
    <el-row v-if="showRole" style="padding: 16px 0">
      <el-select
        placeholder="请选择角色"
        v-model="roleVal"
        style="width: 100%"
        class="role"
      >
        <el-option
          v-for="item in roleOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </el-row>
    <!-- 管理员 -->
    <el-row v-if="showAdmin" style="padding: 16px 0">
      <el-select
        placeholder="请选择角色"
        v-model="adminVal"
        style="width: 100%"
        class="role"
      >
        <el-option
          v-for="item in groupthreeOptions"
          :key="item.label"
          :label="item.label"
          :value="item.label"
        />
      </el-select>
    </el-row>
    <!-- 活动 -->
    <el-row v-if="showActivity" style="padding: 16px 0">
      <el-select
        placeholder="请选择角色"
        v-model="activityVal"
        style="width: 100%"
        class="role"
      >
        <el-option
          v-for="item in activitList"
          :key="item.id"
          :label="item.roleName"
          :value="item.id"
        />
      </el-select>
    </el-row>
    <!-- 组别 -->
    <el-row v-if="showGroup" style="padding: 16px 0">
      <el-select
        placeholder="请选择组别"
        v-model="groupVal"
        style="width: 100%"
        class="role"
      >
        <el-option
          v-for="item in groupList"
          :key="item.id"
          :label="item.categoryName"
          :value="item.id"
        />
      </el-select>
    </el-row>
    <!-- 赛事组别 -->
    <el-row v-if="showCourse" style="padding: 16px 0">
      <el-select
        placeholder="请选择赛事组别"
        v-model="courseVal"
        style="width: 100%"
        class="role"
      >
        <el-option
          v-for="item in courseList"
          :key="item.id"
          :label="item.tagName"
          :value="item.id"
        />
      </el-select>
    </el-row>
    <!-- 排课 -->
    <!--    <el-row v-if="showLession" style="padding:4px 0">-->
    <!--      <el-select-->
    <!--          style="width: 100%"-->
    <!--          v-model="multiplesIds"-->
    <!--          multiple-->
    <!--          placeholder="请选择排课数据"-->
    <!--        >-->
    <!--          <el-option v-for="item in dataList" :key="item.id" :label="item.label" :value="item.id"></el-option>-->
    <!--        </el-select>-->
    <!--    </el-row>-->
    <!-- 时间 -->
    <el-row v-if="showTime" style="padding: 4px 0">
      <el-date-picker
        value-format="yyyy-MM-dd"
        style="width: 100%"
        v-model="eduServeStartTime"
        type="date"
        placeholder="请选择生效日期"
      >
      </el-date-picker>
    </el-row>
    <!-- 星级 -->
    <!-- <el-row v-if="showStar" style="padding:4px 0">
      <el-row>
        <el-col :span="6">推荐度：</el-col>
        <el-col :span="18">
        <el-rate v-model="star"></el-rate>
        </el-col>
      </el-row>
    </el-row> -->
    <!-- 负责人 -->
    <!--    <el-row v-if="showTeacher" style="padding:4px 0">-->
    <!--      <el-select-->
    <!--          style="width: 100%"-->
    <!--          v-model="teacherVal"-->
    <!--          placeholder="请选择排课数据"-->
    <!--        >-->
    <!--          <el-option v-for="item in teacherList" :key="item.label" :label="item.label" :value="item.label"></el-option>-->
    <!--        </el-select>-->
    <!--    </el-row>-->
  </div>
</template>
<script>
import { getRoleList } from "@/api/planning/activity";
import { formatDate } from "@/utils/formatDate";
import { getTreeCategorys } from "@/api/planning/codeActivity";

import {
  getListStudentForClass,
  setListByLoginNames,
  getLessonInfoByClassId,
} from "@/api/senate/class";
export default {
  name: "SelectedCom",
  props: {
    tableSelected: {
      type: Array,
      default: () => [],
    },
    showRole: {
      type: Boolean,
      default: true,
    },
    showLession: {
      type: Boolean,
      default: false,
    },
    showTime: {
      type: Boolean,
      default: false,
    },
    showAdmin: {
      type: Boolean,
      default: false,
    },
    showActivity: {
      type: Boolean,
      default: false,
    },
    showStar: {
      type: Boolean,
      default: false,
    },
    showGroup: {
      type: Boolean,
      default: false,
    },
    showCourse: {
      type: Boolean,
      default: false,
    },
    showTeacher: {
      type: Boolean,
      default: false,
    },
    activityIDCourse: {
      type: String,
      default: "",
    },
    activityID: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      courseVal: "",
      teacherVal: "",
      roleVal: "查看者",
      adminVal: "管理者",
      groupVal: "",
      star: "",
      activityVal: "",
      roleOptions: [
        {
          value: "管理者",
          label: "管理者",
        },
        {
          value: "编辑者",
          label: "编辑者",
        },
        {
          value: "查看者",
          label: "查看者",
        },
      ],
      groupthreeOptions: [
        {
          id: "管理者",
          label: "管理者",
        },
        {
          id: "运营者",
          label: "运营者",
        },
        {
          id: "查看者",
          label: "查看者",
        },
      ],
      teacherList: [
        {
          id: "教务",
          label: "教务",
        },
        {
          id: "老师",
          label: "老师",
        },
      ],
      dataList: [],
      multiplesIds: [],
      eduServeStartTime: "",
      activitList: [],
      groupList: [],
      courseList: [],
    };
  },
  computed: {
    flag() {
      return (
        this.showRole ||
        this.showLession ||
        this.showAdmin ||
        this.showActivity ||
        this.showStar ||
        this.showGroup ||
        this.showTeacher ||
        this.showCourse
      );
    },
    flagHeight() {
      let arr = [
        this.showRole,
        this.showLession,
        this.showAdmin,
        this.showActivity,
        this.showStar,
        this.showGroup,
        this.showTeacher,
        this.showCourse,
      ];
      let h = 430 - arr.filter((i) => i).length * 40;
      console.log(h);
      return h;
    },
  },
  async mounted() {
    if (this.$route.query.classID) {
      this.getLessonInfoByClassId();
    }
    if (this.showActivity) {
      const res = await getRoleList({
        businessCode: "0010",
      });
      if (res.state === "success") {
        this.activitList = res.body;
      }
    }
    if (this.activityID) {
      this.getTreeCategorys();
    }
    if (this.activityIDCourse) {
      this.activityCourseContest();
    }
  },
  methods: {
    // 获取活动组别
    async activityCourseContest() {
      await activityCourseContest({
        activityId: this.activityIDCourse,
        level: 1,
      }).then((res) => {
        if (res.state === "success") {
          this.courseList = res.body;
        }
      });
    },
    async getTreeCategorys() {
      await getTreeCategorys({
        activityId: this.activityID,
      }).then((res) => {
        if (res.state === "success") {
          this.groupList = res.body;
        }
      });
    },
    async getLessonInfoByClassId() {
      // 获取班级排课列表
      const res = await getLessonInfoByClassId({
        pageNum: 1,
        pageSize: 10000,
        class_id: this.$route.query.classID,
        isDesc: false,
      });

      this.dataList = res.body.list;

      this.dataList.forEach((item) => {
        item.label =
          formatDate(new Date(item.start_time), "yyyy-MM-dd hh:mm~") +
          formatDate(new Date(item.end_time), "hh:mm");
      });
    },
    // 升序
    selectAsce() {
      this.tableSelected = this.tableSelected.sort((a, b) => {
        return a.loginName.substring(1) - b.loginName.substring(1);
      });
    },
    // 降序
    selectDesc() {
      this.tableSelected = this.tableSelected.sort((a, b) => {
        return b.loginName.substring(1) - a.loginName.substring(1);
      });
    },
    // 已选择全部删除
    deleteAll() {
      this.$emit("deleteItem");
    },
    // 已选择里删除单条
    deleteSelect(item) {
      this.$emit("deleteItem", item);
    },
  },
};
</script>
<style lang="scss" scoped>
.selected-box {
  .sel-list {
    overflow: auto;
    border-top: 1px solid #eee;
    padding: 8px 0 8px 0;
  }
  .sel-list::-webkit-scrollbar {
    width: 3px;
    height: 5px;
  }
  .hover-style {
    line-height: 32px;
    padding-left: 16px;
    .pointer {
      display: none;
    }
  }
  .hover-style:hover {
    background-color: #edf6ff;
    .pointer {
      display: inline-block;
    }
  }
}
</style>
