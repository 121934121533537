import $axios from '@/utils/request'


//获取重复数据记录
export function listRepeatByExcel(data) {
  return $axios.fPost('/excelLog/list_repeat_by_excel',data)
}
//校验意向客户重复
export function checkRepeatCustomer(data) {
  return $axios.fPost('/crm/customer/checkRepeatCustomer',data)
}
//批量指定客户(兼顾有无负责人)
export function assignConfusedCustomer(data) {
  return $axios.fPost('/crm/operate/assignConfusedCustomer',data)
}
//添加试听排课预约
export function addAuditionStudent(data) {
  return $axios.fPost('/lesson/addAuditionStudent',data)
}
//查看导入详情
export function getLogInfo(data) {
  return $axios.fGet('/excelLog/getLogInfo',data)
}
//查询意向客户的最后一条代办
export function getLatestRemainDealt(data) {
  return $axios.fGet('/system/remainDealt/getLatestRemainDealt',data)
}
//公海间转移
export function transferBetweenPools(data) {
  return $axios.fPost('/crm/pool/transferBetweenPools',data)
}
//删除共享负责人
export function deleteSharingLeader(data) {
  return $axios.fPost('/crm/customer/leader/deleteSharingLeader',data)
}
//根据客户查看共享负责人
export function getLeadersByCustomer(data) {
  return $axios.fGet('/crm/customer/leader/getLeadersByCustomer',data)
}
//批量为客户标记标签
export function batchMarkTagForCustomer(data) {
  // return $axios.fPost('/crm/customer/batchMarkTagForCustomer', data)
  return $axios.fPost('/tag/realtion/batchMarkTagForRelation',data)

}
//根据客户Id拉取试听账户
export function getAuditionUser(data) {
  return $axios.fGet('/crm/customer/getAuditionUser',data)
}
//根据客户Id查询试听记录
export function getAuditionRecord(data) {
  return $axios.fGet('/crm/customer/getAuditionRecord',data)
}
// 不去重批量新增客户
export function addRepeatCustomerBat(data) {
  return $axios.fPost('/crm/customer/addRepeatCustomerBat',data)
}
// 根据学员Id获取意向客户信息
export function getCustomerByRelatedUser(data) {
  return $axios.fGet('/crm/customer/getCustomerByRelatedUser',data)
}
// 成单操作
export function completeCustomer(data) {
  return $axios.fPost('/crm/operate/completeCustomer',data)
}
// 指定共享负责人
export function appointSharingLeader(data) {
  return $axios.fPost('/crm/customer/appointSharingLeader',data)
}

// 查询我的共享客户列表
export function listSharingCrmCustomer(data) {
  return $axios.fPost('crm/customer/listSharingCrmCustomer',data)
}
// 获取所有公海列表
export function getAllPoolList(data) {
  return $axios.fPost('/crm/pool/listPool',data)
}
// 获取重复数据
export function batchEditCustomer(data) {
  return $axios.fPost('/crm/customer/batchEditCustomer',data)
}
// 获取负责的公海列表
export function getPoolList(data) {
  return $axios.fPost('/crm/pool/getPoolList',data)
}
// 查询我的客户列表
export function listCustomer(data) {
  return $axios.fPost('/crm/customer/listCrmCustomer',data)
}
// 查询我的客户列表
export function listCrmCustomerByTrader(data) {
  return $axios.fPost('/crm/customer/listCrmCustomerByTrader',data)
}
// 查询公海客户列表
export function listCustomerInPool(data) {
  return $axios.fPost('/crm/customer/listCustomerInPool',data)
}

// 查询客户详细
export function getCustomer(data) {
  return $axios.fGet('/crm/customer/getInfo',data)
}

// 新增客户
export function addCustomer(data) {
  return $axios.fPost('/crm/customer/addCustomer',data)
}

// 修改客户
export function updateCustomer(data) {
  return $axios.fPost('/crm/customer/editCustomer',data)
}

// 放弃客户
export function giveUpCustomer(data) {
  return $axios.fPost('/crm/operate/giveUpCustomer',data)
}
// 回收客户
export function recoverCustomer(data) {
  return $axios.fPost('/crm/operate/recoverCustomer',data)
}
// 指定客户
export function assignCustomer(data) {
  return $axios.fPost('/crm/operate/assignCustomer',data)
}
// 领取客户
export function fetchCustomer(data) {
  return $axios.fPost('/crm/operate/fetchCustomer', data)
}
// 为客户添加标签
export function markTagForCustomer(data) {
  // return $axios.fPost('/crm/customer/markTagForCustomer',data)
  return $axios.fPost('/tag/realtion/markTagForRealtion',data)
}
// 转让客户
export function passCustomer(data) {
  return $axios.fPost('/crm/operate/passCustomer',data)
}

// 导出客户
export function exportCustomer(data) {
  return $axios.fGet('/system/customer/export',data)
}
// 删除客户
export function delCustomer(data) {
  return $axios.fGet('/crm/customer/delCustomer',data)
}
// 批量导入客户
export function addCustomerBat(data) {
  return $axios.fPost('/crm/customer/addCustomerBat',data)
}
// 年龄列表
export function getSysDictByType(data) {
  return $axios.fPost('/dict/getSysDictByType',data)
}
// 是否有已经存在的客户账号
export function userExist(data) {
  return $axios.fGet('/crm/user/exist',data)
}
// 获取客户详细信息
export function getCustomerInfo(data) {
  return $axios.fGet('/crm/customer/getInfo',data)
}
// 获取意向课程或者沟通结果
export function getSysDictByTypeGet(data) {
  return $axios.fGet('/dict/getSysDictByType',data)
}
// 关联账号列表
export function getCrmMapUser(data) {
  return $axios.fPost('/crm/customer_stuent/getCrmMapUser',data)
}
// 获取未绑定得重复账号
export function detectionBindable(data) {
  return $axios.fPost('/crm/customer_stuent/detectionBindable',data)
}
// 变更渠道来源
export function replaceCustomerChannelSources(data) {
  return $axios.fPost('/crm/customer/replaceCustomerChannelSources',data)
}
// 关联账户
export function customerStuentbindUser(data) {
  return $axios.fPost('/crm/customer_stuent/bindUser',data)
}
// 获取成单学员详情
export function getOperateUserInfo(data) {
  return $axios.fGet('/crm/operate/getUserInfo',data)
}
// 发送待办任务
export function sendTodoTask(data) {
  return $axios.fPost('/crm/operate/sendTodoTask',data)
}

// 取消意向客户
export function cancelSharing(data) {
  return $axios.fPost('/crm/customer/leader/cancelSharing',data)
}
// 渠道来源左侧树
export function getCustomerSourceTree(data) {
  return $axios.fPost('/crm/source/my/tree',data)
}

// 渠道来源右侧列表
export function getCustomerSourceList(data) {
  return $axios.fPost('/crm/customer/list/with_source',data)
}



// 成单记录查询
export function listFinish(data) {
  return $axios.fPost('/crm/customer/listFinish',data)
}
// 撤销成单
export function revertComplete(data) {
  return $axios.fPost('/crm/operate/revertComplete',data)
}
// 获取手机号(意向学员)
export function getCustomerPhone(data) {
  return $axios.fGet('/crm/customer/phone',data)
}
// 获取手机号(学员)
export function getUserPhone(data) {
  return $axios.fGet('/user/phone',data)
}
// 变更手机号
export function updateCustomerPhone(data) {
  return $axios.fPost('/crm/customer/phone/update',data)
}
// 变更手机号
export function updateUserPhone(data) {
  return $axios.fPost('/user/phone/update',data)
}
// 基于意向学员获取学员id
export function getRelatedUserid(data) {
  return $axios.fGet('/crm/customer/related/user',data)
}
// 基于学员获取意向学员id
export function getRelatedCustid(data) {
  return $axios.fGet('/user/related/customer',data)
}
// 视图列表
export function getViewList(data) {
  return $axios.fPost('/view/list',data)
}
// 视图新增
export function addView(data) {
  return $axios.fPost('/view/add',data)
}
// 视图更新
export function updateView(data) {
  return $axios.fPost('/view/update',data)
}
// 视图删除
export function deleteView(data) {
  return $axios.fGet('/view/delete',data)
}


// 渠道激活
export function activateChannels(data) {
  return $axios.fPost('/crm/customer/activateChannels',data)
}
// 校验操作权限
export function operateCheck(data) {
  return $axios.fGet('/crm/operate/check',data)
}
// 公海数据总览接口
export function listCustomerInPoolOverview(data) {
  return $axios.fPost('/crm/stat/listCustomerInPoolOverview',data)
}
// 获取员工可分配数量接口
export function getFollowCountLimit(data) {
  return $axios.fGet('/crm/customer/getFollowCountLimit',data)
}
// 批量分配数据接口
export function batchOverview(data) {
  return $axios.fPost('/crm/operate/batch_pass_pool_overview',data)
}

// 业绩列表
export function salelockList(data) {
  return $axios.fPost('/fin/salelock/list',data)
}
// 新增业绩锁定
export function salelockReplace(data) {
  return $axios.fPost('/fin/salelock/replace',data)
}
// 删除业绩锁定
export function salelockDelete(data) {
  return $axios.fPost('/fin/salelock/delete',data)
}
// 编辑业绩锁定
export function salelockEdit(data) {
  return $axios.fPost('/fin/salelock/edit',data)
}
// 领取（含特定权限）
export function fetchCustomerSpecificRules(data) {
  return $axios.fPost('/crm/operate/fetchCustomerSpecificRules',data)
}
// 共享给自己
export function appointSharingLeaderSpecificRules(data) {
  return $axios.fPost('/crm/operate/appointSharingLeaderSpecificRules',data)
}
// 指派共享负责人到客户
export function appointLeaderForCustomer(data) {
  return $axios.fPost('/crm/customer/appointLeaderForCustomer',data)
}