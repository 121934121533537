var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("el-input", {
        staticStyle: {
          width: "40%",
          "margin-top": "10px",
          "margin-left": "10px",
        },
        attrs: {
          placeholder: "请输入完整的姓名、学号或手机号",
          clearable: "",
          size: "small",
        },
        on: { close: _vm.clearSearch },
        model: {
          value: _vm.searchText,
          callback: function ($$v) {
            _vm.searchText = $$v
          },
          expression: "searchText",
        },
      }),
      _c(
        "el-button",
        {
          staticStyle: { "margin-top": "10px", "margin-left": "10px" },
          attrs: { size: "mini", type: "primary" },
          on: { click: _vm.searchStudent },
        },
        [_vm._v(" 搜索 ")]
      ),
      _vm.dataList.length
        ? _c(
            "el-table",
            {
              ref: "table",
              attrs: {
                fit: "",
                data: _vm.dataList,
                "highlight-current-row": true,
              },
              on: {
                select: _vm.handleSelectionChange,
                "select-all": _vm.selectAll,
                "row-click": _vm.handleSelect,
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55", align: "center" },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "姓名", prop: "name" },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "学号", prop: "loginName" },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "手机号", prop: "tel" },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }