<template>
  <el-row class="row-bg" :gutter="24">
    <el-button
      v-if="editData.lotteryOpen === 'Y'"
      type="primary"
      size="mini"
      style="margin-left: 60px; margin-bottom: 20px"
      :disabled="editData.role === '查看者' || editData.isGrant"
      @click="grantLottery"
      >{{ editData.isGrant ? "已发放抽奖次数" : "发放抽奖次数" }}
    </el-button>
    <el-form
      class="formParent"
      ref="formData"
      :model="formData"
      label-width="140px"
      label-position="right"
    >
      <el-form-item
        label="绑定抽奖："
        v-if="isNormal && formData.lotteryOpen === 'Y'"
      >
        <div v-if="formData.lotteryObject && formData.lotteryObject.id">
          <el-tag closable @close="closeTag" v-if="formData.lotteryObject">{{
            formData.lotteryObject.title
          }}</el-tag>
          <el-button
            style="margin-left: 15px"
            size="mini"
            @click="gotoLotterySetting"
            >配置抽奖奖项</el-button
          >
        </div>
        <el-button
          :disabled="editData.role !== '管理者' && editData.role !== '创建者'"
          v-else
          size="mini"
          @click="bindFlag = true"
          >绑定抽奖
        </el-button>
      </el-form-item>
    </el-form>

    <bind-lottery-activity
      :show="bindFlag"
      @close="bindFlag = false"
      @chooseCurrent="chooseCurrent"
    ></bind-lottery-activity>
  </el-row>
</template>

<script>
import { getContextInfo } from "@/api/research/contest";
import {
  grantLottery,
  addBusiness,
  deleteBusiness,
} from "../../../api/three-planning";
import bindLotteryActivity from "./bindLotteryActivity";

export default {
  name: "contest-lottery",
  components: { bindLotteryActivity },
  props: {
    editData: {
      default: () => {
        return {};
      },
      type: Object,
    },
  },
  inject: ["getContextInfoDetail"],
  data() {
    let RichTimeVal = (rule, value, callback) => {
      if (!this.formData.applyTime || this.formData.applyTime.length === 0) {
        callback(new Error("请选择报名时间"));
      } else {
        callback();
      }
    };
    let contestTimeVal = (rule, value, callback) => {
      if (
        !this.formData.contestTime ||
        this.formData.contestTime.length === 0
      ) {
        callback(new Error("请选择竞赛时间"));
      } else {
        callback();
      }
    };
    return {
      bindFlag: false,
      tagsArr: [],
      currChooseArr: [],
      currTagsTree: [],
      tagsDialog: false,
      rankAnnounce: "Y",
      matchDuration: 1,
      enrollTime: false,
      applychecked: false,
      checked: false,
      dataList: [],
      normalizer(node) {
        return {
          ...node,
          label: node.title,
          value: node.id,
        };
      },
      listLevel: [], // 课程系列
      formData: {
        // 公共form
        contestTime: [],
        raceType: "",
        timeCostRank: "N",
        title: "", //
        description: "", // 竞赛介绍
        codeOpen: "y", // 复制代码
        solutionVideoOpen: "N", // 开放题解
        lotteryOpen: "N", // 开放抽奖
        lotteryObject: null, //关联抽奖
        //
        raceRule: "", // 赛制
        isPrivate: null, // 报名方式
        isVerify: "", // 是否审核报名
        applyTime: [], // 报名时间
        //
        duration: null, // 答题时长
        rankAnnounce: "", // 开放排行榜
        catalogLearnId: "", // 课程系列
      },
    };
  },
  computed: {
    isNormal() {
      return ["普通竞赛"].includes(this.formData.raceType);
    },
    isSpeed() {
      return ["极速竞赛"].includes(this.formData.raceType);
    },
    isEdit() {
      return this.isEditDisabled;
    },
  },
  created() {
    this.getContextInfo();
  },
  methods: {
    grantLottery() {
      this.$confirm("确定发放此次竞赛的抽奖机会吗？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.grantLotterySure();
        })
        .catch(() => {
          return false;
        });
    },
    async grantLotterySure() {
      const res = await grantLottery({
        contestId: this.editData.contest_id,
      });
      if (res && res.state === "success") {
        window.$msg("发放成功");
        this.editData.isGrant = true;
      }
    },
    gotoLotterySetting() {
      this.$router.push({
        path: "/operation/activityReward-details",
        query: {
          id: this.formData.lotteryObject.id,
        },
      });
    },
    closeTag() {
      this.deleteBusiness();
    },
    chooseCurrent(data) {
      //选择的绑定的抽奖活动
      this.formData.lotteryObject = data;
      this.addBusiness();
    },
    handleChangePrivate(value) {
      if (value === 1) {
        this.formData.applyTime = [];
      }
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs["formData"].validate((valid) => {
          if (valid) {
            const dateCalc =
              this.formData.applyTime.length > 1 &&
              this.formData.contestTime.length > 1;
            if (dateCalc) {
              const date =
                new Date(this.formData.applyTime[1]).getTime() >
                new Date(this.formData.contestTime[1]).getTime();
              if (date && this.formData.isPrivate === 1) {
                this.$message.warning("报名结束时间不能大于竞赛结束时间!");
                // eslint-disable-next-line prefer-promise-reject-errors
                reject([false, {}]);
              } else {
                resolve([valid, this.formData]);
              }
            } else {
              resolve([valid, this.formData]);
            }
          } else {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject([false, {}]);
          }
        });
      });
    },
    handleChangeType(label) {
      if (this.formData.raceType) {
        this.$confirm("切换竞赛类型会清空当前配置, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.resetForm();
            this.formData.raceType = label;
            this.$emit("changeType", label);
          })
          .catch(() => {});
      } else {
        this.formData.raceType = label;
      }
    },
    // 充值
    resetForm() {
      this.$refs["formData"].clearValidate();

      this.formData = {
        contestTime: [],
        raceType: "",
        timeCostRank: "N",
        description: "", // 竞赛介绍
        codeOpen: "y", // 复制代码
        solutionVideoOpen: "N", // 开放题解
        lotteryOpen: "N", // 开放抽奖
        lotteryObject: null, //关联抽奖
        //
        type: "", // 赛制
        isPrivate: null, // 报名方式
        isVerify: "", // 是否审核报名
        applyTime: "", // 报名时间
        //
        duration: null, // 答题时长
        rankAnnounce: "", // 开放排行榜
        title: this.formData.title,
        catalogLearnId: "",
      };
    },

    handlechecked() {
      if (!this.applychecked) {
        this.formData.applyTime = [];
      }
    },
    handleContestchecked() {
      if (!this.checked) {
        this.formData.contestTime = [];
      }
    },

    radioChange(value) {
      if (this.formData.opentype === 1) {
        this.enrollTime = false;
        this.formData.isVerify = "N";
        this.formData.applyTime = [
          "0001-01-01 00:00:00",
          "9999-12-31 23:59:59",
        ];
      } else {
        this.enrollTime = true;
        this.formData.isVerify = "Y";
        this.formData.applyTime = [];
      }
    },

    async addBusiness() {
      const [valid, data] = await this.validate();
      if (valid) {
        const params = {
          businessId: this.$route.query.contestId,
          lotteryId: data.lotteryObject.id,
          businessType: "contest",
        };
        const response = await addBusiness(params);
        if (response && response.state === "success") {
          window.$msg("绑定成功");
        }
      }
    },
    async deleteBusiness() {
      const [valid, data] = await this.validate();
      if (valid) {
        const params = {
          businessId: parseInt(this.$route.query.contestId),
          lotteryId: data.lotteryObject.id,
        };
        const response = await deleteBusiness(params);
        if (response && response.state === "success") {
          this.formData.lotteryObject = null;
          window.$msg("已删除绑定的抽奖活动");
        }
      }
    },
    // 查看竞赛详情
    async getContextInfo() {
      const response = await getContextInfo({
        contest_id: this.editData.contest_id,
        defunct: "",
      });

      if (response && response.state === "success") {
        const data = response.body;
        this.formData = { ...this.formData, ...data };
        this.formData.lotteryObject =
          data.lotteryOpen === "Y"
            ? {
                id: data.lotteryId,
                title: data.lotteryTitle,
              }
            : null;

        this.formData.applyTime = [];
        this.formData.contestTime = [];

        if (data.endTime === "9999-12-31 23:59:59.0") {
          this.formData.contestTime = [];
        } else {
          this.formData.contestTime.push(data.startTime);
          this.formData.contestTime.push(data.endTime);
        }

        if (data.endEnrollTime === "9999-12-31 23:59:59.0") {
          this.formData.applyTime = [];
        } else {
          this.formData.applyTime.push(data.startEnrollTime);
          this.formData.applyTime.push(data.endEnrollTime);
        }

        console.log(
          data,
          this.formData,
          data.endEnrollTime.indexOf("9999-12-31 23:59:59.0") === -1
        );
      }
    },
  },
};
</script>

<style scoped>
.row-bg {
  background: #fff;
  margin-bottom: 15px;
  padding: 15px;
}
</style>
