<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    :close-on-click-modal="false"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>绑定抽奖</span>
    </div>
    <el-button
      type="primary"
      size="small"
      style="margin-bottom: 15px"
      @click="addLotteryActivity = true"
      >新增抽奖
    </el-button>
    <el-table
      :data="dataList"
      border
      fit
      highlight-current-row
      ref="dragTable"
      row-key="id"
    >
      <el-table-column
        label="名称"
        align="center"
        prop="title"
      ></el-table-column>

      <el-table-column label="开始时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.startTime | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column label="结束时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.endTime | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="创建人"
        align="center"
        prop="creator"
      ></el-table-column>
      <el-table-column label="操作" align="center" width="150">
        <template slot-scope="scope">
          <el-button
            size="small"
            type="primary"
            plain
            @click="chooseCurrentLottory(scope.row)"
            >选择</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>
    <add-lottory
      :show="addLotteryActivity"
      @close="addLotteryActivity = false"
      @updateView="getDataList"
    ></add-lottory>
  </el-dialog>
</template>

<script>
import { getLotteryList } from "@/api/three-planning";
import addLottory from "@/views/operation/components/addLottory";

export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
  },
  components: { addLottory },
  data() {
    return {
      addLotteryActivity: false,
      dataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0,
      },
    };
  },
  methods: {
    chooseCurrentLottory(row) {
      this.$confirm("确定绑定当前抽奖活动吗？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.$emit("chooseCurrent", row);
          this.close();
        })
        .catch(() => {
          return false;
        });
    },
    // 分页
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.getDataList();
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.getDataList();
    },

    openDialog() {
      this.getDataList();
    },
    async getDataList() {
      const res = await getLotteryList({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        needCount: true,
      });
      this.pagination.total = res.body.total;
      this.dataList = res.body.list;
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
</style>
>
