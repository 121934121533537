var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "row-bg", attrs: { gutter: 24 } },
    [
      _c(
        "el-form",
        {
          ref: "formData",
          staticClass: "formParent",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "140px",
            "label-position": "right",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "题集标题：", prop: "title" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: { placeholder: "请输入题集名称", maxlength: "20" },
                model: {
                  value: _vm.formData.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "title", $$v)
                  },
                  expression: "formData.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "题集类型：", prop: "raceType" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: "", value: _vm.formData.raceType },
                  on: { input: _vm.handleChangeType },
                },
                _vm._l(_vm.raceTypeList, function (item) {
                  return _c(
                    "el-radio",
                    { key: item.value, attrs: { label: item.value } },
                    [_vm._v(_vm._s(item.label) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm.isNormal
            ? _c(
                "el-form-item",
                { attrs: { label: "赛制：", prop: "raceRule" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: "" },
                      on: {
                        change: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                      model: {
                        value: _vm.formData.raceRule,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "raceRule", $$v)
                        },
                        expression: "formData.raceRule",
                      },
                    },
                    _vm._l(_vm.raceRuleList, function (item) {
                      return _c(
                        "el-radio",
                        { key: item.value, attrs: { label: item.value } },
                        [_vm._v(_vm._s(item.label) + " ")]
                      )
                    }),
                    1
                  ),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content:
                          "CA表示刷题类型的题集，题集模式创建完成不可更改",
                        placement: "right",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-question",
                        staticStyle: {
                          "font-size": "18px",
                          "margin-left": "20px",
                        },
                      }),
                    ]
                  ),
                  ["OI", "IOI"].includes(_vm.formData.raceRule)
                    ? _c(
                        "el-checkbox",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: { "true-label": "Y", "false-label": "N" },
                          model: {
                            value: _vm.formData.timeCostRank,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "timeCostRank", $$v)
                            },
                            expression: "formData.timeCostRank",
                          },
                        },
                        [_vm._v(" 统计排名时计算用时 ")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.isNormal
            ? _c(
                "el-form-item",
                { attrs: { label: "报名方式：", prop: "isPrivate" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { input: _vm.handleChangePrivate },
                      model: {
                        value: _vm.formData.isPrivate,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "isPrivate", $$v)
                        },
                        expression: "formData.isPrivate",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("公开赛"),
                      ]),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("私有赛"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: { effect: "dark", placement: "right" },
                    },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "content" }, slot: "content" },
                        [
                          _c("p", [
                            _vm._v("公开赛，允许学习平台用户查看和报名"),
                          ]),
                          _c("p", [
                            _vm._v(
                              "私有赛，用户不能主动报名，只能有题集管理员手动添加参赛成员"
                            ),
                          ]),
                        ]
                      ),
                      _c("i", {
                        staticClass: "el-icon-question",
                        staticStyle: {
                          "font-size": "18px",
                          "margin-left": "20px",
                        },
                      }),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isNormal && _vm.formData.isPrivate === 0
            ? _c(
                "el-form-item",
                { attrs: { label: "是否审核报名：", prop: "isVerify" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.formData.isVerify,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "isVerify", $$v)
                        },
                        expression: "formData.isVerify",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "Y" } }, [_vm._v("是")]),
                      _c("el-radio", { attrs: { label: "N" } }, [_vm._v("否")]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isNormal && _vm.formData.isPrivate !== 1
            ? _c(
                "el-form-item",
                { attrs: { label: "报名时间：", prop: "applyTime" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetimerange",
                      "start-placeholder": "活动报名开始时间",
                      "end-placeholder": "活动报名结束时间",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "default-time": ["00:00:00"],
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$forceUpdate()
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                    model: {
                      value: _vm.formData.applyTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "applyTime", $$v)
                      },
                      expression: "formData.applyTime",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          ["普通竞赛", "极速竞赛"].includes(_vm.formData.raceType)
            ? _c(
                "el-form-item",
                { attrs: { label: "题集时间：", prop: "contestTime" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetimerange",
                      "start-placeholder": "题集开始时间",
                      "end-placeholder": "题集结束时间",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "default-time": ["00:00:00"],
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$forceUpdate()
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                    model: {
                      value: _vm.formData.contestTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "contestTime", $$v)
                      },
                      expression: "formData.contestTime",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "可用语言：", prop: "langmask" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    multiple: "",
                    filterable: "",
                    placeholder: "请选择编程语言",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                  model: {
                    value: _vm.formData.langmask,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "langmask", $$v)
                    },
                    expression: "formData.langmask",
                  },
                },
                _vm._l(_vm.languageList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "允许复制代码：", prop: "codeOpen" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.formData.codeOpen,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "codeOpen", $$v)
                    },
                    expression: "formData.codeOpen",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "y" } }, [_vm._v("是")]),
                  _c("el-radio", { attrs: { label: "n" } }, [_vm._v("否")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isNormal
            ? _c(
                "el-form-item",
                { attrs: { label: "开放抽奖：", prop: "lotteryOpen" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.formData.lotteryOpen,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "lotteryOpen", $$v)
                        },
                        expression: "formData.lotteryOpen",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "Y" } }, [_vm._v("是")]),
                      _c("el-radio", { attrs: { label: "N" } }, [_vm._v("否")]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "开放题解：", prop: "solutionVideoOpen" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.formData.solutionVideoOpen,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "solutionVideoOpen", $$v)
                    },
                    expression: "formData.solutionVideoOpen",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "Y" } }, [_vm._v("是")]),
                  _c("el-radio", { attrs: { label: "N" } }, [_vm._v("否")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isSpeed
            ? _c(
                "el-form-item",
                { attrs: { label: "答题时长：", prop: "duration" } },
                [
                  _c("el-input-number", {
                    attrs: { placeholder: "请输入答题时长", min: 1 },
                    model: {
                      value: _vm.formData.duration,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "duration", $$v)
                      },
                      expression: "formData.duration",
                    },
                  }),
                  _c("span", { staticStyle: { "margin-left": "8px" } }, [
                    _vm._v("min"),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.isSpeed
            ? _c(
                "el-form-item",
                { attrs: { label: "开放排行榜：", prop: "rankAnnounce" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.formData.rankAnnounce,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "rankAnnounce", $$v)
                        },
                        expression: "formData.rankAnnounce",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "Y" } }, [_vm._v("是")]),
                      _c("el-radio", { attrs: { label: "N" } }, [_vm._v("否")]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-row",
        { staticStyle: { display: "flex", "justify-content": "center" } },
        [
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              attrs: {
                disabled:
                  _vm.editData.role !== "管理者" &&
                  _vm.editData.role !== "创建者",
                type: "primary",
                size: "small",
              },
              on: { click: _vm.updateQuestionLibrary },
            },
            [_vm._v("保存 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }