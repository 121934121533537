<template>
  <span>
    <el-dialog
      title="选择学员"
      :visible.sync="dialogVisible"
      width="1000px"
      class="select-box"
      @open="selectHandler"
      append-to-body
      modal-append-to-body
      :modal="showModal"
      top="10vh"
    >
      <span slot="title" class="dialogTitle">选择学员</span>
      <el-row class="contain">
        <el-row class="left-box">
          <el-tabs v-model="activeName" size="small" @tab-click="selectHandler">
            <el-tab-pane label="我负责的" name="my">
              <MyStudentCom
                :size="size"
                ref="my"
                :urlType="urlType"
                :table-selection="tableSelection"
                :searchType="searchType"
                @changeSelectionData="changeSelectionData"
              />
            </el-tab-pane>
            <el-tab-pane label="校区学员" name="student">
              <CampusStudentCom
                :size="size"
                ref="student"
                :table-selection="tableSelection"
                :urlType="urlType"
                :searchType="searchType"
                @changeSelectionData="changeSelectionData"
              />
            </el-tab-pane>
            <el-tab-pane label="员工" name="employee" v-if="isShowEmployee">
              <EmployeeCom
                :size="size"
                ref="employee"
                :table-selection="tableSelection"
                :searchType="searchType"
                @changeSelectionData="changeSelectionData"
              />
            </el-tab-pane>
            <el-tab-pane label="批量导入" name="import">
              <BatchImportCom
                ref="import"
                :size="size"
                :table-selection="tableSelected"
                :searchType="searchType"
                @changeSelectionData="changeSelectionData"
              />
            </el-tab-pane>
            <el-tab-pane label="学员检索" name="search">
              <SearchCom
                ref="search"
                :size="size"
                :table-selection="tableSelection"
                :searchType="searchType"
                @changeSelectionData="changeSelectionData"
              />
            </el-tab-pane>
          </el-tabs>
        </el-row>
        <el-row class="right-box">
          <SelectedCom
            :size="size"
            ref="selected"
            :table-selected="tableSelected"
            @deleteItem="deleteItem"
            :show-role="showRole"
            :show-lession="showLession"
            :show-star="showStar"
            :show-group="showGroup"
            :activityID="activityID"
            :show-course="showCourse"
            :activityIDCourse="activityIDCourse"
          />
        </el-row>
      </el-row>
      <div slot="footer" style="text-align: right">
        <el-button :size="size" @click="closeModal">取消</el-button>
        <el-button :size="size" type="primary" @click="submit">确定</el-button>
      </div>
    </el-dialog>
  </span>
</template>
<script>
import SelectedCom from "@/components/base-com/select/components/SelectedCom";
import EmployeeCom from "@/components/base-com/select/components/EmployeeCom";
import CampusStudentCom from "@/components/base-com/select/components/CampusStudentCom";
import MyStudentCom from "@/components/base-com/select/components/MyStudentCom";
import BatchImportCom from "@/components/base-com/select/components/BatchImportCom";
import SearchCom from "@/components/base-com/select/components/SearchCom";

export default {
  name: "SelectComponent",
  components: {
    SelectedCom,
    CampusStudentCom,
    EmployeeCom,
    BatchImportCom,
    MyStudentCom,
    SearchCom,
  },
  props: {
    selected: {
      type: [Object, Array],
      default: () => [],
    },
    size: {
      type: String,
      default: "small",
    },
    modal: {
      type: Boolean,
      default: false,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
    showLession: {
      type: Boolean,
      default: false,
    },
    showStar: {
      type: Boolean,
      default: false,
    },
    showGroup: {
      type: Boolean,
      default: false,
    },
    showCourse: {
      type: Boolean,
      default: false,
    },
    isShowEmployee: {
      type: Boolean,
      default: true,
    },
    urlType: {
      type: String,
      default: "",
    },
    searchType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialogVisible: false,
      tableSelection: new Map(),
      tableSelected: [],
      activeName: "my",
    };
  },
  watch: {
    selected: {
      handler(n, o) {
        this.tableSelected = [...n];
        this.tableSelection.clear();
        this.tableSelected.forEach((i) => {
          this.tableSelection.set(i.userId, i);
        });
        console.log("this.tableSelection", this.tableSelection);
      },
      deep: true,
      immediate: true,
    },
  },
  inject: {
    // 注入父组件传的值，可以写一个默认的值，如果父组件没有返回数据的话
    multiple: {
      default: true,
    },
    showRole: {
      default: true,
    },
    modal: {
      default: false,
    },
    activityID: {
      default: "",
    },
    activityIDCourse: {
      default: "",
    },
  },
  methods: {
    //
    closeModal() {
      this.dialogVisible = false;
      this.$emit("clear");
    },
    clear() {
      this.tableSelected = [];
      this.tableSelection.clear();
      this.tableSelected.forEach((i) => {
        this.tableSelection.set(i.userId, i);
      });
    },
    // 打开重置表格选择
    selectHandler() {
      setTimeout(() => {
        this.$refs[this.activeName].reverseElectionClick();
      }, 100);
    },
    // changeSelectionData() {
    //   this.tableSelected = [...this.tableSelection].map(i => i[1]);
    //   this.tableSelection.clear()
    //   this.tableSelected.forEach(i => {
    //     this.tableSelection.set(i.userId, i)
    //   })
    //   this.selectHandler();
    // },
    // 表格选中数据
    changeSelectionData(item) {
      if (this.multiple) {
        if (item) {
          this.tableSelection.set(item.userId, item);
        }
      } else {
        if (item) {
          this.tableSelection.clear();
          this.tableSelection.set(item.userId, item);
        }
      }
      this.tableSelected = [...this.tableSelection].map((i) => i[1]);
      this.tableSelection.clear();
      this.tableSelected.forEach((i) => {
        this.tableSelection.set(i.userId, i);
      });
      this.selectHandler();
    },
    deleteItem(item) {
      if (item) {
        // 删除单条
        this.tableSelection.delete(item.userId);
        this.tableSelected = [...this.tableSelection].map((i) => i[1]);
      } else {
        // 全部删除
        this.tableSelected = [];
        this.tableSelection.clear();
      }
      this.$refs.student.reverseElectionClick();
      this.$refs.employee.reverseElectionClick();
      this.$refs.search.reverseElectionClick();
    },
    submit() {
      let multiplesIds = this.$refs.selected.multiplesIds;
      let star = this.$refs.selected.star;
      let groupVal = this.$refs.selected.groupVal;
      let courseVal = this.$refs.selected.courseVal;
      // if (this.showStar) {
      //   if (!star) {
      //     window.$msg("请选择星级", 2);
      //     return false;
      //   }
      // }
      if (this.showCourse) {
        if (!courseVal) {
          window.$msg("请选择组别", 2);
          return false;
        }
      }
      this.$emit(
        "getSubmit",
        this.tableSelected,
        this.$refs.selected.roleVal,
        multiplesIds,
        star,
        groupVal,
        courseVal
      );
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-tabs__active-bar {
  // background-color:none !important;
}
.dialog-footer {
  text-align: right !important;
}
.select-box {
  --border: 1px solid #eee;
  ::v-deep .el-dialog__header {
    border-bottom: var(--border);
  }
  ::v-deep .el-dialog__footer {
    border-top: var(--border);
  }
  ::v-deep .el-dialog__body {
    padding: 16px 16px 0 !important;
  }
  .contain {
    display: flex;
    .pointer {
      cursor: pointer;
    }
    .left-box {
      border: var(--border);
      width: 700px;
      height: 500px;
      ::v-deep .el-tabs__item {
        margin: 5px 10px;
      }
      ::v-deep .el-tabs__active-bar {
        display: none;
        // margin-left: 10px !important;
      }
      ::v-deep .el-tabs__nav-scroll {
        padding: 0px;
      }
      ::v-deep .el-tabs__active-bar {
        // left: 5px !important;
      }
      ::v-deep .el-tabs__content {
        // padding: 8px;
      }
      ::v-deep .el-tabs__header {
        margin: 0;
      }
    }
    .right-box {
      margin-left: 10px;
      flex: 1;
      .already {
        border: var(--border);
        height: 454px;
        padding: 8px;
      }
      .heightPro {
        height: 500px;
      }
      .role {
        width: 100%;
        height: 30px;
        margin-top: 10px;
      }
    }
    .alreadyList {
      height: 390px;
      overflow-y: auto;
      div {
        padding: 3px;
      }
    }
  }
}
</style>
