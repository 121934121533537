<template>
  <el-row>
    <el-tag
      size="mini"
      class="tagClass"
      style="margin-left: 20px"
      v-if="tagsArr.length == 0"
      @click="addTag()"
      >添加标签</el-tag
    >
    <el-tag
      size="mini"
      v-for="(item, index) in tagsArr.slice(0, 2)"
      :key="index"
      type="warning"
      class="tagClass"
      @click="addTag()"
    >
      <span>{{ item.title }}</span>
    </el-tag>
    <span v-if="tagsArr.length > 2" style="margin-left: 5px">
      ...
      <el-link
        type="warning"
        style="font-size: 12px; margin-left: 15px"
        @click="addTag()"
        >更多</el-link
      >
    </span>
    <subjectTags
      :tagsDialog.sync="tagsDialog"
      v-if="tagsDialog"
      handleType="'编辑'"
      :tagsTree="currTagsTree"
      :currentTag="currChooseArr"
      title="竞赛标签"
      @getAllChooseTags="getAllChooseTags"
    />
  </el-row>
</template>

<script>
import getTag from "@/mixins/getTag";
import subjectTags from "@/components/research/question-manage/subjectTags.vue";
import { markTagForRealtion } from "@/api/frontbusiness";
import { getTagsByRelationId } from "@/api/learnmanage/examine";
export default {
  mixins: [getTag],
  components: { subjectTags },
  props: {
    editData: {
      default: () => {
        return {};
      },
      type: Object,
    },
  },
  data() {
    return {
      tagsArr: [],
      currChooseArr: [],
      currTagsTree: [],
      tagsDialog: false,
    };
  },
  created() {},
  mounted() {
    console.log("editData", this.editData);
    this.getTagsByRelationId();
  },
  methods: {
    // 获取标签
    async getTagsByRelationId() {
      let { body } = await getTagsByRelationId({
        relationId: this.editData.contest_id,
      });
      this.tagsArr = body;
    },
    // 添加标签（打开弹窗）
    async addTag(type) {
      this.getContestTagGroupList().then(() => {
        this.currChooseArr = JSON.parse(JSON.stringify(this.tagsArr));
        this.currTagsTree = this.tagTreeData;
        console.log("选择之前this.currChooseArr", this.currChooseArr);
        console.log("选择之前this.currTagsTree ", this.currTagsTree);
        // 编辑
        for (let i = 0; i < this.currTagsTree.length; i++) {
          for (let k = 0; k < this.currTagsTree[i].children.length; k++) {
            this.currTagsTree[i].children[k].state = false;
          }
        }
        for (let i = 0; i < this.currChooseArr.length; i++) {
          for (let k = 0; k < this.currTagsTree.length; k++) {
            if (this.currTagsTree[k].children) {
              for (let q = 0; q < this.currTagsTree[k].children.length; q++) {
                if (
                  this.currTagsTree[k].children[q].id ==
                  this.currChooseArr[i].id
                ) {
                  this.currTagsTree[k].children[q].state = true;
                }
              }
            }
          }
        }
        console.log("选择之后this.currChooseArr", this.currChooseArr);
        console.log("选择之后this.currTagsTree ", this.currTagsTree);
        this.tagsDialog = true;
      });
    },
    // 确定添加标签（新增）
    async getAllChooseTags(data) {
      console.log("data", data);
      if (data.length) {
        this.tagsArr = [];
        let { state } = await markTagForRealtion({
          tagIds: data.map((item) => {
            return item.id;
          }),
          type: "竞赛",
          relationId: this.editData.contest_id,
        });
        if (state == "success") {
          this.getTagsByRelationId();
        }
      } else {
        this.tagsArr = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../style/table.scss";
.tagClass {
  cursor: pointer;
  margin-left: 3px;
}
</style>
