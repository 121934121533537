var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    [
      _vm.tagsArr.length == 0
        ? _c(
            "el-tag",
            {
              staticClass: "tagClass",
              staticStyle: { "margin-left": "20px" },
              attrs: { size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.addTag()
                },
              },
            },
            [_vm._v("添加标签")]
          )
        : _vm._e(),
      _vm._l(_vm.tagsArr.slice(0, 2), function (item, index) {
        return _c(
          "el-tag",
          {
            key: index,
            staticClass: "tagClass",
            attrs: { size: "mini", type: "warning" },
            on: {
              click: function ($event) {
                return _vm.addTag()
              },
            },
          },
          [_c("span", [_vm._v(_vm._s(item.title))])]
        )
      }),
      _vm.tagsArr.length > 2
        ? _c(
            "span",
            { staticStyle: { "margin-left": "5px" } },
            [
              _vm._v(" ... "),
              _c(
                "el-link",
                {
                  staticStyle: { "font-size": "12px", "margin-left": "15px" },
                  attrs: { type: "warning" },
                  on: {
                    click: function ($event) {
                      return _vm.addTag()
                    },
                  },
                },
                [_vm._v("更多")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.tagsDialog
        ? _c("subjectTags", {
            attrs: {
              tagsDialog: _vm.tagsDialog,
              handleType: "'编辑'",
              tagsTree: _vm.currTagsTree,
              currentTag: _vm.currChooseArr,
              title: "竞赛标签",
            },
            on: {
              "update:tagsDialog": function ($event) {
                _vm.tagsDialog = $event
              },
              "update:tags-dialog": function ($event) {
                _vm.tagsDialog = $event
              },
              getAllChooseTags: _vm.getAllChooseTags,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }