<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    @open="openDialog"
    top="7vh"
    append-to-body
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>开放题解视频</span>
    </div>

    <el-row class="row-bg" :gutter="24">
      <el-col :span="12">
        <el-input
          placeholder="请输入搜索内容"
          v-model="inputVal"
          class="input-with-select"
          @keyup.enter.native="search"
        >
          <el-select
            v-model="select"
            clearable
            slot="prepend"
            placeholder="请选择"
            style="width: 100px"
          >
            <el-option label="问题ID" value="1"></el-option>
            <el-option label="问题标题" value="2"></el-option>
          </el-select>
        </el-input>
      </el-col>

      <el-col :span="6">
        <el-button type="primary" size="small" @click="search" class="tb-button"
          >搜 索</el-button
        >
      </el-col>
    </el-row>

    <el-table
      :data="dataList"
      border
      fit
      ref="problemTable"
      highlight-current-row
      class="tb-list"
      border
      fit
      highlight-current-row
      row-key="problem_id"
      @selection-change="handleselectChange"
    >
      <el-table-column
        type="selection"
        width="70"
        align="center"
        :reserve-selection="true"
      ></el-table-column>
      <el-table-column label="题号" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        label="ID"
        align="center"
        prop="problem_id"
      ></el-table-column>
      <el-table-column
        label="标题"
        align="center"
        prop="title"
      ></el-table-column>
    </el-table>

    <el-dropdown trigger="click" class="tb-dropdown" style="margin-top: 20px">
      <el-button type="info" size="medium">
        批量操作
        <i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu style="padding: 10px" slot="dropdown">
        <el-dropdown-item @click.native="batchOpen('open')"
          ><i class="el-icon-circle-plus-outline"></i>批量开放
        </el-dropdown-item>
        <el-dropdown-item @click.native="batchOpen('close')"
          ><i class="el-icon-delete"></i>批量关闭</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
  </el-dialog>
</template>

<script>
import { getQuestionByLibraryId } from "@/api/research/contest";
import { updateProblemUser, enableQues } from "../../../api/research/quesBank";

export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    contestId: {
      default: "",
      type: String,
    },
    studentArray: {
      default: [],
      type: Array,
    },
  },

  data() {
    return {
      inputVal: "",
      select: "1",
      dataList: [],
      multipleSelection: [],
    };
  },
  methods: {
    batchOpen(flag) {
      if (this.multipleSelection.length === 0) {
        window.$msg("请先选择要开放题解的题目", 2);
        return false;
      }
      let thembArray = [];
      this.multipleSelection.forEach((item) => {
        this.studentArray.forEach((value) => {
          let object = {
            userId: value.userId,
            problemId: item.problem_id,
            contestId: parseInt(this.contestId),
            defunctVideo: flag === "open" ? "N" : "Y",
          };
          thembArray.push(object);
        });
      });
      this.updateProblemUser(thembArray, flag);
    },
    async updateProblemUser(thembArray, flag) {
      console.log("thembArray", thembArray);
      let response = null;
      response = await updateProblemUser(thembArray);
      console.log("response", response);
      if (response && response.state === "success") {
        window.$msg("操作成功");
        this.$emit("updateView");
        this.close();
      }
    },
    // 获取模板列表
    async getQuestionByLibraryId() {
      const response = await getQuestionByLibraryId({
        contest_id: this.contestId,
        problem_id: this.select === "1" ? this.inputVal : "",
        title: this.select === "2" ? this.inputVal : "",
      });
      this.dataList = response.body;
    },
    handleselectChange(val) {
      this.multipleSelection = val;
    },
    openDialog() {
      this.getQuestionByLibraryId();
      this.$nextTick(() => {
        this.$refs.problemTable.clearSelection();
      });
    },
    search() {
      this.getQuestionByLibraryId();
    },
    // 关闭
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
/deep/ .el-table td {
  padding: 10px 0 !important;
}
/deep/ .el-dialog {
  overflow: auto;
}
</style>
