<template>
  <!-- table -->
  <div class="tables">
    <div class="handle">
      <span v-show="showSearch">
        <el-input
          v-model="queryParam.searchName"
          placeholder="请输入用户姓名首字母/姓名/工号"
          clearable
          :size="size"
          class="w100"
          @keyup.enter.native="search"
        />
        <el-checkbox
          :size="size"
          @change="search"
          v-model="queryParam.postionStatus"
          style="margin-left: 20px"
          >含已离职</el-checkbox
        >
      </span>
      <!-- <right-toolbar
          class="toolbar-style"
          :show-search.sync="showSearch"
          @queryTable="getList"
        /> -->
      <el-button
        size="mini"
        style="margin-left: 8px"
        icon="el-icon-search"
        circle
        @click="search"
      ></el-button>
    </div>
    <!-- <div class="toolbar-style">
        <right-toolbar
          :show-search.sync="showSearch"
          @queryTable="getList"
        />
        </div> -->
    <el-table
      v-loading="loadingtable"
      ref="table"
      :data="dataList"
      fit
      :highlight-current-row="true"
      height="345"
      @select="handleSelectionChange"
      @select-all="selectAll"
      :size="size"
      :header-cell-style="{ background: '#F7F8FA' }"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column label="工号" align="left" prop="loginName" />
      <el-table-column label="姓名" align="left" prop="name" />
      <el-table-column label="员工状态" align="left">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.postionStatus === '在职'" type="success"
            >在职</el-tag
          >
          <el-tag v-else-if="scope.row.postionStatus === '离职'" type="warning"
            >离职</el-tag
          >
          <el-tag v-else type="info">未知</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="margin-top: 8px"
      small
      background
      :current-page="pagination.pageNum"
      :page-size="pagination.pageSize"
      :page-sizes="pagination.pageSizes"
      :total="pagination.total"
      :layout="pagination.layout"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>
<script>
import { getListEmployee } from "@/api/system/staff.js";

export default {
  name: "EmployeeList",
  props: {
    tableSelection: {
      type: Map,
      default: () => new Map(),
    },
    size: {
      type: String,
      default: "small",
    },
  },
  data() {
    return {
      loadingtable: false,
      showSearch: true,
      treeData: [],
      pagination: {
        pageNum: 1,
        pageSize: 20,
        pageSizes: [20, 50, 100, 200],
        layout: "total, prev, pager, next",
        total: 0,
      },
      deptId: "",
      position: "",
      queryParam: {
        // 查询参数
        searchName: "",
        postionStatus: false,
      },
      dataList: [],
      // 树结构
      defaultProps: {
        children: "children",
        label: "name",
      },
    };
  },
  inject: {
    // 注入父组件传的值，可以写一个默认的值，如果父组件没有返回数据的话
    multiple: {
      default: true,
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    search() {
      this.pagination.pageNum = 1;
      this.getList();
    },
    // 多选
    handleSelectionChange(rows, row) {
      console.log("this.multiple222222", this.multiple);
      if (this.multiple) {
        this.tableSelection.delete(row.userId);
        if (rows.length) {
          rows.forEach((i) => {
            this.tableSelection.set(i.userId, i);
          });
        } else {
          rows.forEach((i) => {
            this.tableSelection.delete(i.userId);
          });
        }
      } else {
        [...this.tableSelection].forEach((i) => {
          this.tableSelection.delete(i[1].userId);
        });
        if (rows.length) {
          rows.forEach((i) => {
            if (row.userId === i.userId) {
              this.tableSelection.set(i.userId, i);
            }
          });
        }
      }
      this.$emit("changeSelectionData");
    },
    // 全选
    selectAll(val) {
      if (this.multiple) {
        if (val.length) {
          this.dataList.forEach((i) => {
            this.tableSelection.set(i.userId, i);
          });
        } else {
          this.dataList.forEach((i) => {
            this.tableSelection.delete(i.userId);
          });
        }
        this.$emit("changeSelectionData");
      } else {
        this.dataList.forEach((i) => {
          if (!this.tableSelection.has(i.userId)) {
            this.tableSelection.delete(i.userId);
          }
        });
        this.$message.warning("不支持多选");
        this.$emit("changeSelectionData");
      }
    },
    // 分页
    handleCurrentChange(val) {
      this.pagination.pageNum = val;
      this.getList();
    },
    // 分页
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.getList();
    },
    // 获取数据
    // TODO
    async getList() {
      this.loadingtable = true;
      // http://124.128.53.85:893/web/#/4?page_id=517
      const res = await getListEmployee({
        pageNum: this.pagination.pageNum,
        pageSize: this.pagination.pageSize,
        deptId: this.deptId,
        position: this.position,
        postionStatus: this.queryParam.postionStatus ? "" : "在职",
        searchName: this.queryParam.searchName,
      });
      this.loadingtable = false;
      if (res.state === "success") {
        this.dataList = res.body.list;
        this.pagination.total = res.body.total;
      } else {
        this.$message.warning(res.errMsg);
        this.dataList = [];
        this.pagination.total = 0;
      }
      // 反选
      this.$nextTick(() => {
        this.reverseElectionClick();
      });
    },
    // 反选
    reverseElectionClick() {
      this.dataList.forEach((item, index) => {
        this.$refs.table.toggleRowSelection(
          this.dataList[index],
          this.tableSelection.has(item.userId)
        );
      });
    },
    // tree点击
    handleDeptNodeClick(node, data) {
      this.deptId = node.id;
      this.pagination.pageNum = 1;
      this.getList();
    },
    handlePostNodeClick(node, data) {
      this.position = node.id;
      this.pagination.pageNum = 1;
      this.getList();
    },
  },
};
</script>
<style scoped lang="scss">
.toolbar-style {
  display: inline-block;
  text-align: right;
  position: absolute;
  right: 0;
}
.oneline-style {
  display: inline-block;
  width: 100%;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tables {
  padding: 16px 0 0 16px;
  width: 459px;
  .handle {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    height: 32px;
    .w100 {
      width: 180px !important;
    }
  }
  ::v-deep .el-table__body-wrapper::-webkit-scrollbar {
    width: 3px;
    height: 5px;
  }
  ::v-deep .pagination {
    margin: 0 0 0 -36px;
    padding-top: 8px;
  }
}
</style>
