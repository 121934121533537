<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    append-to-body
    :close-on-click-modal="false"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>{{ dialogTitle }}</span>
    </div>
    <el-form ref="formData" :model="formData" :rules="rules" label-width="140px">
      <el-form-item label="抽奖名称：" prop="title">
        <el-input
          v-model.trim="formData.title"
          placeholder="请输入抽奖名称"
          clearable
          style="width: 220px"
        ></el-input>
      </el-form-item>
      <el-form-item label="活动时间：" prop="time">
        <el-date-picker
          v-model="formData.time"
          type="datetimerange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00']"
          @blur="forceUpdate()"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="基础中奖概率：" prop="probability">
        <el-input
          v-model.trim="formData.probability"
          placeholder="请输入中奖基础概率"
          clearable
          style="width: 180px"
        ></el-input>
        %
      </el-form-item>
      <!--      <el-form-item label="保底：" prop="isGuarantee">-->
      <!--        <el-switch-->
      <!--          v-model="formData.isGuarantee"-->
      <!--          active-text="是"-->
      <!--          inactive-text="否">-->
      <!--        </el-switch>-->
      <!--      </el-form-item>-->
      <el-form-item label="最多抽奖机会：" prop="obtainedChancesCount">
        <el-input
          v-model.trim="formData.obtainedChancesCount"
          placeholder="请输入抽奖最多抽奖机会"
          clearable
          style="width: 220px;margin-right: 10px"
        ></el-input>
        <span>
          次
           <el-tooltip class="item" effect="dark" content="针对每个用户，此次抽奖可获得的最多抽奖次数"
                       placement="right">
            <i class=" el-icon-question"></i>
           </el-tooltip>
        </span>
      </el-form-item>
      <el-form-item label="奖项可抽中次数：" prop="limit">
        <el-input
          v-model.trim="formData.limit"
          placeholder="请输入奖项可抽中次数"
          clearable
          style="width: 220px;margin-right: 10px"
        ></el-input>
        <span>
          次
           <el-tooltip class="item" effect="dark" content="针对每个用户，奖项可被抽中的次数，适用于每个奖项，如果在奖项中单独设置被抽中次数，则以奖项的设置为主"
                       placement="right">
          <i class=" el-icon-question"></i>
       </el-tooltip>
        </span>
      </el-form-item>
    </el-form>

    <el-row slot="footer">
      <el-button type="primary" @click="saveArticle()">确 定</el-button>
      <el-button @click="close" type="info">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import { lotteryInfo, addLottery, editLottery } from '@/api/three-planning'

export default {
  name: 'addLottory',
  props: {
    show: {
      default: false,
      type: Boolean
    },
    lotteryId: {
      type: String,
      default: '',
    }
  },
  // 监控表单变化，清除验证
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  },
  data () {
    return {
      formData: {
        title: '',
        time: null
      },
      dialogTitle: '新增抽奖',
      chooseDate: null,
      rules: {
        title: [{ required: true, message: '抽奖名称不能为空', trigger: 'blur' }],
        time: [{ required: true, message: '活动时间不能为空', trigger: 'blur' }],
        probability: [{ required: true, message: '基础概率不能为空', trigger: 'blur' }],
        obtainedChancesCount: [{ required: true, message: '最多抽奖机会不能为空', trigger: 'blur' }],
      }
    }
  },
  methods: {
    openDialog () {
      if (this.lotteryId) {
        this.dialogTitle = '编辑抽奖'
        this.getLotteryList()
      } else {
        this.formData = {
          title: '',
          time: null
        }
        this.dialogTitle = '新增抽奖'
      }
    },

    async getLotteryList () {
      const res = await lotteryInfo({
        lotteryId: this.lotteryId
      })
      this.formData = Object.assign({}, res.body)
      this.formData.time = [res.body.startTime, res.body.endTime]
      this.forceUpdate()
    },

    forceUpdate () {
      this.$forceUpdate()
    },

    close () {
      this.$emit('close')
    },

    saveArticle () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          if (this.lotteryId) {
            editLottery({
              id: this.lotteryId,
              title: this.formData.title,
              startTime: this.formData.time[0],
              endTime: this.formData.time[1],
              isGuarantee: false,
              limit: this.formData.limit,
              probability: this.formData.probability,
              obtainedChancesCount: this.formData.obtainedChancesCount,
            }).then(res => {
              if (res.state === 'success') {
                window.$msg('编辑成功')
                this.close()
                this.$emit('updateView')
              }
            })
          } else {
            addLottery({
              title: this.formData.title,
              startTime: this.formData.time[0],
              endTime: this.formData.time[1],
              isGuarantee: false,
              limit: this.formData.limit,
              probability: this.formData.probability,
              obtainedChancesCount: this.formData.obtainedChancesCount,
            }).then(res => {
              if (res.state === 'success') {
                window.$msg('新增成功')
                this.close()
                this.$emit('updateView', {
                  pageSize: 10,
                  pageNum: 1
                })
              }
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
</style>
