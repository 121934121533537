var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "append-to-body": "",
        "close-on-click-modal": false,
        top: "7vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [_c("i"), _c("span", [_vm._v(_vm._s(_vm.dialogTitle))])]
      ),
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "140px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "抽奖名称：", prop: "title" } },
            [
              _c("el-input", {
                staticStyle: { width: "220px" },
                attrs: { placeholder: "请输入抽奖名称", clearable: "" },
                model: {
                  value: _vm.formData.title,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.formData,
                      "title",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "formData.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "活动时间：", prop: "time" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetimerange",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  "default-time": ["00:00:00"],
                },
                on: {
                  blur: function ($event) {
                    return _vm.forceUpdate()
                  },
                },
                model: {
                  value: _vm.formData.time,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "time", $$v)
                  },
                  expression: "formData.time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "基础中奖概率：", prop: "probability" } },
            [
              _c("el-input", {
                staticStyle: { width: "180px" },
                attrs: { placeholder: "请输入中奖基础概率", clearable: "" },
                model: {
                  value: _vm.formData.probability,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.formData,
                      "probability",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "formData.probability",
                },
              }),
              _vm._v(" % "),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: { label: "最多抽奖机会：", prop: "obtainedChancesCount" },
            },
            [
              _c("el-input", {
                staticStyle: { width: "220px", "margin-right": "10px" },
                attrs: { placeholder: "请输入抽奖最多抽奖机会", clearable: "" },
                model: {
                  value: _vm.formData.obtainedChancesCount,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.formData,
                      "obtainedChancesCount",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "formData.obtainedChancesCount",
                },
              }),
              _c(
                "span",
                [
                  _vm._v(" 次 "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: "针对每个用户，此次抽奖可获得的最多抽奖次数",
                        placement: "right",
                      },
                    },
                    [_c("i", { staticClass: "el-icon-question" })]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "奖项可抽中次数：", prop: "limit" } },
            [
              _c("el-input", {
                staticStyle: { width: "220px", "margin-right": "10px" },
                attrs: { placeholder: "请输入奖项可抽中次数", clearable: "" },
                model: {
                  value: _vm.formData.limit,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.formData,
                      "limit",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "formData.limit",
                },
              }),
              _c(
                "span",
                [
                  _vm._v(" 次 "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content:
                          "针对每个用户，奖项可被抽中的次数，适用于每个奖项，如果在奖项中单独设置被抽中次数，则以奖项的设置为主",
                        placement: "right",
                      },
                    },
                    [_c("i", { staticClass: "el-icon-question" })]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.saveArticle()
                },
              },
            },
            [_vm._v("确 定")]
          ),
          _c(
            "el-button",
            { attrs: { type: "info" }, on: { click: _vm.close } },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }