<template>
  <el-row>
    <el-button style="margin-bottom: 15px;" @click="getRankingList" type="warning">刷新统计</el-button>
    <el-table :data="dataList" class="tb-list">
      <el-table-column label="排名" align="center" width="100">
        <template slot-scope="scope">
                                  <span style="display: flex;align-items: center;justify-content: center">
                                    <i v-if="scope.row.ranking === 1" class="medal">
                                      <img src="../../../assets/images/gold.png"/>
                                    </i>
                                    <i v-else-if="scope.row.ranking === 2" class="medal">
                                      <img src="../../../assets/images/silver.png" alt/>
                                    </i>
                                    <i v-else-if="scope.row.ranking === 3" class="medal">
                                      <img src="../../../assets/images/cuprum.png" alt/>
                                    </i>
                                    <span style="margin-left: 5px"> 第{{scope.row.ranking}}名</span>
                                  </span>
        </template>
      </el-table-column>
      <el-table-column label="姓名" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="解决" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.compleNum }}</span>
        </template>
      </el-table-column>
      <el-table-column label="罚时" align="center" v-if="editData.raceRule === 'ACM'">
        <template slot-scope="scope">
          <span>{{ formatDuring(scope.row.total_cost) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="总分" align="center" v-else>
        <template slot-scope="scope">
          <span>{{ scope.row.totalOIScore }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="item+''"
        v-for="(item, index) of problems"
        :key="index"
        align="center"
        v-if="editData.raceRule === 'ACM'"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            :content="problems[index].problemName"
            placement="top"
          >
            <span>{{index + 1}}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
                                    <span
                                      :style="'color'+':'+getStateColor(scope.row.everyProblem[index].rightFlag)"
                                      v-show="scope.row.everyProblem[index].errroNum != 0 && scope.row.everyProblem[index].rightFlag == 0"
                                    >错{{scope.row.everyProblem[index].errroNum}}次</span>
          <span
            :style="'color'+':'+getStateColor(scope.row.everyProblem[index].rightFlag)"
            v-show="scope.row.everyProblem[index].errroNum != 0 && scope.row.everyProblem[index].rightFlag == 1"
          >{{formatDuring(scope.row.everyProblem[index].time_cost)}}</span><span
          v-show="scope.row.everyProblem[index].errroNum != 0 && scope.row.everyProblem[index].rightFlag == 1"
          style="color: #CE433F;">(错{{scope.row.everyProblem[index].errroNum}}次)</span>
          <span
            :style="'color'+':'+getStateColor(scope.row.everyProblem[index].rightFlag)"
            v-show="scope.row.everyProblem[index].errroNum == 0 && scope.row.everyProblem[index].rightFlag == 1"
          >{{formatDuring(scope.row.everyProblem[index].time_cost)}}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="item+''"
        v-for="(item, index) of problems"
        :key="index"
        align="center"
        v-if="editData.raceRule !== 'ACM'"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            :content="problems[index].problemName"
            placement="top"
          >
            <span>{{index + 1}}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <span>{{scope.row.everyProblem[index].IOScore}}</span>
        </template>
      </el-table-column>
    </el-table>
  </el-row>
</template>

<script>
  import { getRankingList } from '@/api/research/contest'

  export default {
    props: {
      editData: {
        default: () => {
          return {};
        },
        type: Object
      }
    },
    data() {
      return {
        dataList: [],
        problems: []
      };
    },
    created() {
      this.getRankingList();
    },
    methods: {
      formatDuring(mss) {
        let hours = Math.floor((mss / 60 / 60));
        let minutes = Math.floor((mss % (60 * 60)) / 60);
        let seconds = Math.round(mss % 60);
        if (hours != 0) {
          return (
            this.Appendzero(hours) +
            this.Appendzero(minutes) +
            this.SecondAppendzero(seconds)
          );
        } else {
          if (minutes == 0) {
            return (
              this.Appendzero(0) +
              this.Appendzero(0) +
              this.SecondAppendzero(seconds)
            );
          } else if (hours == 0) {
            return (
              this.Appendzero(0) +
              this.Appendzero(minutes) +
              this.SecondAppendzero(seconds)
            );
          }
        }
      },
      Appendzero(obj) {
        if (obj < 10) return "0" + "" + obj + ":";
        else return obj + ":";
      },
      SecondAppendzero(obj) {
        if (obj < 10) return "0" + "" + obj;
        else return obj;
      },
      // 获取学生统计
      async getRankingList() {
        const res = await getRankingList({
          contestId: this.editData.contest_id,
        })
        this.dataList = res.body.tbody;
        if(this.editData.raceRule !== 'ACM') {
          let prescore = 0;//预定义分数
          let ranking = 0;//排名
          this.dataList.forEach((item,index)=>{
            if(item.totalOIScore == prescore){
              item.ranking = ranking;
            }else{
              ranking++;
              prescore = item.totalOIScore;
              item.ranking = ranking;
            }
          })
        }else {
          let prescore = 0;//预定义解决数
          let total_cost = 0//预定义罚时
          let ranking = 0;//排名
          this.dataList.forEach((item,index)=>{
            if(item.totalOIScore == prescore && item.total_cost == total_cost ){
              item.ranking = ranking;
            }else{
              ranking++;
              prescore = item.totalOIScore;
              total_cost = item.total_cost
              item.ranking = ranking;
            }
          })
        }
        this.problems = res.body.problems;
      },

      getStateColor(state) {
        switch (state) {
          case 0:
            return "#CE433F";
            break;
          case 1:
            return "#0CA51A";
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "../../../style/table.scss";
</style>
