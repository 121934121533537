<template>
  <el-dialog
    v-if="tagsDialog"
    :title="title"
    :visible.sync="tagsDialog"
    append-to-body
    width="800px"
    top="7vh"
    :before-close="
      () => {
        $emit('update:tagsDialog', false);
      }
    "
  >
    <v-classify
      ref="vClassify"
      style="height: 500px"
      :currentTag="currentTag"
      :pageType="handleType"
      :classifyList="tagsTree"
      :getAllChoose="true"
      @getTagValue="getTagValue"
      @markTagForCustomer="markTagForCustomer"
      @getAllChooseTags="getAllChooseTags"
      @close="close"
    ></v-classify>
  </el-dialog>
</template>
<script>
export default {
  props: {
    tagsDialog: {
      type: Boolean,
      default: false,
    },
    // 新增 1/ 编辑 2
    handleType: {
      default: "1",
      type: String,
    },
    // 当前树结构
    tagsTree: {
      type: Array,
      default: () => [],
    },
    // 当前选择的标签
    currentTag: {
      type: Array,
      default: () => [],
    },
    title: {
      default: "问题标签",
      type: String,
    },
  },
  data() {
    return {};
  },
  mounted() {
    // console.log("handleType", this.handleType);
    // console.log("currentTag", this.currentTag);
    // console.log("tagsTree", this.tagsTree);
  },
  methods: {
    getAllChooseTags(data) {
      // console.log("chooseTagArr", data);
      this.$emit("getAllChooseTags", data);
      this.close();
    },
    /** 增加标签 */
    getTagValue(data) {
      console.log("增加标签", data);
      // console.log("currentTag", this.currentTag);
      // return;
      // let currentData = [];

      this.$emit("getTagValue", data);
      this.close();
    },
    /** 提交标签 */
    markTagForCustomer(data) {
      console.log("修改", data);
    },
    // 关闭
    close() {
      this.$emit("update:tagsDialog", false);
    },
  },
};
</script>
<style scoped lang="scss">
/deep/ .el-tag--dark.el-tag--warning {
  background-color: #e6a23c !important;
}
</style>
