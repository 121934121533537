import $axios from '@/utils/request'

// 获取物品列表
export function getGoodsList (data) {
  const url = '/goods/list'
  return $axios.fPost(url, data)
}

// 添加物品
export function addGoods (data) {
  const url = '/goods/add'
  return $axios.fPost(url, data)
}

// 编辑物品
export function editGoods (data) {
  const url = '/goods/edit'
  return $axios.fPost(url, data)
}

// 获取物品流水
export function getListTurnover (data) {
  const url = '/goods/listTurnover'
  return $axios.fPost(url, data)
}

// 修改物品库存
export function goodsChangeAmount (data) {
  const url = '/goods/changeAmount'
  return $axios.fPost(url, data)
}

// 获取抽奖活动列表
export function getLotteryList (data) {
  const url = '/lottery/list'
  return $axios.fPost(url, data)
}

// 获取奖项列表
export function getLotteryListItem (data) {
  const url = '/lottery/listItem'
  return $axios.fGet(url, data)
}

// 创建奖项
export function addLotteryItem (data) {
  const url = '/lottery/addItem'
  return $axios.fPost(url, data)
}

// 编辑奖项
export function editLotteryItem (data) {
  const url = '/lottery/editItem'
  return $axios.fPost(url, data)
}

// 删除奖项
export function delLotteryItem (data) {
  const url = '/lottery/delItem'
  return $axios.fGet(url, data)
}

// 编辑抽奖活动
export function editLottery (data) {
  const url = '/lottery/edit'
  return $axios.fPost(url, data)
}

// 获取抽奖结果列表
export function getListLotteryRes (data) {
  const url = '/lottery/listLotteryRes'
  return $axios.fPost(url, data)
}

// 删除物品
export function delGoods (data) {
  const url = '/goods/del'
  return $axios.fGet(url, data)
}
