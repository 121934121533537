var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "tree-select", style: { padding: "16px" } },
    [
      _c(
        "el-row",
        { staticStyle: { "margin-bottom": "16px" } },
        [
          _vm.showInput
            ? _c("el-input", {
                attrs: {
                  size: _vm.size,
                  placeholder: "输入关键字进行过滤",
                  clearable: "",
                },
                model: {
                  value: _vm.filterText,
                  callback: function ($$v) {
                    _vm.filterText = $$v
                  },
                  expression: "filterText",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-tree",
        {
          ref: "tree",
          staticClass: "filter-tree",
          style: { width: _vm.treeWidth },
          attrs: {
            data: _vm.data,
            "empty-text": _vm.emptyText,
            "render-after-expand": _vm.renderAfterExpand,
            props: _vm.defaultProps,
            "highlight-current": _vm.highlightCurrent,
            "default-expand-all": _vm.defaultExpandAll,
            "expand-on-click-node": _vm.expandOnClickNode,
            "check-on-click-node": _vm.checkOnClickNode,
            "auto-expand-parent": _vm.autoExpandParent,
            "default-expanded-keys": _vm.defaultExpandedKeys,
            "default-checked-keys": _vm.defaultCheckedKeys,
            "show-checkbox": _vm.showCheckbox,
            "check-strictly": _vm.checkStrictly,
            "node-key": _vm.nodeKey,
            "current-node-key": _vm.currentNodeKey,
            accordion: _vm.accordion,
            indent: _vm.indent,
            "icon-class": _vm.iconClass,
            lazy: _vm.lazy,
            size: _vm.size,
            "filter-node-method": _vm.filterNode,
            load: _vm.load,
            "allow-drag": _vm.allowDrag,
            "allow-drop": _vm.allowDrop,
            "render-content": _vm.renderContent,
          },
          on: {
            "node-click": _vm.nodeClick,
            "node-contextmenu": (event, obj, node, self) =>
              _vm.$emit("nodeContextmenu", event, obj, node, self),
            "check-change": (obj, node, flag) =>
              _vm.$emit("checkChange", obj, node, flag),
            check: _vm.checkHandler,
            "current-change": (obj, node) =>
              _vm.$emit("currentChange", obj, node),
            "node-expand": (obj, node, self) =>
              _vm.$emit("nodeExpand", obj, node, self),
            "node-collapse": (obj, node, self) =>
              _vm.$emit("nodeCollapse", obj, node, self),
            "node-drag-start": (node, event) =>
              _vm.$emit("nodeDragStart", node, event),
            "node-drag-enter": (node1, node2, event) =>
              _vm.$emit("nodeDragEnter", node1, node2, event),
            "node-drag-leave": (node1, node2, event) =>
              _vm.$emit("nodeDragLeave", node1, node2, event),
            "node-drag-over": (node1, node2, event) =>
              _vm.$emit("nodeDragOver", node1, node2, event),
            "node-drag-end": (node1, node2, position, event) =>
              _vm.$emit("nodeDragEnd", node1, node2, position, event),
            "node-drop": (node1, node2, position, event) =>
              _vm.$emit("nodeDrop", node1, node2, position, event),
          },
        },
        [_vm._t("content")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }