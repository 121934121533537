var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c(
        "el-table",
        {
          ref: "dragTable",
          staticClass: "tb-list",
          staticStyle: { width: "40%" },
          attrs: {
            data: _vm.problemList,
            border: "",
            fit: "",
            "highlight-current-row": "",
            border: "",
            fit: "",
            "highlight-current-row": "",
            "row-key": "problem_id",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "题号", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.$index + 1) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "ID", align: "center", prop: "problem_id" },
          }),
          _c("el-table-column", {
            attrs: { label: "标题", align: "center", prop: "title" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }