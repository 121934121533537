<template>
  <div>
    <el-input
      v-model="searchText"
      placeholder="请输入完整的姓名、学号或手机号"
      style="width: 40%; margin-top: 10px;margin-left: 10px"
      clearable
      @close="clearSearch"
      size="small"
    />
    <el-button
      size="mini"
      type="primary"
      style="margin-top: 10px;margin-left: 10px"
      @click="searchStudent"
    >
      搜索
    </el-button>
    <el-table
      v-if="dataList.length"
      fit
      ref="table"
      :data="dataList"
      :highlight-current-row="true"
      @select="handleSelectionChange"
      @select-all="selectAll"
      @row-click="handleSelect">
      <el-table-column type="selection" width="55" align="center"/>
      <el-table-column
        align="center"
        label="姓名"
        prop="name"
      />
      <el-table-column
        align="center"
        label="学号"
        prop="loginName"
      />
      <el-table-column
        align="center"
        label="手机号"
        prop="tel"
      />
    </el-table>
  </div>

</template>
<script>
import { getListByUserSearch } from '@/api/senate/class'

export default {
  props: {
    tableSelection: {
      type: Map,
      default: () => new Map(),
    },
    batCurrentState: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      loading: false,
      searchText: '',
      dataList: [],
    }
  },
  inject: {
    multiple: {
      default: true
    }
  },
  methods: {
    searchStudent () {
      getListByUserSearch({
        userSearch: this.searchText
      }).then(res => {
        if (res.state === 'success') {
          this.dataList = res.body
        }
      })
    },
    clearSearch () {
      this.searchText = ''
      this.dataList = []
    },
    handleSelect (row) {
      const { userId } = row
      const rows = this.tableSelection.get(userId) ? [] : [row]
      this.handleSelectionChange(rows, row)
    },
    handleSelectionChange (rows, row) {
      if (this.multiple) {
        this.tableSelection.delete(row.userId)
        if (rows.length) {
          rows.forEach((i) => {
            this.tableSelection.set(i.userId, i)
          })
        } else {
          rows.forEach((i) => {
            this.tableSelection.delete(i.userId)
          })
        }
      } else {
        [...this.tableSelection].forEach((i) => {
          this.tableSelection.delete(i.userId)
        })
        if (rows.length) {
          rows.forEach((i) => {
            if (row.userId === i.userId) {
              this.tableSelection.set(i.userId, i)
            }
          })
        }
      }
      this.$emit('changeSelectionData')
    },
    selectAll (rows) {
      if (rows.length) {
        this.dataList.forEach((i) => {
          this.tableSelection.set(i.userId, i)
        })
      } else {
        this.dataList.forEach((i) => {
          this.tableSelection.delete(i.userId)
        })
      }
      this.$emit('changeSelectionData')
    },
    reverseElectionClick () {
      this.dataList.forEach((item, index) => {
        this.$refs.table.toggleRowSelection(
          this.dataList[index],
          this.tableSelection.has(item.userId)
        )
      })
    },
  }
}
</script>
<style>
</style>
