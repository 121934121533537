import $axios from '@/utils/request'

// 新增课程类别
export function addCatalogLearn (data) {
  const url = '/courseCatalogLearn/add'
  return $axios.fPost(url, data)
}

// 课程类别排序
export function moveToCatalog (data) {
  const url = '/courseCatalogLearn/moveTo'
  return $axios.fPost(url, data)
}
// 编辑课程类别
export function editCatalogLearn (data) {
  const url = '/courseCatalogLearn/edit'
  return $axios.fPost(url, data)
}

// 删除课程类别
export function delCatalog (data) {
  const url = '/courseCatalogLearn/del'
  return $axios.fPost(url, data)
}

// 获取课程类别
export function courseCatalog (data) {
  const url = '/courseCatalogLearn/list'
  return $axios.fPost(url, data)
}



// 获取课程类别
export function listCampusCourse (data) {
  const url = '/courseCatalogLearn/listCampusCourse'
  return $axios.fPost(url, data)
}
