<template>
  <el-row class="row-bg" :gutter="24">
    <el-form
      class="formParent"
      ref="formData"
      :model="formData"
      :rules="rules"
      label-width="140px"
      label-position="right"
    >
      <el-form-item label="题集标题：" prop="title">
        <el-input
          v-model="formData.title"
          placeholder="请输入题集名称"
          maxlength="20"
          style="width: 400px"
        ></el-input>
      </el-form-item>
      <el-form-item label="题集类型：" prop="raceType">
        <el-radio-group
          disabled
          @input="handleChangeType"
          :value="formData.raceType"
        >
          <el-radio
            v-for="item in raceTypeList"
            :key="item.value"
            :label="item.value"
            >{{ item.label }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="赛制：" prop="raceRule" v-if="isNormal">
        <el-radio-group
          v-model="formData.raceRule"
          @change="$forceUpdate()"
          disabled
        >
          <el-radio
            v-for="item in raceRuleList"
            :key="item.value"
            :label="item.value"
            >{{ item.label }}
          </el-radio>
        </el-radio-group>
        <el-tooltip
          class="item"
          effect="dark"
          content="CA表示刷题类型的题集，题集模式创建完成不可更改"
          placement="right"
        >
          <i
            class="el-icon-question"
            style="font-size: 18px; margin-left: 20px"
          ></i>
        </el-tooltip>
        <el-checkbox
          v-if="['OI', 'IOI'].includes(formData.raceRule)"
          style="margin-left: 10px"
          v-model="formData.timeCostRank"
          true-label="Y"
          false-label="N"
        >
          统计排名时计算用时
        </el-checkbox>
      </el-form-item>
      <el-form-item label="报名方式：" prop="isPrivate" v-if="isNormal">
        <el-radio-group
          v-model="formData.isPrivate"
          @input="handleChangePrivate"
        >
          <el-radio :label="0">公开赛</el-radio>
          <el-radio :label="1">私有赛</el-radio>
        </el-radio-group>
        <el-tooltip class="item" effect="dark" placement="right">
          <span slot="content">
            <p>公开赛，允许学习平台用户查看和报名</p>
            <p>私有赛，用户不能主动报名，只能有题集管理员手动添加参赛成员</p>
          </span>
          <i
            class="el-icon-question"
            style="font-size: 18px; margin-left: 20px"
          ></i>
        </el-tooltip>
      </el-form-item>
      <el-form-item
        v-if="isNormal && formData.isPrivate === 0"
        label="是否审核报名："
        prop="isVerify"
      >
        <el-radio-group v-model="formData.isVerify">
          <el-radio label="Y">是</el-radio>
          <el-radio label="N">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="isNormal && formData.isPrivate !== 1"
        label="报名时间："
        prop="applyTime"
      >
        <el-date-picker
          @change="$forceUpdate()"
          @blur="$forceUpdate()"
          v-model="formData.applyTime"
          type="datetimerange"
          start-placeholder="活动报名开始时间"
          end-placeholder="活动报名结束时间"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00']"
        ></el-date-picker>
        <!--        <el-checkbox-->
        <!--            v-if="formData.raceType === '刷题题集'"-->
        <!--            style="margin-left: 15px"-->
        <!--            v-model="applychecked"-->
        <!--        >不限时-->
        <!--        </el-checkbox>-->
      </el-form-item>
      <el-form-item
        label="题集时间："
        prop="contestTime"
        v-if="['普通竞赛', '极速竞赛'].includes(formData.raceType)"
      >
        <el-date-picker
          @change="$forceUpdate()"
          @blur="$forceUpdate()"
          v-model="formData.contestTime"
          type="datetimerange"
          start-placeholder="题集开始时间"
          end-placeholder="题集结束时间"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00']"
        ></el-date-picker>
        <!--        <el-checkbox-->
        <!--            v-if="formData.raceType === 4"-->
        <!--            style="margin-left: 15px"-->
        <!--            v-model="checked"-->
        <!--        >不限时-->
        <!--        </el-checkbox>-->
      </el-form-item>
      <el-form-item label="可用语言：" prop="langmask">
        <el-select
          v-model="formData.langmask"
          @change="$forceUpdate()"
          multiple
          filterable
          placeholder="请选择编程语言"
        >
          <el-option
            v-for="item in languageList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="允许复制代码：" prop="codeOpen">
        <el-radio-group v-model="formData.codeOpen">
          <el-radio label="y">是</el-radio>
          <el-radio label="n">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="开放抽奖：" v-if="isNormal" prop="lotteryOpen">
        <el-radio-group v-model="formData.lotteryOpen">
          <el-radio label="Y">是</el-radio>
          <el-radio label="N">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="开放题解：" prop="solutionVideoOpen">
        <el-radio-group v-model="formData.solutionVideoOpen">
          <el-radio label="Y">是</el-radio>
          <el-radio label="N">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="答题时长：" v-if="isSpeed" prop="duration">
        <el-input-number
          v-model="formData.duration"
          placeholder="请输入答题时长"
          :min="1"
        ></el-input-number>
        <span style="margin-left: 8px">min</span>
      </el-form-item>
      <el-form-item label="开放排行榜：" v-if="isSpeed" prop="rankAnnounce">
        <el-radio-group v-model="formData.rankAnnounce">
          <el-radio label="Y">是</el-radio>
          <el-radio label="N">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <!-- <el-form-item
        label="课程系列："
        prop="courseCatalogLearnId"
        v-if="!isNormal && !isSpeed"
      >
        <treeselect
          placeholder="请选择班级课程"
          style="width: 230px"
          :flat="true"
          v-model="formData.catalogLearnId"
          :disable-branch-nodes="true"
          :options="listLevel"
          :searchable="true"
          :show-count="true"
          :normalizer="normalizer"
        />
      </el-form-item> -->

      <!-- <el-form-item label="竞赛标签：">
        <el-tag
          size="mini"
          class="tagClass"
          style="margin-left: 20px"
          v-if="tagsArr.length == 0"
          @click="addTag()"
          >添加标签
        </el-tag>
        <el-tag
          size="mini"
          v-for="(item, index) in tagsArr.slice(0, 2)"
          :key="index"
          type="warning"
          class="tagClass"
          @click="addTag()"
        >
          <span>{{ item.title }}</span>
        </el-tag>
        <span v-if="tagsArr.length > 2" style="margin-left: 5px">
          ...
          <el-link
            type="warning"
            style="font-size: 12px; margin-left: 15px"
            @click="addTag()"
            >更多</el-link
          >
        </span>
      </el-form-item> -->
    </el-form>
    <el-row style="display: flex; justify-content: center">
      <el-button
        :disabled="editData.role !== '管理者' && editData.role !== '创建者'"
        type="primary"
        size="small"
        @click="updateQuestionLibrary"
        class="tb-button"
        >保存
      </el-button>
    </el-row>
    <!-- <subjectTags
      :tagsDialog.sync="tagsDialog"
      v-if="tagsDialog"
      handleType="'编辑'"
      :tagsTree="currTagsTree"
      :currentTag="currChooseArr"
      title="竞赛标签"
      @getAllChooseTags="getAllChooseTags"
    /> -->
  </el-row>
</template>

<script>
import { getContextInfo, updateQuestionLibrary } from "@/api/research/contest";
import { langmask } from "@/utils/langmask";
import getCourseListLearn from "@/mixins/getCourseLearnType";
import getTag from "@/mixins/getTag";
import subjectTags from "@/components/research/question-manage/subjectTags";
import { markTagForRealtion } from "@/api/frontbusiness";
import { getTagsByRelationId } from "@/api/learnmanage/examine";
import { getListLevel } from "@/api/operation";

const RACE_TYPE_LIST = [
  // { label: "普通竞赛", value: "普通竞赛" },
  { label: "刷题题集", value: "刷题题集" },
  // { label: "极速竞赛", value: "极速竞赛" },
];

const RACE_RULE_LIST = [
  { label: "ACM", value: "ACM" },
  { label: "OI", value: "OI" },
  { label: "IOI", value: "IOI" },
];
export default {
  name: "contest-info",
  mixins: [],
  components: { subjectTags },
  props: {
    editData: {
      default: () => {
        return {};
      },
      type: Object,
    },
  },
  inject: ["getContextInfoDetail"],
  data() {
    let RichTimeVal = (rule, value, callback) => {
      if (!this.formData.applyTime || this.formData.applyTime.length === 0) {
        callback(new Error("请选择报名时间"));
      } else {
        callback();
      }
    };
    let contestTimeVal = (rule, value, callback) => {
      if (
        !this.formData.contestTime ||
        this.formData.contestTime.length === 0
      ) {
        callback(new Error("请选择竞赛时间"));
      } else {
        callback();
      }
    };
    return {
      bindFlag: false,
      tagsArr: [],
      currChooseArr: [],
      currTagsTree: [],
      tagsDialog: false,
      langmaskValue: "",
      rankAnnounce: "Y",
      matchDuration: 1,
      enrollTime: false,
      applychecked: false,
      checked: false,
      dataList: [],
      normalizer(node) {
        return {
          ...node,
          label: node.title,
          value: node.id,
        };
      },
      listLevel: [], // 课程系列
      raceTypeList: RACE_TYPE_LIST,
      raceRuleList: RACE_RULE_LIST,
      languageList: langmask,
      formData: {
        // 公共form
        contestTime: [],
        raceType: "",
        timeCostRank: "N",
        title: "", //
        description: "", // 竞赛介绍
        langmask: ["1"], // 可用语言
        codeOpen: "y", // 复制代码
        solutionVideoOpen: "N", // 开放题解
        lotteryOpen: "N", // 开放抽奖
        //
        raceRule: "", // 赛制
        isPrivate: null, // 报名方式
        isVerify: "", // 是否审核报名
        applyTime: [], // 报名时间
        //
        duration: null, // 答题时长
        rankAnnounce: "", // 开放排行榜
        catalogLearnId: "", // 课程系列
      },
      rules: {
        timeCostRank: "N",
        codeOpen: {
          required: true,
          message: "是否允许复制代码选择不能为空",
          trigger: "change",
        }, // 复制代码
        solutionVideoOpen: {
          required: true,
          message: "开放题解选择不能为空",
          trigger: "change",
        }, // 开放题解
        lotteryOpen: {
          required: true,
          message: "开放抽奖不能为空",
          trigger: "change",
        }, // 开放抽奖
        duration: {
          required: true,
          message: "答题时长不能为空",
          trigger: "blur",
        }, // 答题时长
        rankAnnounce: {
          required: true,
          message: "开放排行榜选择不能为空",
          trigger: "change",
        }, // 开放排行榜
        isVerify: {
          required: true,
          message: "是否审核报名选择不能为空",
          trigger: "change",
        }, // 是否审核报名
        title: [
          { required: true, message: "竞赛标题不能为空", trigger: "blur" },
          { max: 20, message: "字数限制在20个字内", trigger: "blur" },
        ],

        raceRule: {
          required: true,
          message: "赛制不能为空",
          trigger: "change",
        },
        catalogLearnId: {
          required: true,
          message: "竞赛类别不能为空",
          trigger: "blur",
        },
        isPrivate: {
          required: true,
          message: "报名方式不能为空",
          trigger: "blur",
        },
        applyTime: { required: true, validator: RichTimeVal, trigger: "blur" },
        raceType: {
          required: true,
          message: "请选择竞赛类型",
          trigger: "blur",
        },
        contestTime: {
          required: true,
          validator: contestTimeVal,
          trigger: "blur",
        },
        langmask: {
          required: true,
          message: "请选择编程语言",
          trigger: "blur",
        },
      },
    };
  },
  computed: {
    isNormal() {
      return ["普通竞赛"].includes(this.formData.raceType);
    },
    isSpeed() {
      return ["极速竞赛"].includes(this.formData.raceType);
    },
    isEdit() {
      return this.isEditDisabled;
    },
  },
  created() {
    this.getContextInfo();
    // this.getTagsByRelationId();
    // this.getListLevel();
  },
  methods: {
    handleChangePrivate(value) {
      if (value === 1) {
        this.formData.applyTime = [];
      }
    },
    async getListLevel() {
      let { state, body } = await getListLevel();
      if (state === "success") {
        this.listLevel = body;
      }
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs["formData"].validate((valid) => {
          if (valid) {
            console.log(this.formData);
            const dateCalc =
              this.formData.applyTime.length > 1 &&
              this.formData.contestTime.length > 1;
            if (dateCalc) {
              const date =
                new Date(this.formData.applyTime[1]).getTime() >
                new Date(this.formData.contestTime[1]).getTime();
              if (date && this.formData.isPrivate === 1) {
                this.$message.warning("报名结束时间不能大于竞赛结束时间!");
                // eslint-disable-next-line prefer-promise-reject-errors
                reject([false, {}]);
              } else {
                resolve([valid, this.formData]);
              }
            } else {
              resolve([valid, this.formData]);
            }
          } else {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject([false, {}]);
          }
        });
      });
    },
    handleChangeType(label) {
      if (this.formData.raceType) {
        this.$confirm("切换竞赛类型会清空当前配置, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.resetForm();
            this.formData.raceType = label;
            this.$emit("changeType", label);
          })
          .catch(() => {});
      } else {
        this.formData.raceType = label;
      }
    },
    // 充值
    resetForm() {
      this.$refs["formData"].clearValidate();

      this.formData = {
        contestTime: [],
        raceType: "",
        timeCostRank: "N",
        description: "", // 竞赛介绍
        langmask: ["1"], // 可用语言
        codeOpen: "y", // 复制代码
        solutionVideoOpen: "N", // 开放题解
        lotteryOpen: "N", // 开放抽奖
        //
        type: "", // 赛制
        isPrivate: null, // 报名方式
        isVerify: "", // 是否审核报名
        applyTime: "", // 报名时间
        //
        duration: null, // 答题时长
        rankAnnounce: "", // 开放排行榜
        title: this.formData.title,
        catalogLearnId: "",
      };
    },
    // 获取标签
    async getTagsByRelationId() {
      let { body } = await getTagsByRelationId({
        relationId: this.editData.contest_id,
      });
      this.tagsArr = body;
    },
    // 添加标签（打开弹窗）
    async addTag(type) {
      this.getContestTagGroupList().then(() => {
        this.currChooseArr = JSON.parse(JSON.stringify(this.tagsArr));
        this.currTagsTree = this.tagTreeData;
        console.log("选择之前this.currChooseArr", this.currChooseArr);
        console.log("选择之前this.currTagsTree ", this.currTagsTree);
        // 编辑
        for (let i = 0; i < this.currTagsTree.length; i++) {
          for (let k = 0; k < this.currTagsTree[i].children.length; k++) {
            this.currTagsTree[i].children[k].state = false;
          }
        }
        for (let i = 0; i < this.currChooseArr.length; i++) {
          for (let k = 0; k < this.currTagsTree.length; k++) {
            if (this.currTagsTree[k].children) {
              for (let q = 0; q < this.currTagsTree[k].children.length; q++) {
                if (
                  this.currTagsTree[k].children[q].id ==
                  this.currChooseArr[i].id
                ) {
                  this.currTagsTree[k].children[q].state = true;
                }
              }
            }
          }
        }
        console.log("选择之后this.currChooseArr", this.currChooseArr);
        console.log("选择之后this.currTagsTree ", this.currTagsTree);
        this.tagsDialog = true;
      });
    },
    // 确定添加标签（新增）
    async getAllChooseTags(data) {
      console.log("data", data);
      if (data.length) {
        this.tagsArr = [];
        let { state } = await markTagForRealtion({
          tagIds: data.map((item) => {
            return item.id;
          }),
          type: "竞赛",
          relationId: this.editData.contest_id,
        });
        if (state == "success") {
          this.getTagsByRelationId();
        }
      } else {
        this.tagsArr = [];
      }
    },
    handlechecked() {
      if (!this.applychecked) {
        this.formData.applyTime = [];
      }
    },
    handleContestchecked() {
      if (!this.checked) {
        this.formData.contestTime = [];
      }
    },

    radioChange(value) {
      if (this.formData.opentype === 1) {
        this.enrollTime = false;
        this.formData.isVerify = "N";
        this.formData.applyTime = [
          "0001-01-01 00:00:00",
          "9999-12-31 23:59:59",
        ];
      } else {
        this.enrollTime = true;
        this.formData.isVerify = "Y";
        this.formData.applyTime = [];
      }
    },

    async updateQuestionLibrary() {
      const [valid, data] = await this.validate();
      if (valid) {
        const params = {
          ...data,
          startTime: data.contestTime[0],
          endTime: data.contestTime[1],
          startEnrollTime: data.applyTime[0],
          endEnrollTime: data.applyTime[1],
          lotteryOpen: data.lotteryOpen,
          langmask: data.langmask.join(","),
        };

        if (params.raceType !== "极速竞赛") {
          params.duration = null;
        }
        if (params.raceType !== "普通竞赛") {
          params.isPrivate = null;
        }

        const response = await updateQuestionLibrary(params);
        if (response && response.state === "success") {
          window.$msg("编辑成功");
          this.getContextInfoDetail();
        }
      }
    },
    // 查看竞赛详情
    async getContextInfo() {
      const response = await getContextInfo({
        contest_id: this.editData.contest_id,
        defunct: "",
      });

      if (response && response.state === "success") {
        const data = response.body;
        this.formData = { ...this.formData, ...data };
        this.formData.langmask = data.langmask.split(",");

        this.formData.applyTime = [];
        this.formData.contestTime = [];

        if (data.endTime === "9999-12-31 23:59:59.0") {
          this.formData.contestTime = [];
        } else {
          this.formData.contestTime.push(data.startTime);
          this.formData.contestTime.push(data.endTime);
        }

        if (data.endEnrollTime === "9999-12-31 23:59:59.0") {
          this.formData.applyTime = [];
        } else {
          this.formData.applyTime.push(data.startEnrollTime);
          this.formData.applyTime.push(data.endEnrollTime);
        }
      }
    },
  },
};
</script>

<style scoped>
.row-bg {
  background: #fff;
  margin-bottom: 15px;
  padding: 15px;
}
</style>
