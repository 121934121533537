import $axios from '@/utils/request'

// // 变更角色
// export function updateRole (data) {
//   const url = '/course/dararole/updateRole'
//   return $axios.fPost(url, data)
// }

// // 根据课程获取用户角色详情
// export function getUserCourseRoleInfo (data) {
//   const url = '/course/dararole/getUserCourseRoleInfo'
//   return $axios.fGet(url, data)
// }


// 发送微信模板消息统计
export function messageRecondCount (data) {
  const url = '/message/wx/public/count'
  return $axios.fPost(url, data)
}
// 发送微信模板消息
export function messageSendWx (data) {
  const url = '/message/wx/public/send'
  return $axios.fPost(url, data)
}
// 微信的模板消息
export function messageSendTemplate (data) {
  const url = '/message/template/wx/list'
  return $axios.fPost(url, data)
}
// 问卷中微信的记录
export function messageSendHistory (data) {
  const url = '/message/user/wx/list'
  return $axios.fPost(url, data)
}
// 新增问卷
export function dwexamAdd (data) {
  const url = '/dw/exam/add'
  return $axios.fPost(url, data)
}
// 问卷列表
export function dwexamList (data) {
  const url = '/dw/exam/list'
  return $axios.fPost(url, data)
}
// 问卷管理权限人员列表
export function dwManagerList (data) {
  const url = '/dw/manager/list'
  return $axios.fPost(url, data)
}
// 问卷管理权限人员修改
export function dwManagerEdit (data) {
  const url = '/dw/manager/edit'
  return $axios.fPost(url, data)
}
// 问卷管理权限人员增加
export function dwManagerAdd (data) {
  const url = '/dw/manager/add'
  return $axios.fPost(url, data)
}
// 问卷管理权限人员删除
export function dwManagerDelete (data) {
  const url = '/dw/manager/delete'
  return $axios.fPost(url, data)
}


// 发布试卷
export function dwexamPublish (data) {
  const url = '/dw/exam/publish'
  return $axios.fPost(url, data)
}
// 复制问卷
export function dwexamCopy (data) {
  const url = '/dw/exam/copy'
  return $axios.fPost(url, data)
}
// 删除问卷
export function dwexamDel (data) {
  const url = '/dw/exam/delete'
  return $axios.fPost(url, data)
}
// 停用问卷
export function dwexamStop (data) {
  const url = '/dw/exam/stop'
  return $axios.fPost(url, data)
}
// 问卷详情
export function dwexamInfo (data) {
  const url = '/dw/exam/info'
  return $axios.fPost(url, data)
}
// 编辑问卷
export function dwexamEdit (data) {
  const url = '/dw/exam/edit'
  return $axios.fPost(url, data)
}
// 预览问卷
export function dwexamPreview (data) {
  const url = '/dw/exam/preview'
  return $axios.fPost(url, data)
}
// 入学档案预览问卷
export function dwexamGrowDetail (data) {
  const url = '/dw/answer/detail'
  return $axios.fPost(url, data)
}

// sso登录
export function loginSso (data) {
  const url = '/openplatform/user/loginpass'
  return $axios.fLoginPost(url, data)
}
// 问卷详情（内部）
export function dwexamSelf (data) {
  const url = '/openplatform/dw/exam/info'
  return $axios.fPost(url, data)
}
// 用户信息(内部)
export function dwexamSelfuserinfo (data) {
  const url = '/openplatform/user/detail'
  return $axios.fPost(url, data)
}
// 学员列表(内部)
export function dwexamSelfStuList (data) {
  const url = '/openplatform/user/listStudentForCampus'
  return $axios.fPost(url, data)
}
// 校区tree(内部)
export function dwexamSelfTreeList (data) {
  const url = '/openplatform/campus/list/all'
  return $axios.fPost(url, data)
}
// 提交问卷(内部)
export function dwexamSelfSubmit (data) {
  const url = '/openplatform/dw/exam/answer'
  return $axios.fPost(url, data)
}
// 问卷信息（外部）
export function dwexamOpenInfo (data) {
  const url = '/openplatform/dw/exam/open/info'
  return $axios.fPost(url, data)
}
// 提交问卷（外部）
export function dwexamOpenSubmit (data) {
  const url = '/openplatform/dw/exam/open/answer'
  return $axios.fPost(url, data)
}
// 填写记录
export function dwAnswerList (data) {
  const url = '/dw/answer/list'
  return $axios.fPost(url, data)
}
// 删除问卷记录
export function dwRecordDel (data) {
  const url = '/dw/answer/delete'
  return $axios.fPost(url, data)
}

// 新增修改题目接口
export function editQuestion (data) {
  const url = '/dw/exam/editQuestion'
  return $axios.fPost(url, data)
}
// ● 新增创建共享海报的接口
export function addTemplateUser (data) {
  const url = '/dw/exam/addTemplateUser'
  return $axios.fPost(url, data)
}
// ● 海报列表接口
export function listTemplateUser (data) {
  const url = '/dw/exam/listTemplateUser'
  return $axios.fPost(url, data)
}
