var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "c-padding-left-16 c-padding-right-16" }, [
    _c("div", { staticClass: "c-padding-8" }, [
      _c("div", [
        _c("div", [
          _c(
            "span",
            { staticClass: "info-item" },
            [
              _vm._v("结果： "),
              _vm.solutionInfo.result <= 4 ||
              _vm.solutionInfo.result == 12 ||
              _vm.solutionInfo.result == 13
                ? _c("el-tag", { attrs: { type: "success" } }, [
                    _vm._v(
                      " " + _vm._s(_vm.getType(_vm.solutionInfo.result)) + " "
                    ),
                  ])
                : _vm.solutionInfo.result >= 5 && _vm.solutionInfo.result <= 11
                ? _c("el-tag", { attrs: { type: "danger" } }, [
                    _vm._v(
                      " " + _vm._s(_vm.getType(_vm.solutionInfo.result)) + " "
                    ),
                  ])
                : _c("el-tag", { attrs: { type: "warning" } }, [
                    _vm._v(
                      " " + _vm._s(_vm.getType(_vm.solutionInfo.result)) + " "
                    ),
                  ]),
            ],
            1
          ),
          _vm.solutionList.length
            ? _c("span", { staticClass: "info-item" }, [
                _vm._v(
                  " 测试用例通过率：" +
                    _vm._s(_vm.solutionInfo.pass_rate * 100) +
                    "% "
                ),
              ])
            : _vm._e(),
        ]),
        _c("div", [
          _c("span", { staticClass: "info-item" }, [
            _vm._v("语言："),
            _c("span", { staticClass: "item-green" }, [
              _vm._v(_vm._s(_vm.getLanText(_vm.item.language))),
            ]),
          ]),
          _c("span", { staticClass: "info-item" }, [
            _c("span", { staticClass: "item-green" }, [
              _vm._v("代码长度：" + _vm._s(_vm.solutionInfo.code_length)),
            ]),
          ]),
          _c("span", { staticClass: "info-item" }, [
            _vm._v("运行时间："),
            _c("span", { staticClass: "item-green" }, [
              _vm._v(_vm._s(_vm.solutionInfo.time) + "ms"),
            ]),
          ]),
          _c("span", { staticClass: "info-item" }, [
            _vm._v("占用内存："),
            _c("span", { staticClass: "item-green" }, [
              _vm._v(_vm._s(_vm.solutionInfo.memory) + "KB"),
            ]),
          ]),
          _c("span", { staticClass: "info-item" }, [
            _vm._v(
              "提交时间： " +
                _vm._s(
                  _vm.solutionInfo.in_date
                    ? _vm.solutionInfo.in_date.substring(
                        0,
                        _vm.solutionInfo.in_date.length - 2
                      )
                    : "暂无"
                )
            ),
          ]),
        ]),
        _c("div", { staticClass: "c-margin-top-8" }, [
          _c("h3", [_vm._v("代码：")]),
          _c("div", {
            ref: "ace",
            staticClass: "ace-editor",
            attrs: { id: "toptwo" },
          }),
        ]),
      ]),
    ]),
    _vm.solutionInfo.compileInfo
      ? _c("div", { staticClass: "c-padding-8" }, [
          _c("h3", [_vm._v("编译信息：")]),
          _c(
            "div",
            {
              staticClass: "c-margin-top-8 c-padding-16 info-item-box",
              staticStyle: { padding: "20px" },
            },
            [
              _vm.solutionInfo.compileInfo
                ? _c(
                    "p",
                    {
                      staticStyle: {
                        padding: "20px",
                        "white-space": "pre-wrap",
                        "max-height": "500px",
                        "overflow-y": "auto",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.solutionInfo.compileInfo) + " ")]
                  )
                : _c("p", [_vm._v("暂无")]),
            ]
          ),
        ])
      : _vm._e(),
    5 <= Number(_vm.solutionInfo.result) && Number(_vm.solutionInfo.result) < 11
      ? _c("div", { staticClass: "c-padding-8" }, [
          _c("h3", [_vm._v("运行信息：")]),
          _c(
            "div",
            {
              staticClass: "c-margin-top-8 c-padding-16 info-item-box",
              staticStyle: {
                padding: "20px",
                "white-space": "pre-wrap",
                "max-height": "500px",
                "overflow-y": "auto",
              },
            },
            [_vm._v(" " + _vm._s(_vm.solutionInfo.runtimeInfo) + " ")]
          ),
        ])
      : _vm._e(),
    _vm.solutionList.length
      ? _c("div", { staticClass: "c-padding-8" }, [
          _c("h3", [_vm._v("用例信息：")]),
          _c(
            "div",
            {
              staticClass: "c-margin-top-8 c-padding-16 info-item-box",
              staticStyle: { padding: "20px" },
            },
            [
              _vm.solutionList.length == 0
                ? _c("p", { staticClass: "compileInfoText colorText" }, [
                    _vm._v(" 暂无 "),
                  ])
                : _c("el-row", [
                    _c(
                      "div",
                      { staticStyle: { display: "flex", "flex-wrap": "wrap" } },
                      _vm._l(_vm.solutionList, function (item, index) {
                        return _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "baseClass",
                              class:
                                item.runState === "AC" ? "ACclass" : "WAclass",
                            },
                            [
                              _c("span", { staticClass: "yongliIndex" }, [
                                _vm._v("#" + _vm._s(item.fileName)),
                              ]),
                              _c("span", { staticClass: "yongliStatus" }, [
                                _vm._v(_vm._s(item.runState)),
                              ]),
                              _c("span", { staticClass: "yongliData" }, [
                                _vm._v(
                                  _vm._s(item.runTime) +
                                    "/" +
                                    _vm._s(item.memory)
                                ),
                              ]),
                            ]
                          ),
                        ])
                      }),
                      0
                    ),
                  ]),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }