var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    [
      _c(
        "el-card",
        { staticStyle: { "margin-bottom": "10px" } },
        [
          _c("Tinymce", {
            attrs: { width: "100%", height: 500 },
            model: {
              value: _vm.activityContent,
              callback: function ($$v) {
                _vm.activityContent = $$v
              },
              expression: "activityContent",
            },
          }),
          _c(
            "el-row",
            { staticClass: "mt-20" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.saveContent },
                },
                [_vm._v("保 存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }