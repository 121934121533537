<template>
  <div class="batchImport">
    <el-input
      v-model="batchStr"
      type="textarea"
      placeholder="S0001
S0002
S0003
……
……
请按每行一个学号的格式填写要添加的学员，点击导入按钮"
      rows="8"
      show-word-limit
    />
    <el-button
      size="mini"
      type="primary"
      style="margin-top: 10px"
      @click="importFun"
    >
      导入
    </el-button>
    <el-row class="result-box" v-if="isNonentity">
      <span class="seleRow">不存在的用户名</span>
      <el-row
        v-for="(item, index) of nonexistence"
        :key="index"
        class="seleList"
      >
        <span>{{ item }}</span>
      </el-row>
    </el-row>
  </div>
</template>
<script>
import {
  getListStudentForClass,
  setListByLoginNames,
  getLessonInfoByClassId,
} from "@/api/senate/class";
export default {
  props: {
    tableSelection: {
      type: Array,
      default: () => [],
    },
    batCurrentState: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      batchStr: "",
      multipleSelectionTotal: [], // 已选择学员
      nonexistence: [],
      isNonentity: false,
      dataList: [],
      multiplesIds: [],
    };
  },
  inject: {
    // 注入父组件传的值，可以写一个默认的值，如果父组件没有返回数据的话
    multiple: {
      default: true,
    },
  },
  methods: {
    reverseElectionClick() {},
    // TODO
    importFun() {
      // this.$message.info('自添加逻辑')
      const loninNameSet = this.batchStr.split("\n");
      let params = {
        roleCodeSet: [1],
        loninNameSet,
      };
      if (this.batCurrentState) {
        params.identity = "student";
      }
      setListByLoginNames(params).then((res) => {
        if (res.state === "success") {
          this.multipleSelectionTotal = res.body.existence;
          this.nonexistence = res.body.nonexistence;
          // console.log(this.tableSelection)
          if (this.multiple) {
            if (this.tableSelection.length) {
              this.multipleSelectionTotal.forEach((item, index) => {
                const itemIndex = this.tableSelection.findIndex((items) => {
                  return item.userId === items.userId;
                });
                if (itemIndex === -1) {
                  this.$emit("changeSelectionData", item);
                } else {
                  return this.$message.warning(
                    `${item.name}已选择，请勿重复选择`
                  );
                }
              });
            } else {
              this.multipleSelectionTotal.forEach((item, index) => {
                this.$emit("changeSelectionData", item);
              });
            }
          } else {
            if (this.multipleSelectionTotal.length == 1) {
              if (this.tableSelection.length) {
                this.multipleSelectionTotal.forEach((item, index) => {
                  const itemIndex = this.tableSelection.findIndex((items) => {
                    return item.userId === items.userId;
                  });
                  if (itemIndex === -1) {
                    this.$emit("changeSelectionData", item);
                  } else {
                    return this.$message.warning(
                      `${item.name}已选择，请勿重复选择`
                    );
                  }
                });
              } else {
                this.multipleSelectionTotal.forEach((item, index) => {
                  this.$emit("changeSelectionData", item);
                });
              }
            } else if (this.multipleSelectionTotal.length) {
              this.$message.warning("不支持多选");
            }
          }
          if (res.body.nonexistence.length === 0) {
            this.isNonentity = false;
          } else {
            this.isNonentity = true;
          }
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.batchImport {
  padding: 12px;
}
.seleRow {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  background: #efefef;
  box-sizing: border-box;
  padding-left: 25px;
}
.seleNum {
  color: #fff;
  border-radius: 50%;
  padding: 1px 8px;
  background: #cccccc;
}
.seleList {
  box-sizing: border-box;
  line-height: 32px;
  padding-left: 20px;
}
.ico-del {
  display: inline-block;
  margin-right: 50px;
  cursor: pointer;
}
.result-box {
  max-height: 200px;
  overflow: auto;
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.15);
  padding-bottom: 30px;
  margin-top: 15px;
}
</style>
